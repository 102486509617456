<template>
  <div class="el-transfer-panel__body w-transfer-list__tree">
    <el-input
      class="el-transfer-panel__filter w-form--text"
      v-model="filterText" size="small"
      :placeholder="filterPlaceholder"
      :suffix-icon="inputIcon"
      @mouseenter.native="inputHover = true"
      @mouseleave.native="inputHover = false"
      @click="clearQuery"
      v-if="filterable">
    </el-input>
    <div>
      <div class="w-transfer-title">
        <div
          v-if="$slots['title-left']"
          class="w-transfer-title__left">
          <slot name="title-left"></slot>
        </div>
        <div
          v-if="$slots['title-right']"
          class="w-transfer-title__right">
          <slot name="title-right"></slot>
        </div>
      </div>
      <el-scrollbar
        wrap-class="w-transfer__wrap"
        view-class="w-transfer__treebox result">
        <el-tree
          :data="data"
          node-key="id"
          :props="defaultProps"
          :accordion="true"
          ref="tree2"
          :default-expand-all="true"
          :filter-node-method="filterNode"
          class="w-transfer__tree">
          <div class="tree-node-item" slot-scope="{ node, data }">
            <span
            v-if="!node.isLeaf"
            class="expand-container"></span>
            <span class="custom-tree-node">
              <slot
                name="result-item"
                :node="node"
                :data="data"
                :event-handle="itemEventHandle"></slot>
            </span>
          </div>
        </el-tree>
      </el-scrollbar>
    </div>
    <div class="result-footer-top">
      <slot
        name="result-footer-top"
        :data="data"
        :value="value"></slot>
    </div>
    <p class="el-transfer-panel__footer">
      <el-row style="height: 100%;line-height: 40px;padding-left:16px;">
        <el-col
          :span="12">
          <el-button
            type="text"
            class="clear-btn"
            @click="clearNode">清空所选</el-button>
        </el-col>
        <el-col
          :span="12"
          style="text-align: right;">{{ checkText }}</el-col>
      </el-row>
    </p>
  </div>
</template>

<script>
export default {
  name: 'treeBox',
  data () {
    return {
      inputHover: false,
      filterText: '',
      defaultProps: { // 树形加载框配置项 子元素默认键名为‘childs’
        children: 'childs',
        label: 'label'
      },
      allChecked: false,
      resultKeys: []
    }
  },
  watch: {
    resultKeys (val) {
      const self = this
      self.$emit('input', val)
    },

    value (val) {
      const self = this
      self.resultKeys = val
    },

    // 输入框数据
    filterText (val) {
      const self = this
      self.$refs.tree2.filter(val)
    }
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },

    // 穿梭框已勾选的值
    value: {
      type: Array,
      default () {
        return []
      }
    },
    filterable: Boolean, // 是否开启搜索过滤
    filterMethod: Function, // 自定义的搜索过滤方法
    filterPlaceholder: String, // 过滤文案

    overflow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    //  绑定的图片icon
    inputIcon () {
      const self = this
      return self.filterText.length > 0 && self.inputHover ? 'el-icon-close' : 'el-icon-search'
    },

    // 数据池的数据长度
    dataLength () {
      const self = this
      let total = 0
      self.data.map(item => {
        if (!self.overflow) {
          total++
        }
        item.childs.map(resultKeys => {
          total++
          if (resultKeys.childs && resultKeys.childs.length > 0) {
            resultKeys.childs.map(trdKeys => {
              total++
            })
          }
        })
      })
      return total
    },

    //  选择框文案
    checkText () {
      const self = this
      return `已选 ${self.dataLength} 项`
    }
  },
  methods: {
    clearNode () {
      const self = this
      self.clearData()
      self.$parent.$refs.leftTree.allChecked = false
    },

    // 过滤节点
    filterNode (value, data) {
      const self = this
      if (typeof self.filterMethod === 'function') {
        return self.filterMethod(self.resultKeys, data)
      } else {
        if (!value) {
          return true
        }
        if (data.label.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1) {
          return true
        } else {
          return false
        }
      }
    },

    // 清空左边输入框值方法
    clearQuery () {
      const self = this
      if (self.inputIcon === 'el-icon-close') {
        self.filterText = ''
      }
    },

    clearData () {
      const self = this
      self.$parent.$refs.leftTree.$refs.tree1.setCheckedKeys([])
    },

    /**
     * 获取所有的子集key
     */
    getAllKeys ({ childIds, id }) {
      if (Array.isArray(childIds) && childIds.length > 0) {
        return childIds
      }
      return [id]
    },

    /**
     * 单项数据处理
     */
    itemEventHandle (payload) {
      const self = this
      const tree1 = self.$parent.$refs.leftTree.$refs.tree1
      const { type } = payload
      if (type === 'remove') {
        // tree1.setChecked(key, false)
        this.getAllKeys(payload.data).forEach(key => {
          tree1.setChecked(key, false)
        })
      }
    }
  }
}
</script>
