/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-15 22:28:15
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 19:06:20
 */
import JsEncrypt from 'jsencrypt'
import Vue from 'vue'
import * as requestApis from '@/store/request-apis'
import * as types from '@/store/mutation-types.js'
import stateTool from '@/store/state-tool'

const state = {
  name: 'login-reset',
  form: {
    // 手机号/邮箱
    username: '',
    // 验证码
    code: '',
    // 新密码
    password: '',
    // 再次输入
    repassword: ''
  }
}

const getters = {
  form: state => state.form
}

const mutations = {

  /**
   * 重置表单
   */
  [types.LOGIN_RESET_RESET_FORM] (state) {
    stateTool.updateAllKeys(state.form, {
      // 手机号/邮箱
      username: '',
      // 验证码
      code: '',
      // 新密码
      password: '',
      // 再次输入
      repassword: ''
    })
  }

}

const actions = {

  /**
   * 显示登录弹窗
   */
  async showLogin ({ dispatch }) {
    dispatch('Login/updateDialogLogin', {
      dialogType: 'login'
    }, { root: true })
  },
  /**
   * 校验账号是否存在
   */
  getAccountStatus ({ dispatch }, payload) {
    return dispatch('Login/dialog/getAccountStatus', payload, { root: true })
  },

  /**
   * 获取验证码
   */
  async getVerifyCode (context, payload) {
    const data = await Vue.wPost(requestApis.RESET_GET_VERIFY_CODE, payload)
    return data
  },

  /**
   * 获取RSA公钥
   */
  async getRsaPublishKey ({ dispatch }) {
    return dispatch('Login/dialog/getRsaPublishKey', {}, { root: true })
  },

  /**
   * 重置提交
   */
  async resetSubmit ({ dispatch }, payload) {
    const publishKey = await dispatch('getRsaPublishKey')
    const {
      username,
      password,
      code
    } = payload
    if (!publishKey || !username || !password || !code) {
      return false
    }
    const encodeKeys = ['password']
    const form = ['username', 'password', 'code'].reduce((res, key) => {
      if (encodeKeys.includes(key)) {
        const jsencrypt = new JsEncrypt()
        jsencrypt.setPublicKey(publishKey)
        res[key] = jsencrypt.encrypt(payload[key])
      } else {
        res[key] = payload[key]
      }
      return res
    }, {})
    const data = await Vue.wPost(requestApis.RESET_RESET_PASSWORD, form)
    return data
  },

  /**
   * 显示成功弹窗
   */
  async showSucccess ({ dispatch }) {
    await dispatch('Login/updateDialogLogin', {
      dialogType: 'success'
    }, { root: true })
    dispatch('Login/dialog/login/success/initModal', {
      title: '密码重置成功'
    }, { root: true })
  },

  /**
   * 重置表单
   */
  resetForm ({ commit }) {
    commit(types.LOGIN_RESET_RESET_FORM)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
