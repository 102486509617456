/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-17 16:57:14
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 15:25:41
 */
import AcvicityAdd from './AcvicityAdd'
import AcvicityTest from './AcvicityTest'
export default {
  AcvicityAdd,
  AcvicityTest
}
