/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-10 09:30:59
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-10 09:32:14
 */
import WTable from './WTable.vue'
WTable.install = Vue => {
  Vue.component(WTable.name, WTable)
}
export default WTable
