/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 17:45:32
 * @LastEditors: Arvin
 * @LastEditTime: 2021-01-14 10:52:41
 */

/**
 * 获取图像尺寸
 * @param {File} 上传的图片
 * @return {Number} 图像数据
 */
import moment from 'moment'
import Vue from 'vue'

export const wGetUrlQuery = (prop) => {
  const val = location.search.match(new RegExp('[\\?\\&]' + prop + '=([^\\&]*)(\\&?)', 'i'))
  return val ? val[1] : val
}

export const wGetImgInfo = (file) => {
  return new Promise(resolve => {
    const url = window.URL || window.webkitURL
    const img = new Image()

    img.src = url.createObjectURL(file)
    img.onload = e => resolve(e.currentTarget)
  })
}

/**
 * @description 求最大公约数
 * @param { Number } a 数字
 * @param { Number } b 数字
 * @return { Number } 最大公约数
 */
export const wGetCommonDivisor = (a, b) => {
  let temp
  while (b && b !== 0) {
    temp = a % b
    a = b;
    b = temp
  }
  return a
}

/**
 *获取字符串长度，区分中英文
 * @param {String} str 字符串
 */
export const wGetTextLength = (str) => {
  class Regexp {
    constructor (str) {
      // this.url = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-|%)+)/gi
      // 中文字正则
      this.cn = /[\u4E00-\u9FA5\uF900-\uFA2D]/gi
      // 中文标点正则
      this.fullwidthChar = /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/gi
      // 英文数字正则
      this.letter = /[a-zA-Z0-9]/gi
      // 字符串文本
      this._str = str
    }

    computed () {
      // 获取字符串中中文字符的长度，并将字符串中的中文字符去除
      const cnLength = this._str.match(this.cn) === null ? 0 : this._str.match(this.cn).length
      this._str = this._str.replace(this.cn, '')
      // 获取字符串中中文标点的长度
      const fullwidthCharLength = this._str.match(this.fullwidthChar) === null ? 0 : this._str.match(this.fullwidthChar).length
      this._str = this._str.replace(this.fullwidthChar, '')
      // 获取英文字母和数字的长度，并将字符串中的英文标点去除
      const enLength = this._str.match(this.letter) === null ? 0 : this._str.match(this.letter).length
      this._str = this._str.replace(this.letter, '')
      // 获取英文符号的长度
      const otherLength = this._str.length

      return (cnLength + fullwidthCharLength) * 2 + enLength + otherLength
    }
  }

  return new Regexp(str).computed()
}

/**
 * 解决JavaScript小数点计算问题
 * @param {Number} 待格式化的小数点
 * @return {Number} 返回格式化后的数据
 */
export const wStripDeci = (num) => {
  return parseFloat(num).toPrecision(12)
}

/**
 *
 * @param {} imgUrl 图片地址，单图为String，多图为Array，例如：['http://baidu.com','http://weibo.cn']
 * @param {} imgName 图片名称，单图为String，多图为Array，例如：['图片1','图片2']
 */
export const wGetImgList = (imgUrl, imgName, isSingle = true) => {
  class Imglist {
    constructor (imgUrl, imgName) {
      this.imgUrl = imgUrl
      this.imgName = imgName
      this.list = []
    }

    getSingle () {
      if (this.imgUrl) {
        this.list.push(Object.assign({}, { url: this.imgUrl, name: this.imgName || this.imgUrl }))
      }
      return this.list
    }

    getMul () {
      if (this.imgUrl && Array.isArray(this.imgUrl)) {
        this.imgUrl.forEach((item, index) => {
          if (item) {
            this.list.push(Object.assign({}, {
              url: item,
              name: this.imgName && this.imgName[index] !== null ? this.imgName[index] : item
            }))
          }
        })
      }
      return this.list
    }
  }

  if (isSingle) {
    return new Imglist(imgUrl, imgName).getSingle()
  } else {
    return new Imglist(imgUrl, imgName).getMul()
  }
}

export const collspanMenu = (needOpen = false) => {
  const a = document.querySelector('#app')
  if (a.getAttribute('class')) {
    const list = a.getAttribute('class').split(' ')
    if (list.indexOf('open') > -1) {
      a.setAttribute('class', 'un-open')
    }
    if (needOpen) {
      if (list.indexOf('un-open') > -1) {
        a.setAttribute('class', 'open')
      }
    }
  }
}

export const getNormalWeek = (isToday) => {
  const start = new Date()
  const end = new Date()
  if (isToday) {
    end.setTime(start.getTime())
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
  } else {
    end.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
  }
  const date = [
    moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')]
  console.log(date)
  return date
}

export const getToday = () => {
  const start = new Date()
  const end = new Date()
  const date = [
    moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')]
  console.log(date)
  return date
}

// 数字格式化 10000 => 1,000
export const intNumberFormat = (num, split = ',') => {
  return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1' + split)
}

// 带小数的数字格式化 1000.7889 => 1,000.7889
export const floatNumberFormat = (num, split = ',') => {
  const [int, float] = (num + '').split(/\./g)
  return [intNumberFormat(int, split), float].join('.')
}

export const numberFormat = (num, split = ',') => {
  return typeof num === 'number' ? Number.isInteger(num) ? intNumberFormat(num, split) : floatNumberFormat(num, split) : num
}

// 金钱格式化
export const moneyFormat = money => {
  const moneyStr = (money - 0).toFixed(2)
  const [int, float] = moneyStr.split('.')
  return intNumberFormat(int) + '.' + float
}

// 是否为数字格式
export const isNumber = num => {
  if (typeof num === 'number') {
    return true
  }
  if (/^\d+(.\d+)?$/.test(num)) {
    return true
  }
  return false
}

export const copyText = (text, tips = '链接复制成功') => {
  const aux = document.createElement('input')
  aux.setAttribute('value', text)
  document.body.appendChild(aux)
  aux.select()
  document.execCommand('copy')
  document.body.removeChild(aux)
  Vue.wToast.success(tips)
}
