<!--
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 10:31:00
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-12-07 18:08:24
-->
<template>
  <div id="app" class="un-open">
    <router-view/>
  </div>
</template>

<script>
import { collspanMenu } from '@/plugins/utils'
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions([
      'updateIsMobile'
    ]),
    init () {
      const isMobile = /android|iphone/i.test(navigator.userAgent)
      this.updateIsMobile(isMobile)
      this.bodyHideMenu()
    },
    bodyHideMenu () {
      const slider = document.querySelector('.w-slider')
      const collspan = document.querySelector('.w-header--navbar--collspan')
      document.body.onclick = (e) => {
        if (slider && collspan) {
          if (!slider.contains(e.target) && !collspan.contains(e.target)) {
            collspanMenu(false)
          }
        }
      }
    }
  },
  async beforeMount () {
    await this.init()
  }
}
</script>
