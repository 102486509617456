<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-13 14:10:28
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-13 14:14:52
-->
<template>
  <span class="w-tags">
    {{text}}
  </span>
</template>

<script>
export default {
  name: 'WTags',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import './index.scss'
</style>
