/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-22 14:49:01
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-22 17:57:32
 */
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('Login/dialog/login/success')

export default {
  name: 'Success',
  data () {
    return {
      timer: null,
      count: 3
    }
  },
  computed: {
    ...mapGetters([
      'title'
    ])
  },
  async mounted () {
    const finish = await this.startCount()
    if (finish === true) {
      const lastPage = document.referrer
      const isSameOrigin = new RegExp('^' + location.origin).test(lastPage)
      if (isSameOrigin) {
        window.location.href = lastPage
      } else {
        window.location.href = '/'
      }
    }
  },
  methods: {

    /**
     * 开始倒计时
     */
    startCount () {
      this.count = 3
      this.isCounting = true
      return new Promise(resolve => {
        this.doCount(resolve)
      })
    },

    /**
     * 倒计时计数
     */
    doCount (resolve) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.count--
        if (this.count > 0) {
          this.doCount(resolve)
          return
        }
        clearTimeout(this.timer)
        this.timer = null
        this.isCounting = false
        resolve(true)
      }, 1000)
    }
  }
}
