/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 14:30:49
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 14:32:32
 */
import orderFinish from './orderFinish.vue'
export default orderFinish
