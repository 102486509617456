/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-22 11:22:28
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-22 11:28:04
 */
import WGeetest from './WGeetest.vue'
export default WGeetest
