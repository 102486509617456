/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-16 17:02:27
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-21 19:58:36
 */
export default {
  name: 'WMobileTips',
  data () {
    return {
      WMobileTipsShow: false
    }
  },
  methods: {
    hideWMobileTips () {
      this.WMobileTipsShow = false
    },
    WMobileTipsClose () {
      // 发送请求
      // const data = this.$wGet('/')
      console.log('发送请求')
    },
    checkWMobileTips () {
      console.log('发送请求')
      // const data = this.$wGet('/')
      this.WMobileTipsShow = true
    }
  }
}
