/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-13 17:22:16
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-25 01:57:37
 */
import JsEncrypt from 'jsencrypt'
import Vue from 'vue'
import * as requestApis from '@/store/request-apis'
import * as types from '@/store/mutation-types'
import stateTool from '@/store/state-tool'

const state = {
  name: 'login-register',
  form: {
    // 用户名
    name: '',
    // 邮箱/手机号
    username: '',
    // 密码
    password: '',
    // 验证码
    code: '',
    // 是否同意皓数用户注册协议注册协议
    agree: false
  }
}

const getters = {
  form: state => state.form
}

const mutations = {

  /**
   * 更新表单
   */
  [types.LOGIN_REGISTRE_UPDATE_FORM] (state, payload) {
    stateTool.updateAllKeys(state.form, payload)
  },

  /**
   * 重置表单
   */
  [types.LOGIN_REGISTER_RESET_FORM] (state) {
    stateTool.updateAllKeys(state.form, {
      // 用户名
      name: '',
      // 邮箱/手机号
      username: '',
      // 密码
      password: '',
      // 验证码
      code: '',
      // 是否同意皓数用户注册协议注册协议
      agree: false
    })
  }

}

const actions = {

  /**
   * 更新表单
   */
  updateForm ({ commit }, payload) {
    commit(types.LOGIN_REGISTRE_UPDATE_FORM, payload)
  },

  /**
   * 显示登录弹窗
   */
  showLogin ({ dispatch }) {
    dispatch('Login/updateDialogLogin', {
      dialogType: 'login'
    }, { root: true })
  },

  /**
   * 校验账号是否存在
   */
  getAccountStatus ({ dispatch }, payload) {
    return dispatch('Login/dialog/getAccountStatus', payload, { root: true })
  },

  /**
   * 获取RSA公钥
   */
  async getRsaPublishKey ({ dispatch }) {
    return dispatch('Login/dialog/getRsaPublishKey', {}, { root: true })
  },

  /**
   * 获取极验后台配置
   */
  async getGeeTestConfig () {
    const data = await Vue.wPost(requestApis.GET_GEETEST_CONFIG, {})
    return data
  },

  /**
   * 验证极验验证码
   */
  async verifyGeeTestCode (context, payload) {
    const data = await Vue.wPost(requestApis.VERIFY_GEETEST_CODE, payload)
    return data
  },

  /**
   * 获取验证码
   */
  async getVerifyCode (context, payload) {
    const data = await Vue.wPost(requestApis.GET_VERIFY_CODE, payload)
    return data
  },

  /**
   * 登录提交
   */
  async registerSubmit ({ dispatch }, payload) {
    const publishKey = await dispatch('getRsaPublishKey')
    const {
      username,
      password,
      name,
      code
    } = payload
    if (!publishKey || !username || !password || !name || !code) {
      return false
    }
    const encodeKeys = ['password']
    const form = ['username', 'password', 'name', 'code'].reduce((res, key) => {
      if (encodeKeys.includes(key)) {
        const jsencrypt = new JsEncrypt()
        jsencrypt.setPublicKey(publishKey)
        res[key] = jsencrypt.encrypt(payload[key])
      } else {
        res[key] = payload[key]
      }
      return res
    }, {})
    const data = await Vue.wPost(requestApis.REGISTER_SUBMIT, form)
    return data
  },

  /**
   * 显示成功弹窗
   */
  async showSucccess ({ dispatch }) {
    await dispatch('Login/updateDialogLogin', {
      dialogType: 'success'
    }, { root: true })
    dispatch('Login/dialog/login/success/initModal', {
      title: '恭喜注册成功'
    }, { root: true })
  },

  /**
   * 重置表单
   */
  resetForm ({ commit }) {
    commit(types.LOGIN_REGISTER_RESET_FORM)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
