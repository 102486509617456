/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-04 19:20:40
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-12-07 18:04:47
 */
import WBoxHeader from './WBoxHeader'
import WBoxContent from './WBoxContent'
import WCounto from './WCounto'
import WButton from './WButton'
import WTable from './WTable'
import WTableSort from './WTableSort'
import WTags from './WTags'
import WSvg from './WSvg'
import WTooltip from './WTooltip'
import WInputValid from './WInputValid'
import WSelectList from './WSelectList'
import WTransferList from './WTransferList'
import WSuccessDialog from './WSuccessDialog'

const components = {}
components.install = Vue => {
  Vue.use(WBoxHeader)
  Vue.use(WBoxContent)
  Vue.use(WCounto)
  Vue.use(WButton)
  Vue.use(WTable)
  Vue.use(WTableSort)
  Vue.use(WTags)
  Vue.use(WSvg)
  Vue.use(WTooltip)
  Vue.use(WInputValid)
  Vue.use(WSelectList)
  Vue.use(WTransferList)
  Vue.use(WSuccessDialog)
}

export default components
