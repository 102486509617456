/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-13 14:10:40
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-13 14:12:53
 */
import WTags from './WTags.vue'
WTags.install = Vue => {
  Vue.component(WTags.name, WTags)
}
export default WTags
