/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-16 17:02:27
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 17:07:06
 */
import { mapGetters } from 'vuex'
export default {
  name: 'WMobileTips',
  data () {
    return {
      WMobileTipsShow: false
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  },
  methods: {
    hideWMobileTips () {
      this.WMobileTipsShow = false
    },
    async WMobileTipsClose () {
      // 发送请求
      console.log('发送请求close')
      this.$wPost('/common/user/operate/flag/add.do', { operate_type: 1 })
    },
    async checkWMobileTips () {
      console.log('发送请求')
      const data = await this.$wPost('/common/user/operate/flag/checkExist.do', { operate_type: 1 })
      console.log(data)
      if (data === true) {
        this.WMobileTipsShow = true
      }
    }
  },
  beforeMount () {
    if (this.isMobile) {
      this.checkWMobileTips()
    }
  }
}
