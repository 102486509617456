/* eslint-disable quote-props */
/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-13 09:49:28
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 17:07:39
 */
const _import = require('../_import_' + process.env.NODE_ENV)

// const appRouter = {
//   'ap000001': [{
//     path: '/app/overview',
//     icon: 'ic_data_normal',
//     acicon: 'ic_data_hover',
//     cn: '数据看板',
//     name: 'Overview',
//     component: _import('model/App/Overview')
//   }],
//   'ap000002': [{
//     path: '/app/activity',
//     icon: 'ic_activity_normal',
//     acicon: 'ic_activity_hover',
//     cn: '活动管理',
//     name: 'AppActivity',
//     component: _import('model/App/Activity')
//   },
//   {
//     path: '/app/activity/add',
//     name: 'AppActivityAdd',
//     component: _import('model/App/Activity/ActivityAdd')
//   },
//   {
//     path: '/app/activity/edit',
//     name: 'AppActivityEdit',
//     component: _import('model/App/Activity/ActivityAdd')
//   },
//   {
//     path: '/app/activity/test',
//     name: 'AppActivityTest',
//     component: _import('model/App/Activity/ActivityTest')
//   },
//   {
//     path: '/app/activity/crowdattribute',
//     cn: '人群画像',
//     name: 'AppActivityTestCrowdattribute',
//     component: _import('model/App/Datapackage/Crowdattribute')
//   }],
//   'ap000003': [{
//     path: '/app/datapackage',
//     icon: 'ic_crowd_normal1',
//     acicon: 'ic_crowd_hover',
//     cn: '人群管理',
//     name: 'Datapackage',
//     component: _import('model/App/Datapackage')
//   },
//   {
//     path: '/app/datapackage/crowdattribute',
//     cn: '人群画像',
//     name: 'Crowdattribute',
//     component: _import('model/App/Datapackage/Crowdattribute')
//   },
//   {
//     path: '/app/datapackage/datapackagedetail',
//     cn: '人群包详情',
//     name: 'DatapackageDetail',
//     component: _import('model/App/Datapackage/DatapackageDetail')
//   },
//   {
//     path: '/app/datapackage/diy/add',
//     cn: '新建人群包',
//     name: 'Addpackage',
//     component: _import('model/App/Datapackage/AddDiypackage')
//   }],
//   'ap000004': [{
//     path: '/app/finance',
//     icon: 'ic_account_normal',
//     acicon: 'ic_account_hover',
//     cn: '账户管理',
//     name: 'Finance',
//     component: _import('model/App/Finance')
//   },
//   {
//     path: '/app/finance/accountconfig',
//     icon: 'ic_account_normal',
//     acicon: 'ic_account_hover',
//     cn: '账户资质认证',
//     name: 'AccountConfig',
//     component: _import('model/App/Finance/AccountConfig')
//   },
//   {
//     path: '/app/finance/accountdetail',
//     icon: 'ic_account_normal',
//     acicon: 'ic_account_hover',
//     cn: '账户资质详情',
//     name: 'AccountConfig',
//     component: _import('model/App/Finance/AccountDetail')
//   }]
// }

const appRouter = [
  {
    path: '/app/overview',
    icon: 'ic_data_normal',
    acicon: 'ic_data_hover',
    cn: '数据看板',
    name: 'Overview',
    component: _import('model/App/Overview'),
    meta: {
      auth: 'ap000001',
      isMenu: true
    }
  }, {
    path: '/app/activity',
    icon: 'ic_activity_normal',
    acicon: 'ic_activity_hover',
    cn: '活动管理',
    name: 'AppActivity',
    component: _import('model/App/Activity'),
    meta: {
      auth: 'ap000002',
      isMenu: true
    }
  },
  {
    path: '/app/activity/add',
    name: 'AppActivityAdd',
    component: _import('model/App/Activity/ActivityAdd'),
    meta: {
      auth: 'ap000002'
    }
  },
  {
    path: '/app/activity/edit/:id',
    name: 'AppActivityEdit',
    component: _import('model/App/Activity/ActivityAdd'),
    meta: {
      auth: 'ap000002'
    }
  },
  {
    path: '/app/activity/test/:activity_id',
    name: 'AppActivityTest',
    component: _import('model/App/Activity/ActivityTest'),
    meta: {
      auth: 'ap000002'
    }
  },
  {
    path: '/app/activity/crowdattribute',
    cn: '人群画像',
    name: 'AppActivityTestCrowdattribute',
    component: _import('model/App/Datapackage/Crowdattribute'),
    meta: {
      auth: 'ap000002'
    }
  }, {
    path: '/app/datapackage',
    icon: 'ic_crowd_normal1',
    acicon: 'ic_crowd_hover',
    cn: '人群管理',
    name: 'Datapackage',
    component: _import('model/App/Datapackage'),
    meta: {
      auth: 'ap000003',
      isMenu: true
    }
  }, {
    path: '/app/datapackage/crowdattribute',
    cn: '人群画像',
    name: 'Crowdattribute',
    component: _import('model/App/Datapackage/Crowdattribute'),
    meta: {
      auth: 'ap000003'
    }
  }, {
    path: '/app/datapackage/datapackagedetail/:id',
    cn: '人群包详情',
    name: 'DatapackageDetail',
    component: _import('model/App/Datapackage/DatapackageDetail'),
    meta: {
      auth: 'ap000003'
    }
  }, {
    path: '/app/datapackage/diy/add',
    cn: '新建人群包',
    name: 'Addpackage',
    component: _import('model/App/Datapackage/AddDiypackage'),
    meta: {
      auth: 'ap000003'
    }
  }, {
    path: '/app/finance',
    icon: 'ic_account_normal',
    acicon: 'ic_account_hover',
    cn: '账户管理',
    name: 'Finance',
    component: _import('model/App/Finance'),
    meta: {
      auth: 'ap000004',
      isMenu: true
    }
  }, {
    path: '/app/finance/accountconfig',
    icon: 'ic_account_normal',
    acicon: 'ic_account_hover',
    cn: '账户资质认证',
    name: 'AccountConfig',
    component: _import('model/App/Finance/AccountConfig'),
    meta: {
      auth: 'ap000004'
    }
  }, {
    path: '/app/finance/accountdetail',
    icon: 'ic_account_normal',
    acicon: 'ic_account_hover',
    cn: '账户资质详情',
    name: 'AccountConfig',
    component: _import('model/App/Finance/AccountDetail'),
    meta: {
      auth: 'ap000004'
    }
  }
]

export default appRouter
