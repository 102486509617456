/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 11:07:15
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-22 21:01:19
 */
import Vue from 'vue'

class StateTool {
  /**
   * 判断属性是否存在于实例属性
   * @param { objecy } target 对象
   * @param { string } key 属性
   */
  hasOwn (target, key) {
    return Object.prototype.hasOwnProperty.call(target, key)
  }

  /**
   * 更新单个属性
   * @param { object } target
   * @param { string } key
   * @param { any } val
   */
  update (target, key, val) {
    if (this.hasOwn(target, key)) {
      target[key] = val
      return
    }
    Vue.set(target, key, val)
  }

  /**
   * 更新全部属性
   * @param { object } target
   * @param { object } payload
   */
  updateAllKeys (target, payload) {
    Object.keys(payload).forEach(key => this.update(target, key, payload[key]))
  }

  /**
   * 获取需要更新的属性名数组
   */
  getUnEqualKeys (obj1, obj2) {
    return Object.keys(obj1).filter(key => {
      if (this.hasOwn(obj2, key)) {
        return obj1[key] !== obj2[key]
      }
      return true
    })
  }

  /**
   * 需要更新的新对象
   */
  getUpdateObj (obj1, obj2) {
    return this.getUnEqualKeys(obj1, obj2).reduce((res, key) => {
      res[key] = obj1[key]
      return res
    }, {})
  }
}

export default new StateTool()
