/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-30 09:40:39
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-30 09:41:38
 */
import Vue from 'vue'

export default new Vue()
