/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-04 19:18:31
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-04 19:19:57
 */
import WBoxHeader from './WBoxHeader.vue'
WBoxHeader.install = Vue => {
  Vue.component(WBoxHeader.name, WBoxHeader)
}
export default WBoxHeader
