/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-13 11:09:11
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-16 15:42:51
 */
import Login from './login.vue'
export default Login
