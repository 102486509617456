/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-23 17:13:04
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-12-28 16:59:22
 */
import Vue from 'vue'
import store from '@/store'

// import { Loading } from 'element-ui'
const plugin = {}

// let loading;

// const startLoading = (text) => {
//   loading = Loading.service({
//     text: text
//     // target:'#main'
//   });
// };

// const endLoading = () => {
//   loading.close();
// };

const downloadExcel = async (url, payload, title) => {
  const obj = {
    show: true
  }
  title ? obj.title = title : obj.title = ''
  store.dispatch('updateDownloadModal', obj)
  const newPayload = Object.assign({ }, payload)
  newPayload.page = 1
  Vue.wPost(url, newPayload, {
    responseType: 'arraybuffer',
    onDownloadProgress: (e) => {
      console.log(e)
      let pre = parseInt((e.loaded / e.total) * 100)
      console.log(e)
      console.log(pre)
      if (isNaN(pre)) {
        pre = 100
      }
      store.dispatch('updateDownloadModal', {
        pre: pre
      })
      // startLoading(`已下载${pre}%`)
      if (pre >= 100 || isNaN(pre)) {
        const timer = setTimeout(() => {
          store.dispatch('updateDownloadModal', {
            show: false,
            pre: 0,
            title: ''
          })
          clearTimeout(timer)
        }, 1000)
      }
    }
  }).then(res => {
    if (res === false) {
      store.dispatch('updateDownloadModal', {
        show: false,
        pre: 0,
        title: ''
      })
      return
    }
    if (
      !res.headers['content-disposition'] &&
      !res.headers['content-disposition'].indexOf('=') > -1
    ) {
      return;
    }
    const filename = decodeURIComponent(res.headers['content-disposition'].split('=')[1]);
    const blob = new Blob([res.data], {
      type: ''
    })
    if ('msSaveOrOpenBlob' in navigator) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      // eslint-disable-next-line no-use-before-define
      window.URL.revokeObjectURL(blobURL);
      const blobURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = blobURL;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobURL);
    }
  })
}

plugin.install = Vue => {
  Vue.prototype.$wExport = downloadExcel
  Vue.wExport = Vue.prototype.$wExport
}

export default plugin
