/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 10:31:00
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-14 00:03:46
 */
import Vue from 'vue'
import Vuex from 'vuex'
import state from './states'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import modules from './model'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules,
  state,
  getters,
  mutations,
  actions
})
