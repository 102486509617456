/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 17:08:44
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-24 14:27:07
 */

/**
* 按需要引入，并未挂在到Vue实例上。 例：import { wVerifyUrl } from '@/plugins/verifier'
*/
import Vue from 'vue'
import { wGetImgInfo, wGetCommonDivisor } from '../utils'

const verify = (reg, str) => {
  return reg.test(str)
}

export const wVerifyIDFA = (str) => {
  const regexp = /^([0-9a-zA-Z]{1,})(([/\s-][0-9a-zA-Z]{1,}){4})$/
  return verify(regexp, str)
}

export const wVerifyIMEI = (str) => {
  const regexp = /^\d{15}$/
  return verify(regexp, str)
}

export const wVerifyOAID = (str) => {
  const regexp = /^[a-z_A-Z0-9-\\.!@#\\$%\\\\^&\\*\\)\\(\\+=\\{\\}\\[\]\\/",'<>~\\·`\\?:;|]+{16, 64}$/
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是 ip
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是ip，false: 当前字符串不是ip
 */

export const wVerifyIpv4 = (str) => {
  const regexp = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
  // console.log(regexp.test(str))
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是 ip
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是ip，false: 当前字符串不是ip
 */

export const wVerifyIpv6 = (str) => {
  const regexp = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/gm
  // console.log(regexp.test(str))
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是 url
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是url，false: 当前字符串不是url
 */
export const wVerifyUrl = (str) => {
  const regexp = /(ht|f)tp(s?):\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-.?,'/\\+&amp;%$#_]*)?/gi
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是AppStore的APP链接
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是AppStore链接，false: 当前字符串不是AppStore链接
 */
export const wVerifyAppStoreUrl = (str) => {
  const regexp = /^(http|https):\/\/apps\.apple\.com\/.+\/id[0-9]+.*/gi
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是itunes的APP链接
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是itunes链接，false: 当前字符串不是itunes链接
 */
export const wVerifyItunesUrl = (str) => {
  const regexp = /^(http|https):\/\/itunes\.apple\.com\/.+\/id[0-9]+.*/gi
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是以.apk结尾
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是.apk链接，false: 当前字符串不是.apk链接
 */
export const wVerifyApkUrl = (str) => {
  const regexp = /^http[s]?:\/\/.*[^/]+\.(apk|APK)$/gi
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是邮箱
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是邮箱，false: 当前字符串不是邮箱
 */
export const wVerifyEmail = (str) => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是手机
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是手机，false: 当前字符串不是手机
 */
export const wVerifyPhone = (str) => {
  const regexp = /^(0|86|17951)?(13[0-9]|15[012356789]|18[0-9]|14[579]|17[0-9]|19[89]|)[0-9]{8}$/gi
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是固定电话
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是固定电话，false: 当前字符串不是固定电话
 */
export const wVerifyTelephone = (str) => {
  const regexp = /\d{3,4}-\d{7,8}$/gi
  return verify(regexp, str)
}

/**
 * 校验当前字符串是不是银行账号
 * @param {String} str 待校验字符串
 * @returns {Boolean} true: 当前字符串是银行账号，false: 当前字符串不是银行账号
 */
export const wVerifyBankNum = (str) => {
  const regexp = /^\d{8,30}$/gi
  return verify(regexp, str)
}

/**
 * 校验当前是不是浮点数
 * @param {String} str 待校验数字
 * @returns {Boolean} true: 当前字符串是浮点数，false: 当前字符串不是浮点数
 */
export const wVerifyDeci = (str) => {
  const regexp = /^\d+(\.\d+)?$/gi
  return verify(regexp, str)
}

/**
 * 校验浮点数后面是不是只有两位小数
 * @param {String} str 待校验数字
 * @returns {Boolean} true: 当前浮点数后只有两位小数，false: 当前浮点数后不只有两位小数
 */
export const wVerifyDeciTwo = (str) => {
  const regexp = /^\d+(\.\d{1,2})?$/gi
  return verify(regexp, str)
}

/**
 * 校验当前是不是数字
 * @param {String} str 待校验数字
 * @returns {Boolean} true: 当前字符串是浮点数，false: 当前字符串不是数字
 */
export const wVerifyNum = (str) => {
  const regexp = /^[0-9]*$/gi
  return verify(regexp, str)
}

/**
 * 校验当前是不是数字和英文
 * @param {String} str 待校验字符串
 */
export const wVerifyNumOrEn = (str) => {
  const regexp = /^[a-zA-Z0-9]*$/gi
  return verify(regexp, str)
}

/**
 * 校验当前是不是浮点数最多两位小数
 * @param {String} str 待校验数字
 * @returns {Boolean} true: 当前字符串是浮点数，false: 当前字符串不是浮点数
 */
export const wVerifyDeci2 = (str) => {
  const regexp = /^\d+(\.\d{1,2})?$/gi
  return verify(regexp, str)
}

/**
 * 图片统一校验方法
 * @param {Array} file 文件
 * @param {Number} width  宽度
 * @param {Number} height 高度
 * @param {Number} size 最大大小(以K为单位)
 * @param {str} type 图片类型
 * @param {Boolean} isRadio 是否计算比例
 */
export const wImgValid = async (file, width, height, size, checkGif, isRadio = false, isSelfSize = false) => {
  const fileType = file.type ? file.type.toLowerCase() : file.type
  const isJpg = fileType === 'image/jpeg'
  const isPng = fileType === 'image/png'
  const isGif = fileType === 'image/gif'
  if (checkGif) {
    if (!(isJpg || isPng || isGif)) {
      Vue.wToast.error('格式JPG、PNG或GIF')
      return Promise.reject(new Error())
    }
  } else {
    if (!(isJpg || isPng)) {
      Vue.wToast.error('格式JPG或PNG')
      return Promise.reject(new Error())
    }
  }
  let isLtSize = true
  if (size) {
    isLtSize = file.size / 1024 <= size
  }
  const fileInfo = await wGetImgInfo(file)
  const isRightRadio = ((fileInfo.width / fileInfo.height).toFixed(2) === (width / height).toFixed(2))
  const isMinWH = fileInfo.width >= width && fileInfo.height >= height
  const commonDivisor = await wGetCommonDivisor(width, height)
  const isFileSize = (fileInfo.width === width && fileInfo.height === height) || (width === 0 && height === 0) || (isMinWH && isRadio) // 高度宽度为 0 不做限制

  if (isRadio && !isRightRadio) {
    Vue.wToast.error(`图片比例为 ${width / commonDivisor}:${height / commonDivisor}`)
    return Promise.reject(new Error())
  }

  if (isSelfSize) {
    if ((fileInfo.width < width || fileInfo.height < height) || !isRightRadio) {
      Vue.wToast.error(`图片尺寸不小于 ${width}*${height}且比例为 ${width / commonDivisor}:${height / commonDivisor}`)
      return Promise.reject(new Error())
    }
  }

  if (!isFileSize) {
    Vue.wToast.error(`图片${isRadio ? '最小' : ''}尺寸为 ${width}*${height}`)
    return Promise.reject(new Error())
  }

  if (!isLtSize) {
    Vue.wToast.error(`不超过${size}KB`)
    return Promise.reject(new Error())
  }

  if (isLtSize && isFileSize) {
    return true
  } else {
    return Promise.reject(new Error())
  }
}

/**
 * 图片统一校验方法
 * @param {Array} file 文件
 * @param {Number} width  宽度 无需校验时传0
 * @param {Number} height 高度 无需校验时传0
 * @param {Number} size 最大大小(以K为单位)
 * @param {Boolean} checkGif 是否支持GIF
 */
export const wImgValidNormal = async (file, width, height, size, checkGif) => {
  const fileType = file.type ? file.type.toLowerCase() : file.type
  const isJpg = fileType === 'image/jpeg'
  const isPng = fileType === 'image/png'
  const isGif = fileType === 'image/gif'

  if (checkGif) {
    if (!(isJpg || isPng || isGif)) {
      Vue.wToast.error('格式JPG、PNG或GIF')
      return Promise.reject(new Error())
    }
  } else {
    if (!(isJpg || isPng)) {
      Vue.wToast.error('格式JPG或PNG')
      return Promise.reject(new Error())
    }
  }

  const isLtSize = file.size / 1024 <= size
  const fileInfo = await wGetImgInfo(file)
  const isFileSize = (fileInfo.width === width && fileInfo.height === height) || (width === 0 && height === 0) // 高度宽度为 0 不做限制

  if (!isFileSize) {
    Vue.wToast.error(`图片尺寸为 ${width}*${height}`)
    return Promise.reject(new Error())
  }

  if (!isLtSize) {
    Vue.wToast.error(`不超过${size}KB`)
    return Promise.reject(new Error())
  }

  if (isLtSize && isFileSize) {
    return true
  } else {
    return Promise.reject(new Error())
  }
}
