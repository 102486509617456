<!--
 * @Author: heyan
 * @Date: 2023-12-28 17:12:56
 * @LastEditors: heyan
 * @LastEditTime: 2023-12-29 11:38:39
 * @Description: 门户跳转过来的中间加载页面
-->
<template>
  <div class="loading-page" v-loading="true"></div>
</template>

<script>
import { mapGetters as mapRootGetters } from 'vuex'
export default {
  name: 'LoadingPage',
  computed: {
    ...mapRootGetters([
      'menus'
    ])
  },
  watch: {
    menus: {
      handler (v) {
        if (v && v.length > 0) {
          if (v[0].children.length > 0) {
            this.$router.replace({ path: v[0].children[0].path })
          } else {
            this.$router.replace({ path: v[0].path })
          }
        } else {
          this.$router.push('/websites/login')
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-page {
  width: 100%;
  height: 100%;
}
</style>
