/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-03 15:59:06
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-13 19:11:48
 */
import * as types from './mutation-types'
import { cloneDeep } from 'lodash'
import stateTool from '@/store/state-tool'
import StorageTool from '@/service/sessionStorage'
export default {

  [types.UPDATED_RIGHTS] (state, payload) {
    state.rights = cloneDeep(payload)
  },

  [types.UPDATED_SUCCESS_DIALOG] (state, payload) {
    state.successDialog = Object.assign({}, cloneDeep(payload))
  },

  [types.UPDATED_NOTICE_INFO] (state, payload) {
    state.notice_info = cloneDeep(payload)
  },

  [types.UPDATED_CAN_BUY_COMBO] (state, payload) {
    state.can_buy_combo = payload
  },

  [types.UPDATED_AUTH] (state, payload) {
    state.auth = cloneDeep(payload)
  },

  [types.UPDATED_USER_INFO] (state, payload) {
    state.userInfo = cloneDeep(payload)
  },

  [types.UPDATED_DOWNLOAD_MODAL] (state, payload) {
    state.downloadModal = Object.assign({}, state.downloadModal, payload)
  },

  [types.UPDATED_MENUS] (state, payload) {
    state.menus = cloneDeep(payload)
  },

  [types.UPDATED_LOGIN] (state, payload) {
    state.isLogin = payload
  },

  [types.UPDATED_ENUM_MAP] (state, payload) {
    state.enum_map = Object.assign({}, payload)
  },

  [types.UPDATED_IS_MOBILE] (state, payload) {
    state.isMobile = payload
  },

  /**
   * 设置屏幕宽度
   * @param {Object} payload 屏幕宽度
   */
  [types.SCREEN_WIDTH] (state, payload) {
    state.screenWidth = payload.screenWidth
  },

  /**
   * 设置屏幕宽度
   * @param {Object} payload 屏幕宽度
   */
  [types.SCREEN_HEIGHT] (state, payload) {
    state.screenHeight = payload.screenHeight
  },

  /**
   * 更新账户信息
   */
  async [types.UPDATE_ACCOUNT_INFO] (state, payload) {
    await stateTool.updateAllKeys(state.accountInfo, payload)
  },

  /**
   * 设置支付信息
   */
  async [types.UPDATE_PAYORDER_INFO] (state, payload) {
    const payOrderInfo = state.payOrderInfo
    const storageTool = new StorageTool('FINANCE_PAYORDER')
    if (payOrderInfo.detail === null) {
      const storageData = storageTool.getItem()
      if (storageData && [payOrderInfo.order_id, payload.order_id].includes(storageData.order_id)) {
        payload = Object.assign({}, storageData, payload)
      }
    }
    await stateTool.updateAllKeys(state.payOrderInfo, payload)
    storageTool.setItem(state.payOrderInfo)
  }
}
