/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-12-07 18:00:16
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-12-07 18:21:02
 */
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'WSuccessDialog',
  data () {
    return {

    }
  },
  methods: {
    ...mapActions([
      'updatedSuccessDialog'
    ])
  },
  computed: {
    ...mapGetters([
      'successDialog'
    ])
  }
}
