/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 15:09:09
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 19:03:24
 */
import Crowdattribute from './Crowdattribute'
import AddDiypackage from './AddDiypackage'
import components from './components'

const state = {
  name: 'Datapackage'
}

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  modules: {
    Crowdattribute,
    AddDiypackage,
    components
  },
  state,
  getters,
  mutations,
  actions
}
