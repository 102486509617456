/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-25 19:30:34
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-25 19:37:43
 */
import WRemind from './WRemind.vue'
export default WRemind
