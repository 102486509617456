/*
 * @Author: AllenXD
 * @Date: 2022-05-30 10:26:08
 * @Email: xudong@adbright.cn
 * @LastEditors: heyan
 * @LastEditTime: 2023-12-26 18:25:48
 * @Description: file information
 * @Company: your company
 */
/* eslint-disable */
const objectProto = Object.prototype
const toString = objectProto.toString
const hasOwnProperty = objectProto.hasOwnProperty

function is(val, type) {
  return toString.call(val) === `[object ${type}]`
}

export function isNumber(val) {
  return is(val, 'Number')
}
export function isString(val) {
  return is(val, 'String')
}
export function isDate(val) {
  return is(val, 'Date')
}
export function isNull(val) {
  return val === null
}
export function isNaN(val) {
  return isNumber(val) && val !== Number(val)
}
export function isUndefined(val) {
  // eslint-disable-next-line no-undefined
  return val === undefined
}
export function isArray(val) {
  return val && Array.isArray(val)
}
export function isObject(val) {
  return val !== null && is(val, 'Object')
}
export function isFunction(val) {
  return typeof val === 'function'
}
export function isPromise(val) {
  return is(val, 'Promise') && isObject(val) && isFunction(val.then) && isFunction(val.catch)
}
export function isEmpty(val) {
  if (isArray(val) || isString(val)) {
    return val.length === 0
  }

  if (val instanceof Map || val instanceof Set) {
    return val.size === 0
  }

  if (isObject(val)) {
    return Object.keys(val).length === 0
  }

  return false
}
export function isSemi(vl, val, key = '*') {
  vl = String(vl)
  if (isArray(val)) {
    return isSemi(key, '*') ?
      val.some(it => String(it) === vl) : val.some(it => String(it[key]) === vl)
  } else {
    val = String(val)
    return vl === val
  }
}
export function isLength(ele, key = '', status = 1) {
  if (isUndefined(ele) || isNull(ele)) return false
  if (isArray(ele)) {
    return isEmpty(key) ? !isEmpty(ele) : status === 1 ? !ele.some(vl => isEmpty(vl[key])) : !isEmpty(ele.filter(vl => !isEmpty(vl[key])))
  } else if (isObject(ele)) {
    if (isEmpty(key)) return Object.keys(ele).length
    if (!hasOwnProperty.call(ele, key) || !isArray(ele[key])) return false
    return !isEmpty(ele[key])
  }
  return false
}

export function isPc() {
  if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return false
  }
  return true
}

export function isFirefox() {
  let sys = false
  var ua = navigator.userAgent.toLowerCase()
  ua.match(/firefox\/(\d+\.\d+)/i) ? sys = true : sys = false
  return sys
}

export function isSafari() {
  let sys = false
  var ua = navigator.userAgent.toLowerCase()
  ua.match(/version\/([\d.]+).*safari/) ? sys = true : sys = false
  return sys
}

export default {
  isNumber,
  isString,
  isDate,
  isNull,
  isNaN,
  isArray,
  isObject,
  isFunction,
  isPromise,
  isEmpty,
  isSemi,
  isLength,
  isPc,
  isFirefox
}