/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-14 13:44:27
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-14 13:46:04
 */
import Register from './register.vue'
export default Register
