/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-04 09:35:20
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-04 09:38:13
 */
import WHeader from './WHeader.vue'
export default WHeader
