/* eslint-disable quote-props */
/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-13 09:48:01
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-14 09:11:40
 */
const _import = require('../_import_' + process.env.NODE_ENV)

const adminRouter = [
  {
    path: '/admin/overview',
    icon: 'ic_data_normal',
    acicon: 'ic_data_hover',
    cn: '数据看板',
    name: 'AdminOverview',
    component: _import('model/Admin/Overview'),
    meta: {
      auth: 'ad000001',
      isMenu: true
    }
  },
  {
    path: '/admin/agent',
    icon: 'ic_agent_normal',
    acicon: 'ic_agent_hover',
    cn: '代理商管理',
    name: 'AdminAgent',
    component: _import('model/Admin/Agent'),
    meta: {
      auth: 'ad000002',
      isMenu: true
    }
  },
  {
    path: '/admin/agent/add',
    name: 'AdminAgentAdd',
    component: _import('model/Admin/Agent/AgentAdd'),
    meta: {
      auth: 'ad000002'
    }
  },
  {
    path: '/admin/agent/edit/:id',
    name: 'AdminAgentEdit',
    component: _import('model/Admin/Agent/AgentAdd'),
    meta: {
      auth: 'ad000002'
    }
  },
  {
    path: '/admin/agent/detail/:id',
    name: 'AdminAgentDetail',
    component: _import('model/Admin/Agent/AgentDetail'),
    meta: {
      auth: 'ad000002'
    }
  },
  {
    path: '/admin/custom',
    icon: 'ic_customer_normal',
    acicon: 'ic_customer_hover',
    cn: '客户管理',
    name: 'AdminCustom',
    component: _import('model/Admin/Custom'),
    meta: {
      auth: 'ad000003',
      isMenu: true
    }
  }, {
    path: '/admin/custom/detail/:id',
    name: 'AdminCustomDetail',
    component: _import('model/Admin/Custom/CustomDetail'),
    meta: {
      auth: 'ad000003'
    }
  }, {
    path: '/admin/datapackage',
    icon: 'ic_crowd_normal1',
    acicon: 'ic_crowd_hover',
    cn: '人群管理',
    name: 'Datapackage',
    component: _import('model/Admin/Datapackage'),
    meta: {
      auth: 'ad000004',
      isMenu: true
    }
  }, {
    path: '/admin/finance',
    icon: 'ic_money_normal',
    acicon: 'ic_money_hover',
    cn: '财务管理',
    name: 'Finance',
    component: _import('model/Admin/Finance'),
    meta: {
      auth: 'ad000005',
      isMenu: true
    }
  }, {
    path: '/admin/user',
    icon: 'ic_user_normal',
    acicon: 'ic_user_hover',
    cn: '用户管理',
    name: 'About',
    component: _import('model/Admin/User'),
    meta: {
      auth: 'ad000006',
      isMenu: true
    }
  }, {
    path: '/admin/activity',
    icon: 'ic_activity_normal',
    acicon: 'ic_activity_hover',
    cn: '活动管理',
    name: 'About',
    component: _import('model/Admin/Activity'),
    meta: {
      auth: 'ad000008',
      isMenu: true
    }
  }, {
    path: '/admin/activity/detail/:id',
    cn: '活动管理',
    name: 'AdminActivityDetail',
    component: _import('model/Admin/Activity/ActivityDetail'),
    meta: {
      auth: 'ad000008'
    }
  }, {
    path: '/admin/count',
    icon: 'ic_datalist_normal',
    acicon: 'ic_datalist_hover',
    cn: '数据统计',
    name: 'AdminCount',
    component: _import('model/Admin/Count'),
    meta: {
      auth: 'ad000009',
      isMenu: true
    }
  }, {
    path: '/admin/combo',
    icon: 'ic_combo_normal',
    acicon: 'ic_combo_hover',
    cn: '套餐管理',
    name: 'AdminCombo',
    component: _import('model/Admin/Combo'),
    meta: {
      auth: 'ad000010',
      isMenu: true
    }
  }
]

export default adminRouter
