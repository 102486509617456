/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-12-07 17:57:59
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-12-07 18:06:35
 */
import WSuccessDialog from './WSuccessDialog.vue'
WSuccessDialog.install = Vue => {
  Vue.component(WSuccessDialog.name, WSuccessDialog)
}
export default WSuccessDialog
