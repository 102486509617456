/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-05 10:41:50
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-05 10:43:52
 */
import WButton from './WButton.vue'
WButton.install = Vue => {
  Vue.component(WButton.name, WButton)
}
export default WButton
