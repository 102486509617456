/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-22 14:32:49
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-22 14:34:10
 */
import Success from './success.vue'
export default Success
