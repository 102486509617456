/* eslint-disable semi */
/*
 * @Author: AllenXD
 * @Date: 2022-08-05 19:38:55
 * @Email: xudong@adbright.cn
 * @LastEditors: heyan
 * @LastEditTime: 2023-12-26 18:26:11
 * @Description: file information
 * @Company: your company
 */
/* eslint-disable */
import { isEmpty, isArray } from './is'

export function getUrlParam(link, key = '') {
  let url = ''
  if (isEmpty(link)) {
    url = location.href.toString()
  } else {
    url = link.toString()
  }
  const linkArr = url.split('?');
  let linkObj = {};
  const params = Object.create(null);

  if (isArray(linkArr) && linkArr.length > 1) {
    linkObj = linkArr[1].split('&');
    linkObj.forEach(element => {
      element = element.split('=');
      params[element[0]] = element[1];
    });
  } else {
    return null
  }
  if (key === '') {
    return params;
  } else {
    const objectProto = Object.prototype
    const hasOwnProperty = objectProto.hasOwnProperty
    if (hasOwnProperty.call(params, key)) {
      return params[key]
    } else {
      return -1
    }
  }
}

// eslint-disable-next-line require-jsdoc
export function setParamUrl(baseUrl, obj) {
  let parameters = '';
  let url = '';
  for (const key in obj) {
    parameters += key + '=' + encodeURIComponent(obj[key]) + '&';
  }
  parameters = parameters.replace(/&$/, '');
  if (/\?$/.test(baseUrl)) {
    url = baseUrl + parameters;
  } else {
    url = baseUrl.replace(/\/?$/, '?') + parameters;
  }
  return url;
}

export const urlDel = (url, name) => {
  var urlArr = url.split('?')
  let urlte
  if (urlArr.length > 1 && urlArr[1].indexOf(name) > -1) {
    var query = urlArr[1]
    var obj = {}
    var arr = query.split('&')
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].split('=')
      obj[arr[i][0]] = arr[i][1]
    };
    delete obj[name]
    console.log(obj, '-------')
    if (JSON.stringify(obj) !== '{}') { // 判断obj还有值没
      urlte = urlArr[0] + '?' + JSON.stringify(obj).replace(/[\"\{\}]/g, '').replace(/\:/g, '=').replace(/\,/g, '&')
    } else {
      urlte = urlArr[0]
    }
    return urlte
  } else {
    return url
  };
}

export function removeQueryParameters(url, parametersToRemove) {
  let newUrl = url;
  parametersToRemove.forEach((param) => {
    let regex = new RegExp('([?&])' + param + '=.*?(&|$)', 'i');
    let separator = newUrl.indexOf('?') !== -1 ? '&' : '?';
    newUrl = newUrl.replace(regex, (match, start, end) => start === '?' ? '?' : end);
  });
  newUrl = newUrl.replace(/([?&])$/, '');
  return newUrl;
}