/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-04 11:25:54
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-04 17:07:24
 */
import store from '@/store'
import Vue from 'vue'
const plugin = {}
plugin.install = Vue => {
  /**
   * 传入按钮的链接
   */
  Vue.prototype.$_has = function (value) {
    let isExist = false;
    const auth = store.state.auth
    if (!Array.isArray(auth)) {
      return false
    }

    if (Array.isArray(value)) {
      isExist = Boolean(auth.filter((val) => { return value.indexOf(val) > -1 }).length > 0)
    } else {
      if (auth.indexOf(value) > -1) {
        isExist = true
      }
    }

    return isExist
  }
}

/**
 * 权限指令
 */
Vue.directive('has', {
  bind: function (el, binding) {
    if (!Vue.prototype.$_has(binding.value)) {
      Vue.nextTick(() => {
        el.parentNode.removeChild(el);
      })
    }
  }
});
export default plugin
