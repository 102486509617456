/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-14 10:32:45
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 17:32:46
 */

// 首页 - 登录弹窗 - 登录
export const GET_ACCOUNT_STATUS = '/open/auth/check_status.do' // 验证账号存在情况 -1：未注册，1-已启用，2-已停用
export const GET_RSA_PUBLISH_KEY = '/open/auth/get_secret.do' // 获取RSA公钥
export const LOGIN_SUBMIT = '/open/auth/login.do' // 提交登录

// 首页 - 登录弹窗 - 注册
export const GET_GEETEST_CONFIG = '/open/auth/geetest/init.do' // 获取极验后端配置
export const VERIFY_GEETEST_CODE = '/open/auth/geetest/validate.do' // 验证极验验证码
export const GET_VERIFY_CODE = '/open/auth/get_verify_code.do' // 获取验证码
export const REGISTER_SUBMIT = '/open/auth/register.do' // 提交注册

// 首页 - 重置密码 - 下一步
export const RESET_CHECK_UPDATE_CODE = '/open/auth/check_update_code.do' // 校验验证码
export const RESET_GET_VERIFY_CODE = '/open/auth/get_update_code.do' // 获取验证码
export const RESET_RESET_PASSWORD = '/open/auth/reset_password.do' // 重置密码

// 管理后台 - 数据看板
export const ADMIN_OVERVIEW_GET_OVERVIEW_DATA = '/admin/overview/get.do' // 查询总体信息
export const ADMIN_OVERVIEW_GET_TREND_DATA = '/admin/overview/list.do' // 查询趋势信息

// 客户前台 - 数据看板
export const APP_OVERVIEW_GET_OVERVIEW_DATA = '/app/overview/get.do' // 查询总体信息
export const APP_OVERVIEW_GET_TREND_DATA = '/app/overview/list.do' // 查询趋势信息

// 代理后台 - 数据看板
export const OPERATE_OVERVIEW_GET_OVERVIEW_DATA = '/operate/overview/get.do' // 查询总体信息
export const OPERATE_OVERVIEW_GET_TREND_DATA = '/operate/overview/list.do' // 查询趋势信息

// 人群管理 - 新建人群包（自定义人群包）
export const APP_DIYPACKAGE_ADD_SEARCH_ACTIVITY = '/app/datapackage/diy/search_activity.do' // 获取活动列表
export const OPERATE_DIYPACKAGE_ADD_SEARCH_ACTIVITY = '/operate/datapackage/diy/search_activity.do' // 获取活动列表
