/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-17 16:56:53
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-25 01:42:17
 */
import Acvicity from './Acvicity'
import Datapackage from './Datapackage'
import Overview from './Overview'
import Finance from './Finance'

const state = {
}

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  modules: {
    ...Acvicity,
    Datapackage,
    Overview,
    Finance
  },
  state,
  getters,
  mutations,
  actions
}
