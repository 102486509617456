/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 15:32:27
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-18 15:34:09
 */
import WInputValid from './WInputValid.vue'
WInputValid.install = Vue => {
  Vue.component(WInputValid.name, WInputValid)
}
export default WInputValid
