<!--
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-03 11:27:04
 * @LastEditors: Mingkit
 * @LastEditTime: 2022-01-27 17:58:02
-->
<template>
  <div class="w-login" id="w-login">
    <div :class="['w-login--header', pageHeight > 0 ? 'w-login--header-active' : '']">
      <div class="w-login--header--content">
        <div class="w-login--header--content--left">
          <w-svg
            data_iconName="img_logo_databright_pay"
            class-name="w-login--header--content--left--logo"></w-svg>
        </div>
        <div class="w-login--header--content--right">
          <w-button type="primary" class="w-login--header--content--right--loginBtn" round @click="goToLogin">立即登录</w-button>
          <w-button type="text" class="w-login--header--content--right--loginBtn-m" round @click="goToLogin">立即登录</w-button>
        </div>
      </div>
    </div>
    <div class="w-login--box">
      <div class="w-login--box--content">
        <div class="w-login--box--content-top">
          <div class="w-login--box--content-top-left">
            <p class="w-login--box--content-top-left-main">皓数DataBright</p>
            <p class="w-login--box--content-top-left-second">营销数据助手</p>
            <p class="w-login--box--content-top-left-detail">通过人工智能学习算法与大数据挖掘，帮助您实现数据资产建设，满足多场景营销需求，赋能业务增长。</p>
            <w-button class="w-login--box--content-top-left-booking" type="primary" round @click="toBottom">预约演示</w-button>
          </div>
          <div class="w-login--box--content-top-right">
            <w-svg className="w-login--box--content-top-right-img" data_iconName="img_home_databright"></w-svg>
          </div>
        </div>
        <div class="w-login--box--content-mid">
          <div class="w-login--box--content-mid-title">
            选择皓数DataBright<span>，</span><br />跨入数字营销新时代
          </div>
          <div class="w-login--box--content-mid-detail">
            <div class="w-login--box--content-mid-detail-card" v-for="(item, index) in cardList" :key="index">
              <w-svg className="w-login--box--content-mid-detail-card-img" :data_iconName="item.url"></w-svg>
              <div class="w-login--box--content-mid-detail-card-main">{{item.main}}</div>
              <div class="w-login--box--content-mid-detail-card-second">{{item.second}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-login--footer" id="booking">
      <div class="w-login--footer--content">
        <div class="w-login--footer--content-top">
          <div class="w-login--footer--content-top-left">
            <p class="w-login--footer--content-top-left-title">预约演示</p>
            <el-form ref="form" :model="form" :rules="rules" label-width="0" class="w-form w-login--footer--content-top-left-form">
              <el-form-item class="w-form--item w-form--item-name" prop="contact_name">
                <w-input-valid
                  placeholder="姓名"
                  v-model="form.contact_name"
                  isSplitType
                  @input="(v) => { form.contact_name = v }"
                ></w-input-valid>
              </el-form-item>
              <el-form-item class="w-form--item w-form--item-company" prop="company_name">
                <w-input-valid
                  placeholder="公司"
                  v-model="form.company_name"
                  isSplitType
                  @input="(v) => { form.company_name = v }"
                ></w-input-valid>
              </el-form-item>
              <el-form-item class="w-form--item" prop="phone">
                <w-input-valid
                  placeholder="手机号码"
                  v-model="form.phone"
                  isSplitType
                  @input="(v) => { form.phone = v }"
                ></w-input-valid>
              </el-form-item>
              <el-form-item class="w-form--item" prop="remark">
                <w-input-valid
                  placeholder="需求备注"
                  v-model="form.remark"
                  isSplitType
                  @input="(v) => { form.remark = v }"
                ></w-input-valid>
              </el-form-item>
            </el-form>
            <w-button class="w-login--footer--content-top-left-bookingbtn" type="primary" round ref="submitButton" subBtn @click="booking">立即预约</w-button>
          </div>
          <div class="w-login--footer--content-top-right">
            <p class="w-login--footer--content-top-right-title">联系我们</p>
            <p class="w-login--footer--content-top-right-con">电话：400 008 2908</p>
            <p class="w-login--footer--content-top-right-con">邮箱：bd@adbright.cn</p>
            <p class="w-login--footer--content-top-right-con">地址：深圳市南山区科兴科学园B1栋703室</p>
            <div class="w-login--footer--content-top-right-code">
              <div class="w-login--footer--content-top-right-code-card">
                <w-svg className="w-login--footer--content-top-right-code-card-img" data_iconName="img_home_weixin"></w-svg>
                <p>微信公众号</p>
              </div>
              <div class="w-login--footer--content-top-right-code-service">
                <div class="w-login--footer--content-top-right-code-content">
                  <img src="https://www.adbright.cn/dl/img/pc/footer/img_weixin_customer_service.png" alt="" class="w-login--footer--content-top-right-code-card-img">
                </div>
                <p>皓数客服</p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-login--footer--content-bottom">
          <div class="w-login--footer--content-bottom-up">
            <template v-for="(item, index) in linkList">
              <a class="w-login--footer--content-bottom-up-link" target="_blank" :key="index" :href="item.url">{{item.name}}</a>
              <span :key="'line' + index" v-show="index < linkList.length - 1"> | </span>
            </template>
          </div>
          <div class="w-login--footer--content-bottom-down">
            <span>© <a href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备15014511号 版权所有</a> <br />© {{ currentYear }}皓数DataBright | 皓量科技（深圳）有限公司</span>
          </div>
        </div>
      </div>
    </div>
    <div class="w-login-affix">
      <div ref="wechat" @mouseenter="mouseenter('wechat')" @mouseleave="mouseleave('wechat')" class="w-login-affix-box">
        <i ref="wechat-icon" class="showIcon ab_font_wechat"></i>
        <div class="w-login-affix-box-code">
          <div class="w-login-affix-box-code-card">
            <w-svg className="w-login-affix-box-code-card-img" data_iconName="img_home_weixin"></w-svg>
            <p>微信公众号</p>
          </div>
          <div class="w-login-affix-box-code-service">
            <div class="w-login-affix-box-code-content">
              <img src="https://www.adbright.cn/dl/img/pc/footer/img_weixin_customer_service.png" alt="" class="w-login--footer--content-top-right-code-card-img">
            </div>
            <p>皓数客服</p>
          </div>
        </div>
      </div>
      <div ref="phone" @mouseenter="mouseenter('phone')" @mouseleave="mouseleave('phone')" class="w-login-affix-box">
        <i class="showIcon ab_font_phone"></i>
        <span>400 008 2908</span>
      </div>
    </div>
    <!-- 登录、注册、重置密码 -->
    <dialog-login
      :show-dialog.sync="dialogLogin.showDialog"
      :dialog-type.sync="dialogLogin.dialogType"
      @show-reset="goToReset"
      @show-upgrade="goToUpgrade"
      >
    </dialog-login>

    <w-success-dialog></w-success-dialog>
  </div>
</template>

<script>
import { mapGetters, createNamespacedHelpers, mapActions } from 'vuex'
import WSvg from '../../../components/common/WSvg/WSvg.vue'
import DialogLogin from './dialog/login'
import HandleData from '../../../service/Login'
import wx from 'weixin-jsapi'
const { mapGetters: selMapGetters, mapActions: selfMapActions } = createNamespacedHelpers('Login')

export default {
  metaInfo: { // set a title
    title: '皓数DataBright，营销数据助手，帮助轻松搭建营销数据库',
    meta: [{ // set meta
      name: 'keyWords',
      content: '皓数,DataBright,皓数DataBright,数据服务,数据库,私有数据,营销,营销数据.大数据挖掘,移动广告,程序化广告,技术服务,广告技术'
    }, {
      name: 'description',
      content: '皓数DataBright是皓量科技推出的营销数据助手，帮助轻松搭建营销数据库，依托人工智能学习算法与大数据挖掘，实现营销数据存储和复用，积累私有数据资产。'
    }]
  },
  components: {
    DialogLogin,
    WSvg
  },
  data () {
    return {
      weixinData: {
        appId: '',
        noncestr: '',
        signature: '',
        timestamp: ''
      },
      pageHeight: 0,
      cardList: [
        {
          url: 'img_home_storage',
          main: '数据存储',
          second: '超大数据存储容量'
        },
        {
          url: 'img_home_supervise',
          main: '数据管理',
          second: '跨媒体数据存储和营销数据复用'
        },
        {
          url: 'img_home_analysis',
          main: '数据分析',
          second: '深度洞察投放用户画像'
        }
      ],
      linkList: [
        {
          name: '皓量科技AdBright',
          url: 'https://www.adbright.cn/'
        },
        {
          name: 'AdBright 轻叶',
          url: 'https://h5.adplusx.com/'
        },
        {
          name: 'AdBright 积木',
          url: 'https://web.adbright.cn/'
        },
        {
          name: '小敦客户中心',
          url: 'https://center.wesdom.me?source=Yy4j8'
        }
      ],
      form: {
        contact_name: '',
        company_name: '',
        phone: '',
        remark: ''
      },
      rules: {
        contact_name: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写姓名')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        company_name: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写公司')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        phone: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写手机号码')
            }
            if (!/^1\d{10}$/.test(val)) {
              res('请填写正确的手机号码')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        remark: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写需求备注')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }]
      },
      currentYear: ''
    }
  },
  computed: {
    ...mapGetters([
      'isLogin',
      'menus'
    ]),

    ...selMapGetters([
      'dialogLogin',
      'dialogReset'
    ])
  },
  beforeMount () {
    console.log(this.isLogin)
    if (this.isLogin === true) {
      console.log(this.menus)
      if (this.menus.length > 0) {
        if (this.menus[0].children.length > 0) {
          this.$router.replace({ path: this.menus[0].children[0].path })
        } else {
          this.$router.replace({ path: this.menus[0].path })
        }
      }
    }
  },
  async mounted () {
    const nowDate = new Date()
    this.currentYear = nowDate.getFullYear();
    await this.getWexinData()
  },
  created () {
    this.listenerFunction();
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.listenerFunction);
  },
  methods: {
    ...mapActions([
      'updatedSuccessDialog'
    ]),
    ...selfMapActions([
      'updateDialogLogin'
    ]),
    async getWexinData () {
      const data = await this.$wGet('/open/weixin/getWxSign.do', {
        url: window.location.href.split('#')[0]
        // url: 'https://adbright.cn'
      })
      console.log(data)
      this.weChatShare(data)
      // this.weixinData = Object.assign(this.weixinData, data)
      // console.log(data)
    },
    weChatShare (data) {
      const debug = false
      const shareForm = {
        title: '皓数DataBright | 营销数据助手',
        desc: '帮助轻松搭建营销数据库',
        link: window.location.origin,
        imgUrl: `${window.location.origin}/shareLogo.jpg`
      }

      wx.config({
        debug: debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，公众号的唯一标识  /*需要在对应公众号配置对应的域名*/
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.noncestr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        jsApiList: ['checkJsApi', 'hideMenuItems', 'showMenuItems', 'hideAllNonBaseMenuItem', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      })
      wx.error((err) => {
        console.log(err)
      })
      wx.ready(() => {
        // 分享给朋友
        wx.onMenuShareAppMessage({
          title: shareForm.title,
          desc: shareForm.desc,
          link: shareForm.link,
          imgUrl: shareForm.imgUrl,
          success: () => {},
          cancel: () => {},
          fail: (res) => {
            // alert('onMenuShareAppMessage', JSON.stringify(res));
          }
        })

        // 分享到朋友圈
        wx.onMenuShareTimeline({
          title: shareForm.title,
          desc: shareForm.desc,
          link: shareForm.link,
          imgUrl: shareForm.imgUrl,
          success: () => {
            // alert('已分享');
          },
          cancel: () => {
            // alert('取消分享');
          },
          fail: (res) => {
            // alert(JSON.stringify(res));
          }
        })

        // 分享到QQ
        wx.onMenuShareQQ({
          title: shareForm.title,
          desc: shareForm.desc,
          link: shareForm.link,
          imgUrl: shareForm.imgUrl,
          success: () => {},
          cancel: () => {}
        })

        // 分享到微博
        wx.onMenuShareWeibo({
          title: shareForm.title,
          desc: shareForm.desc,
          link: shareForm.link,
          imgUrl: shareForm.imgUrl,
          success: () => {},
          cancel: () => {}
        })
      })
    },
    listenerFunction (e) {
      document.addEventListener('scroll', this.handleScroll, true);
    },
    handleScroll () {
      this.pageHeight = window.pageYOffset
    },
    mouseenter (type) {
      this.$nextTick(() => {
        if (type === 'wechat') {
          this.$refs['wechat-icon'].style.display = 'none'
          this.$refs[type].style.padding = '0'
          this.$refs[type].style.transform = 'translateX(-218px)'
          this.$refs[type].style.height = '152px'
          this.$refs[type].style.width = '260px'
        } else {
          this.$refs[type].style.width = '152px'
          this.$refs[type].style.transform = 'translateX(-110px)'
        }
      })
    },

    mouseleave (type) {
      this.$nextTick(() => {
        if (type === 'wechat') {
          this.$refs[type].style.width = '42px'
          this.$refs[type].style.height = '42px'
          this.$refs[type].style.padding = '6px'
          this.$refs[type].style.transform = 'translateX(0px)'
          this.$refs['wechat-icon'].style.display = 'block'
        } else {
          this.$refs[type].style.width = '42px'
          this.$refs[type].style.transform = 'translateX(0px)'
        }
      })
    },

    booking () {
      this.$refs.form.validate(async v => {
        if (v) {
          const payload = new HandleData(this.form).booking()
          console.log(payload)
          const data = await this.$wPost('/open/db/consulter/add.do', payload)
          if (data !== false) {
            await this.updatedSuccessDialog({
              visible: true,
              title: '预约成功',
              descr: '产品顾问将在24小时内与您联系'
            })
          }
          const formList = ['contact_name', 'company_name', 'phone', 'remark']
          formList.forEach(e => {
            this.$set(this.form, e, '')
          })
          this.$refs.submitButton.updatedLoading(false)
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    },
    toBottom () {
      this.$nextTick(() => {
        const i = 50
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const height = scrollHeight - clientHeight // 超出窗口上界的值就是底部的scrolTop的值
        // document.documentElement.scrollTop += i
        document.documentElement.scrollTop += i
        document.body.scrollTop += i
        const top = document.documentElement.scrollTop || document.body.scrollTop
        console.log('scrollTop:', document.documentElement.scrollTop, 'height:', height)
        if (Math.abs(top - height) > 50) {
          var c = setTimeout(() => this.toBottom(i), 16);
        } else {
          clearTimeout(c);
        }
      })
    },
    /**
     * 显示登录弹窗
     */
    goToLogin () {
      // this.$wToast.success('登录成功')
      this.updateDialogLogin({
        dialogType: 'login',
        showDialog: true
      })
    },

    /**
     * 显示重置密码弹窗
     */
    goToReset () {
      this.updateDialogLogin({
        dialogType: 'reset',
        showDialog: true
      })
    },

    // 显示升级提示弹窗
    goToUpgrade () {
      this.updateDialogLogin({
        dialogType: 'upgrade',
        showDialog: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
