/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-24 12:45:23
 * @LastEditors: Arvin
 * @LastEditTime: 2021-01-14 10:57:20
 */
import { moneyFormat, numberFormat, isNumber } from '@/plugins/utils'

export default {
  name: 'Meal',
  props: {
    className: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => ({})
    },
    activeId: {
      type: [Number, String]
    }
  },
  computed: {
    computedClassName () {
      return [
        'meal',
        !this.data.usable ? 'disabled' : '',
        this.data.id === this.activeId ? 'active' : '',
        this.className].join(' ')
    },

    // 有效期限
    availableTime () {
      if (isNumber(this.data.period)) {
        const last = this.data.period % 12
        if (last > 0) {
          return `有效期限 <span>${this.data.period}</span> 月`
        } else {
          return `有效期限 <span>${this.data.period / 12}</span> 年`
        }
      } else {
        return ''
      }
    }
  },
  methods: {

    // 格式化金钱
    moneyFormat,

    // 数字格式化
    numberFormat
  }
}
