/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 14:31:25
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-30 14:40:19
 */
import StorageTool from '@/service/sessionStorage'
import eventBus from '@/service/eventBus'
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex'

const { mapActions } = createNamespacedHelpers('PayOrder/dialog')

export default {
  name: 'OrderFinish',
  computed: {

    ...mapRootGetters([
      'payOrderInfo'
    ])
  },
  methods: {

    ...mapActions([
      'checkOrderPayStatus',
      'removeOrder',
      'closeDialog'
    ]),

    /**
     * 取消订单
     */
    async cancel () {
      const { order_id: orderId } = this.payOrderInfo
      if (orderId) {
        const data = await this.removeOrder(orderId)
        this.$refs.cancelBtn.updatedLoading(false)
        if (data === true) {
          this.$wToast.success('订单已取消')
          await new StorageTool('FINANCE_PAYORDER').removeItem()
          this.closeDialog()
          // this.$parent.$parent.$parent.$refs['record-table'].refreshTableList()
          eventBus.$emit('app-finance-refresh')
        }
        return
      }
      this.$refs.cancelBtn.updatedLoading(false)
    },

    /**
     * 已完成订单
     */
    async confirm () {
      const { order_id: orderId } = this.payOrderInfo
      if (orderId) {
        const data = await this.checkOrderPayStatus()
        this.$refs.submitBtn.updatedLoading(false)
        if (data === 2) {
          await new StorageTool('FINANCE_PAYORDER').removeItem()
          this.closeDialog()
        }
        // this.$wToast.error('订单暂未支付成功， 请确认订单是否支付成功')
        eventBus.$emit('app-finance-refresh')
        return
      }
      this.$refs.submitBtn.updatedLoading(false)
    }
  }
}
