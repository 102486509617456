/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-24 12:37:01
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-24 12:55:15
 */
import Meal from './meal.vue'
export default Meal
