<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-04 15:58:15
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 20:39:28
-->
<template>
  <el-scrollbar
    tag="div"
    ref="scroll"
    class="scroll-bar__menu"
    wrap-class="scroll-bar__menu__wrap"
    view-class="scroll-bar__menu__list"
  >
    <div class="w-sidebar--logo">
      <w-svg data_iconName="img_logo_databright" className="w-sidebar--logo__svg"></w-svg>
    </div>
    <el-menu
      class="w-sidebar__menu"
      :default-active="acR"
      unique-opened
      router
      >
      <template v-for="(item, index) in menus">
        <el-submenu :index="item.path" :key="index" v-if="item.children && item.children.length > 0">
          <template slot="title">
            <i :class="['w-sidebar__menu-item__icon', item.icon]"></i>{{item.cn}}
          </template>
          <el-menu-item
            v-for="(children, childrenIndex) in item.children"
            :key="childrenIndex"
            :index="children.path"
            class="w-sidebar__menu-item"
          >
            {{children.cn}}
          </el-menu-item>
        </el-submenu>
        <el-menu-item
          :key="index"
          :index="item.path"
          v-else
          class="w-sidebar__menu-item"
          >
            <w-svg v-show="$route.path.indexOf(item.path) < 0" :data_iconName="item.icon" isIconFont className="w-sidebar__menu-item__icon is-normal"></w-svg>
            <w-svg v-show="$route.path.indexOf(item.path) > -1" :data_iconName="item.acicon || ''" className="w-sidebar__menu-item__icon is-hover"></w-svg>
          <div>{{item.cn}}</div>
        </el-menu-item>
      </template>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WMenu',
  computed: {
    ...mapGetters({
      menus: 'menus'
    }),
    acR () {
      const arr = this.$route.path.split('/')
      const arr1 = [arr[1], arr[2]]
      const val = '/' + arr1.join('/')
      console.log(val)
      return val
    }
  },
  mounted () {
    console.log(this.$wDpr())
  }
}
</script>

<style src="./index.scss" lang="scss" scoped></style>
