<template>
  <div class="w-transfer el-transfer">
    <div class="el-transfer-panel">
      <slot name="title-left"></slot>
      <tree-box
        v-bind="$props"
        :data="sourceData"
        :filterPlaceholder="filterPlaceholder"
        @check-change="onSourceCheckedChange"
        :expand-on-click-node="false"
        :overflow="overflow"
        ref="leftTree">
        <slot
          name="item-title-left"
          slot="title-left"></slot>
        <slot
          name="item-title-right"
          slot="title-right"></slot>
        <div slot="item" slot-scope="{node, data}">
          <slot
            name="item"
            :node="node"
            :data="data"></slot>
        </div>
      </tree-box>
      <slot name="left-footer"></slot>
    </div>
    <div class="el-transfer-panel" :class="handleScreenWidth(screenWidth)">
      <slot name="title-right"></slot>
      <result-box
        v-bind="$props"
        :data="targetData"
        :filterPlaceholder="filterPlaceholder"
        :overflow="overflow"
        ref="rightTree">
        <slot
          name="result-item-title-left"
          slot="title-left"></slot>
        <slot
          name="result-item-title-right"
          slot="title-right"></slot>
        <div slot="result-item" slot-scope="{node, data, eventHandle}">
          <slot
            name="result-item"
            :node="node"
            :data="data"
            :event-handle="eventHandle"></slot>
        </div>
        <div slot="result-footer-top" slot-scope="{data, value}">
          <slot
            name="result-footer-top"
            :data="data"
            :value="value"
            ></slot>
        </div>
      </result-box>
      <slot name="right-footer"></slot>
    </div>
  </div>
</template>

<script>
import treeBox from './tree-box'
import resultBox from './result-box'
import { mapGetters } from 'vuex'
export default {
  name: 'WTransferList',
  components: {
    treeBox,
    resultBox
  },
  data () {
    return {
      checkedKeys: [],
      timer: ''
    }
  },
  props: {

    // 穿梭框值
    data: {
      type: Array,
      default () {
        return []
      }
    },

    // 穿梭框已勾选的值
    value: {
      type: Array,
      default () {
        return []
      }
    },

    // 是否开启搜索功能
    filterable: Boolean,

    // 搜索输入框的占位文字
    filterPlaceholder: {
      type: String,
      default: ''
    },

    // 搜索过滤的自定义方法
    filterMethod: Function,

    overflow: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      screenWidth: 'screenWidth'
    }),

    // 左边数据添加属性‘key’和‘label’
    sourceData () {
      const self = this
      return self.data.map(item => {
        item.label = item.name;
        item.key = item.id
        for (const subItem of item.childs) {
          subItem.label = subItem.name
          subItem.key = subItem.id
          if (subItem.childs.length > 0) {
            for (const trdItem of subItem.childs) {
              trdItem.label = trdItem.name
              trdItem.key = trdItem.id
            }
          }
        }
        return item
      })
    },

    // 将value数组根据data解析成树形结构
    targetData () {
      const self = this
      const arr = []
      const data = JSON.parse(JSON.stringify(self.data))
      data.forEach(item => {
        if (self.value.indexOf(item.key) > -1) {
          arr.push(item)
        } else {
          const _item = JSON.parse(JSON.stringify(item))
          const secArr = []
          _item.childs.map((secItem) => {
            if (self.value.indexOf(secItem.key) > -1) {
              secArr.push(secItem)
            } else {
              if (secItem.childs && secItem.childs.length > 0) {
                const trdArr = []
                secItem.childs.map((trdItem) => {
                  if (self.value.indexOf(trdItem.key) > -1) {
                    trdArr.push(trdItem)
                  }
                })
                if (trdArr.length > 0) {
                  secItem.childs = trdArr
                  secArr.push(secItem)
                } else {
                  secItem.childs = []
                }
              }
            }
          })
          if (secArr.length > 0) {
            _item.childs = secArr
            arr.push(_item)
          }
        }
      })
      return arr
    }
  },
  methods: {
    handleScreenWidth (screenWidth) {
      if (screenWidth > 1015) {
        return 'w-transfer-list__margin__left'
      } else if (screenWidth <= 1014 && screenWidth > 998) {
        return ''
      } else if (screenWidth <= 997 && screenWidth > 769) {
        return 'w-transfer-list__margin__top'
      } else if (screenWidth <= 768 && screenWidth > 709) {
        return ''
      } else {
        return 'w-transfer-list__margin__top'
      }
    },

    /**
     * 左边穿梭框选中状态改变时 获取选中状态的保存key值的数组
     */
    onSourceCheckedChange (val, isChecked, isChildsChecked) {
      const self = this
      if (self.timer !== '') {
        clearTimeout(self.timer)
      }
      self.timer = setTimeout(() => {
        self.checkedKeys = self.$refs.leftTree.$refs.tree1.getCheckedKeys()
        self.$emit('input', self.checkedKeys)
      }, 30)
    }
  },

  mounted () {
    const self = this
    // 编辑订单和编辑定向时 左侧tree将已选择的值勾选
    if (self.value.length > 0) {
      setTimeout(() => {
        self.$refs.leftTree.$refs.tree1.setCheckedKeys(self.value)
      }, 50)
    }
  }
}
</script>
