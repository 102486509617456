/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 10:31:00
 * @LastEditors: heyan
 * @LastEditTime: 2023-12-28 18:28:59
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LoadingPage from '../views/LoadingPage.vue'
import LocalRoute from './router'
import { assign, isUndefined } from 'lodash'
import store from '../store'
import Login from '../views/model/Login'

const _import = require('./_import_' + process.env.NODE_ENV)

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

// const route = new LocalRoute().getRouteList()
const route = new LocalRoute().getRoute()
console.log(route)
const routes = [
  {
    path: '/website/index',
    name: 'Login',
    component: Login
  },
  {
    path: '/website/loading',
    name: 'LoadingPage',
    component: LoadingPage
  },
  {
    path: '/pay',
    name: 'PayOrder',
    component: _import('model/PayOrder')
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      ...route,
      {
        path: '/change-password',
        name: 'changePassword',
        children: [],
        component: _import('model/Password')
      }
    ]
  }

]
console.log(routes)

const ignoreList = ['/website/index', '/change-password', '/pay']

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next({ path: '/website/index' })
  }
  if (store.state.rights.length < 1 && !['/website/index', '/website/loading'].includes(to.path)) {
    next({ path: '/website/index' })
  }
  if (store.state.rights.indexOf(to.meta.auth) > -1 || ignoreList.indexOf(to.path) > -1) {
    console.log('有权限')
    if (!isUndefined(from.query.current_back_id) && isUndefined(to.query.current_back_id)) {
      const path = {
        path: to.path,
        query: assign({}, to.query, {
          current_back_id: from.query.current_back_id
        })
      }
      if (Number(from.query.current_back_id) === 2) {
        path.query.current_agent_id = from.query.current_agent_id || ''
      } else if (Number(from.query.current_back_id) === 3) {
        path.query.current_custom_id = from.query.current_custom_id || ''
      }
      next(path)
    } else {
      next()
    }
  } else {
    console.log(to.path, '没有权限')
    next({ path: from.path })
  }
})

export default router
