/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-15 22:16:01
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-26 16:20:53
 */
// import { createNamespacedHelpers } from 'vuex'
import WGeetest from '@/components/WGeetest'
import { createNamespacedHelpers, mapActions as mapRootActions } from 'vuex'

const { mapActions, mapGetters } = createNamespacedHelpers('Login/dialog/login/reset')

export default {
  name: 'login-reset',
  components: {
    WGeetest
  },
  props: {
    resetUserName: {
      type: String,
      default: ''
    }
  },
  data () {
    const self = this
    return {
      rules: {
        username: [
          {
            required: true,
            message: '请输入邮箱/手机号',
            trigger: ['blur']
          },
          {
            async validator (rule, value, res) {
              if (!/^(1\d{10}|\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)$/.test(value)) {
                return res('请输入正确的邮箱/手机号')
              }
              // todo 验证账号存在
              const data = await self.getAccountStatus({ username: value })
              if (data === -1) {
                return res('账号不存在，请检查输入是否有误')
              }
              return res()
            },
            trigger: ['blur']
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: ['blur']
          },
          {
            pattern: /^\d{6}$/,
            message: '请输入正确的验证码',
            trigger: ['blur']
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: ['blur']
          },
          {
            // 旧密码验证 2023-3-26修改
            // pattern: /^[a-zA-Z0-9]{6,20}$/,1
            pattern: /^[A-Za-z0-9|`~!@#$%^*()_+?:{},.\\/;[\]]{6,20}$/,
            message: '6-20位，仅支持英文大小写、数字及符号组合',
            trigger: ['blur']
          }
        ],
        repassword: [
          {
            required: true,
            message: '请输入密码',
            trigger: ['blur']
          },
          {
            // 重新输入密码验证 2023-3-29修改
            // pattern: /^[a-zA-Z0-9]{6,20}$/,
            pattern: /^[A-Za-z0-9|`~!@#$%^*()_+?:{},.\\/;[\]]{6,20}$/,
            message: '6-20位，仅支持英文大小写、数字及符号组合',
            trigger: ['blur']
          },
          {
            async validator (rule, value, res) {
              const errorMsg = await new Promise(resolve => self.$refs['reset-form'].validateField('password', resolve))
              if (!errorMsg && self.form.password !== value) {
                res('两次填写密码不一致，请检查后重新填写')
                return
              }
              res()
            },
            trigger: ['blur']
          }
        ]
      },
      showPlatformList: false,
      platformList: []
    }
  },

  computed: {
    ...mapGetters([
      'form'
    ])
  },
  created () {
    console.log('reset传进来的form。。。', this.resetUserName);
    if (this.resetUserName) {
      this.form.username = this.resetUserName
    }
  },
  methods: {

    ...mapActions([
      'showLogin', // 显示登录
      'getAccountStatus', // 验证账号是否存在
      'getVerifyCode', // 获取验证码
      'resetSubmit', // 提交修改
      'showSucccess', // 显示成功弹窗
      'resetForm', // 重置表单
      'loginSubmit' // 提交登录
    ]),
    ...mapRootActions([
      // 初始化权限
      'initRights'
    ]),

    /**
     * 去登录
     */
    goToLogin () {
      this.showLogin()
      this.resetForm()
    },
    async getList () {
      console.log('????');
      console.log('this.username', this.form.username);
      console.log('this.code', this.form.code);
      if (this.form.code === '') {
        return
      }
      const data = await this.$wGet('/open/auth/sub_system.do?username=' + this.form.username + '&code=' + this.form.code)
      console.log('🚀 ~ file: reset.js:65 ~ validator ~ data:', data)
      if (data) {
        this.showPlatformList = true
        this.platformList = data
      }
    },
    /**
     * 检查手机号
     */
    async checkUsername (callback) {
      const errorMsg = await new Promise(resolve => this.$refs['reset-form'].validateField('username', resolve))
      console.log(errorMsg);
      callback(errorMsg)
    },
    /**
     * 获取验证码
     */
    async getCode ({ geetest_challenge: challenge }) {
      console.log('🚀 ~ file: reset.js:167 ~ getCode ~ challenge:', challenge)
      if (challenge) {
        const { username } = this.form
        const data = await this.getVerifyCode({
          username,
          challenge
        })
        if (data !== true) {
          this.$wToast.error('获取验证码失败')
          return
        } else {
          this.showPlatformList = true
        }
        // 开始计数
        this.$refs.geetest.startCount()
      }
    },

    /**
     * 表单校验
     */
    validate () {
      const verifyForm = this.$refs['reset-form']
      return new Promise(resolve => verifyForm.validate(resolve))
    },
    // 去除密码框中的空格
    trimStr (obj, val) {
      this[obj][val] = this[obj][val].replace(/\s+/g, '');
    },
    /**
     * 确认提交
     */
    async resetConfirmTap () {
      const valid = await this.validate()
      console.log(valid)
      if (!valid) {
        // 关闭正在提交loading
        this.$refs['confirm-btn'].updatedLoading(false)
        return
      }
      console.log('reset submit')
      const {
        username,
        password,
        code
      } = this.form
      const data = await this.resetSubmit({
        username,
        password,
        code
      })
      console.log('🚀 ~ file: reset.js:210 ~ resetConfirmTap ~ data:', data)
      this.$refs['confirm-btn'].updatedLoading(false)
      if (data === false) {
        // this.$wToast.error('重置失败')
        return
      } else {
        // 重置成功后，跳转到登录页面
        const data = await this.loginSubmit({ username, password })
        if (data === true) {
          return
        } else {
          this.$wToast.success('登录成功')
          this.resetForm()
          this.$emit('close-dialog')
        }
      }
      // 重置表单
      this.resetForm()
      // 显示成功弹窗
      this.showSucccess()
    }
  }
}
