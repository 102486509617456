/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-04 15:13:36
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-04 15:19:24
 */
import WSlider from './WSlider.vue'
export default WSlider
