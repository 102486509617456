/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-22 18:18:33
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-27 18:33:50
 */
import Vue from 'vue'
import * as types from '@/store/mutation-types'
import * as requestApis from '@/store/request-apis'
import stateTool from '@/store/state-tool'

const state = {
  name: 'AppOverview',
  overviewData: {
    // 今日数据
    overview_today_vo: {
      // 曝光量
      exposure: 0,
      // 点击量
      today_click: 0,
      // 数据存储量
      today_storge: 0,
      // 转化量
      today_transform: 0,
      // 套餐购买金额
      today_sale_meal: 0,
      // 数据购买金额
      today_data_money: 0,
      // 数据购买量
      today_data_count: 0
    },
    // 总览数据
    overview_total_vo: {
      // 安卓用户量
      android_total_deviceid: 0,
      // 数据存储总容
      data_capacity: 0,
      // ios用户量
      ios_total_deviceid: 0,
      // 用户总量
      total_deviceid: 0,
      // 无效数据总量
      total_invalid: 0,
      // 数据存储量
      total_storge: 0,
      // 套餐购买
      meal_sale: 0,
      // 数据购买数据总量
      data_total_sale: 0,
      // 数据购买金额
      data_sale_money: 0
    }
  },
  // 趋势请求参数
  trendQueryForm: {
    _date: [],
    start_date: '',
    end_date: '',
    media_platform: 0
  },
  // 趋势数据
  trendData: []
}

const getters = {
  overviewData: state => state.overviewData,
  todayData: state => state.overviewData.overview_today_vo,
  totalData: state => state.overviewData.overview_total_vo,
  trendQueryForm: state => state.trendQueryForm,
  trendData: state => state.trendData
}

const mutations = {

  /**
   * 更新总体数据
   */
  [types.APP_OVERVIEW_OVERVIEW_DATA_UPDATE] (state, payload) {
    stateTool.updateAllKeys(state.overviewData, payload)
  },

  /**
   * 更新趋势数据
   */
  [types.APP_OVERVIEW_TREND_DATA_UPDATE] (state, payload) {
    stateTool.update(state, 'trendData', payload)
  },

  /**
   * 更新趋势请求参数
   */
  [types.APP_OVERVIEW_TREND_QUERYFORM_UPDATE] (state, payload) {
    stateTool.updateAllKeys(state.trendQueryForm, payload)
  }
}

const actions = {

  /**
   * 查询总体数据
   */
  async getOverviewData ({ commit, getters }, payload = {}) {
    const query = Object.assign({}, payload, { id: getters.objectId })
    const data = await Vue.wPost(requestApis.APP_OVERVIEW_GET_OVERVIEW_DATA, query)
    console.log(data)
    if (data !== false) {
      data.overview_total_vo.total_deviceid = data.overview_total_vo.android_total_deviceid + data.overview_total_vo.ios_total_deviceid
      commit(types.APP_OVERVIEW_OVERVIEW_DATA_UPDATE, data)
    }
    return data
  },

  /**
   * 更新趋势请求参数
   */
  updateTrendQueryForm ({ commit }, payload) {
    commit(types.APP_OVERVIEW_TREND_QUERYFORM_UPDATE, payload)
  },

  /**
   * 查询趋势信息
   */
  async getTradeData ({ commit, getters }, payload = {}) {
    const queryForm = getters.trendQueryForm
    const updateObj = stateTool.getUpdateObj(payload, queryForm)

    if (Object.keys(updateObj).length) {
      await commit(types.APP_OVERVIEW_TREND_QUERYFORM_UPDATE, updateObj)
    }

    const query = Object.assign({}, queryForm, payload)
    delete query._date

    const data = await Vue.wPost(requestApis.APP_OVERVIEW_GET_TREND_DATA, query)
    console.log(data)
    if (Array.isArray(data)) {
      commit(types.APP_OVERVIEW_TREND_DATA_UPDATE, data)
    }
    return data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
