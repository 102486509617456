/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-03 15:58:35
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-26 09:41:45
 */
import Vue from 'vue'
import * as types from './mutation-types'
import * as requestApis from './request-apis'
import Dyroute from '@/plugins/dynamicRoute'
import Router from '../router'
import StorageTool from '@/service/sessionStorage'

// mock
// import manageMenu from '@/mocks/manageMenu'
// import customerMenu from '@/mocks/customerMenu'
// import agentMenu from '@/mocks/agentMenu'
import { wGetUrlQuery } from '@/plugins/utils'

export default {
  async updatedRights ({ commit }, payload) {
    commit(types.UPDATED_RIGHTS, payload)
  },

  async updatedSuccessDialog ({ commit }, payload) {
    commit(types.UPDATED_SUCCESS_DIALOG, payload)
  },

  async updatedNoticeInfo ({ commit }, payload) {
    commit(types.UPDATED_NOTICE_INFO, payload)
  },

  async updatedCanBuyCombo ({ commit }, payload) {
    commit(types.UPDATED_CAN_BUY_COMBO, payload)
  },

  async updatedUserInfo ({ commit }, payload) {
    commit(types.UPDATED_USER_INFO, payload)
  },

  async updatedAuths ({ commit }, payload) {
    commit(types.UPDATED_AUTH, payload)
  },

  async updatedMenus ({ commit }, payload) {
    commit(types.UPDATED_MENUS, payload)
  },

  async updatedLogin ({ commit }, payload) {
    commit(types.UPDATED_LOGIN, payload)
  },

  async updatedEnumMap ({ commit }, payload) {
    commit(types.UPDATED_ENUM_MAP, payload)
  },

  async updateIsMobile ({ commit }, payload) {
    commit(types.UPDATED_IS_MOBILE, payload)
  },

  async updateDownloadModal ({ commit }, payload) {
    commit(types.UPDATED_DOWNLOAD_MODAL, payload)
  },

  /**
  * 监听屏幕变动，记录屏幕宽度
  * @param {Object} payload
  */
  async watchScreenWidth ({ commit }) {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    commit(types.SCREEN_WIDTH, { screenWidth })
    commit(types.SCREEN_HEIGHT, { screenHeight })
    window.addEventListener('resize', () => {
      const screenWidth1 = window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      const screenHeight2 = window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      commit(types.SCREEN_WIDTH, { screenWidth: screenWidth1 })
      commit(types.SCREEN_HEIGHT, { screenHeight: screenHeight2 })
    }, true)
  },

  /**
   * 获取极验后台配置
   */
  async getGeeTestConfig (context, payload) {
    const data = await Vue.wPost(requestApis.GET_GEETEST_CONFIG, {})
    return data
  },

  /**
   * 验证极验验证码
   */
  async verifyGeeTestCode (context, payload) {
    const data = await Vue.wPost(requestApis.VERIFY_GEETEST_CODE, payload)
    return data
  },

  /**
   * 权限初始化
   */
  async initRights ({ dispatch }, payload) {
    const newPayload = Object.assign({}, payload)
    await dispatch('updateBack', newPayload)
    const data = await Vue.wPost('/common/user/get_right.do')
    // console.log(data)
    if (data.right) {
      // mock模拟添加套餐管理权限
      // if (newPayload.current_back_id === 1) {
      //   data.right.push({ id: 'ad000010', auth: [], children: [] })
      // }

      const dyRoute = new Dyroute(data.right).computedRoutes()
      console.log(dyRoute)
      await dispatch('updatedLogin', true)
      await dispatch('updatedAuths', dyRoute.auth)
      await dispatch('updatedRights', dyRoute.routes)
      await dispatch('updatedMenus', dyRoute.menus)
      await dispatch('updatedEnumMap', data.enum_map)
      await dispatch('updatedNoticeInfo', data.notice_info)
      await dispatch('updatedCanBuyCombo', data.can_buy_combo)

      const { current_back_id: currentBackId } = payload
      // 客户前台
      if ([2, 3].includes(currentBackId)) {
        await dispatch('getAccountInfo')
        await dispatch('initPayOrderInfo')
      }
    }
  },

  async updateBack ({ dispatch }, cPayload) {
    if (Number(wGetUrlQuery('current_back_id')) === 2) {
      cPayload.current_back_id = Number(wGetUrlQuery('current_back_id'))
      cPayload.current_agent_id = Number(wGetUrlQuery('current_agent_id'))
      cPayload.current_custom_id = null
    } else if (Number(wGetUrlQuery('current_back_id')) === 3) {
      cPayload.current_back_id = Number(wGetUrlQuery('current_back_id'))
      cPayload.current_agent_id = null
      cPayload.current_custom_id = Number(wGetUrlQuery('current_custom_id'))
    }
    await dispatch('updatedUserInfo', cPayload)
  },

  /**
   * 获取用户信息
   */
  async getAccountInfo ({ dispatch, commit, getters }) {
    const currentBackId = (getters.userInfo || {}).current_back_id
    let data = []
    switch (currentBackId) {
      case 1:
        return
      case 2:
        data = await dispatch('getAgentAccountInfo')
        break
      case 3:
        data = await dispatch('getAppAccountInfo')
        break
    }
    if (data !== false) {
      commit(types.UPDATE_ACCOUNT_INFO, data)
    }
  },

  /**
   * 获取客户端的用户信息
   */
  async getAppAccountInfo ({ commit }) {
    const data = await Vue.wPost('/app/finance/get.do')
    return data
  },

  /**
   * 获取代理侧的用户信息
   */
  async getAgentAccountInfo ({ commit }) {
    const data = await Vue.wPost('/operate/finance/get.do')
    return data
  },

  async initPayOrderInfo ({ getters, dispatch }) {
    const orderInfo = getters.payOrderInfo
    const { order_id: orderId } = orderInfo
    if (!orderId) {
      const storageTool = new StorageTool('FINANCE_PAYORDER')
      const storageData = storageTool.getItem()
      if (storageData) {
        await dispatch('setPayOrderDetail', { ...storageData }, { root: true })
      }
    }
  },

  async setPayOrderDetail ({ commit }, payload) {
    await commit(types.UPDATE_PAYORDER_INFO, payload)
  },

  /**
   * 更新页面支付跳转状态
   */
  async updatePayStatus ({ dispatch }, payload) {
    dispatch('setPayOrderDetail', payload)
  },

  async jumpToPay ({ dispatch, getters }, payload) {
    const { origin, search } = location
    const currentBackId = (getters.userInfo || {}).current_back_id
    let from = location.href
    switch (currentBackId) {
      case 1:
        break
      case 2:
        from = origin + '/operate/finance' + search
        break
      case 3:
        from = origin + '/app/finance' + search
        break
    }
    await dispatch('setPayOrderDetail', { order_id: payload, from })
    Router.push('/pay')
  },

  /**
   * 显示套餐购买弹窗
   */
  async showMealBuyDialog ({ dispatch, getters }) {
    await dispatch('getAccountInfo')
    const {
      name,
      is_new: isNew,
      can_expand: canExpand = false
    } = getters.accountInfo

    if (name) {
      await dispatch('PayOrder/dialog/showMealBuyDialog', {
        name,
        is_new: isNew,
        can_expand: canExpand,
        combo_buy_type: isNew ? 3 : 1
      }, { root: true })
    } else {
      Vue.wToast.error('获取账号信息错误')
    }
  },

  /**
   * 显示支付弹窗
   */
  async showOrderFinish ({ dispatch }) {
    await dispatch('PayOrder/dialog/showOrderFinish', {}, { root: true })
  },

  /**
   * 检查订单支付状态
   */
  async checkOrderPayStatus ({ dispatch, getters }, payload) {
    const orderId = payload || getters.payOrderInfo.order_id
    if (!orderId) {
      return
    }
    const data = await dispatch('PayOrder/checkOrderPayStatus', orderId, { root: true })
    return data
  }
}
