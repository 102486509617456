/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 14:30:15
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 14:35:50
 */
import OrderChecking from './orderChecking.vue'
export default OrderChecking
