<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-05 10:41:44
 * @LastEditors: AllenXD
 * @LastEditTime: 2022-05-25 11:45:50
-->
<template>
  <el-button
  :class="['w-button', `w-button-${type}`, className]"
  :type="type"
  :subBtn="subBtn"
  :plain="plain"
  :round="round"
  :circle="circle"
  :disabled="disabled"
  :icon="icon"
  :autofocus="autofocus"
  :native-type="nativeType"
  :loading="loading"
  @click="wClick"
  >
    <span v-show="loading === true && loadingText !== ''">{{loadingText}}</span>
    <span v-show="loading === false || loadingText === ''">
      <slot></slot>
    </span>
  </el-button>
</template>

<script>
export default {
  name: 'WButton',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    className: {
      type: String,
      default: ''
    },
    plain: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: ''
    },
    subBtn: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    nativeType: {
      type: String,
      default: 'button'
    }
  },
  data () {
    return {
      loading: false,
      time: null
    }
  },
  methods: {
    async wClick (e) {
      const self = this
      if (this.subBtn === true) {
        this.loading = true
        this.time = setTimeout(() => {
          clearTimeout(this.time)
          self.$emit('click', e)
        }, 30)
      } else {
        self.$emit('click', e)
      }
    },
    updatedLoading (bol) {
      this.loading = bol
    }
  }
}
</script>

<style lang="scss">
@import './index.scss';
</style>
