import { render, staticRenderFns } from "./reset.vue?vue&type=template&id=831d4f7c&scoped=true&"
import script from "./reset.js?vue&type=script&lang=js&"
export * from "./reset.js?vue&type=script&lang=js&"
import style0 from "./reset.scss?vue&type=style&index=0&id=831d4f7c&lang=scss&scoped=true&"
import style1 from "./reset.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "831d4f7c",
  null
  
)

export default component.exports