/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-12-07 17:29:23
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-12-07 17:30:40
 */
class HandleData {
  constructor (payload) {
    this.payload = payload
  }

  booking () {
    const newPayload = {
      company_name: this.payload.company_name,
      contact_name: this.payload.contact_name,
      phone: this.payload.phone,
      remark: this.payload.remark
    }
    return newPayload
  }
}

export default HandleData
