/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-03 11:27:43
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-03 11:28:47
 */
import WLogin from './Login.vue'
export default WLogin
