/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 17:18:50
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-22 18:26:52
 */
import Overview from './Overview'

const state = {
  name: 'Admin'
}

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  modules: {
    Overview
  },
  state,
  getters,
  mutations,
  actions
}
