<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-04 19:18:49
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-17 15:35:39
-->
<template>
  <div class="w-box--header" :style="{ backgroundColor: background }">
    <div class="w-box--header-title">
      <slot name="title"></slot>
    </div>
    <div class="w-box--header-btn">
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WBoxHeader',
  props: {
    background: {
      type: String,
      default: 'transparent'
    }
  }
}
</script>

<style src="./index.scss" lang="scss" scoped>
</style>
