/*
 * @Author: RongHaoC
 * @Date: 2023-03-23 17:46:42
 * @Last Modified by: RongHaoC
 * @Last Modified time: 2023-03-28 15:55:58
 */

import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('Login/dialog/upgrade/upgrade')

export default {
  name: 'Upgrade',
  props: {

  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'title'
    ])
  },
  async created () {
    // 获取用户开通的平台
    // this.getOpenPlatform()
  },
  async mounted () {
  },
  methods: {
    goToReset () {
      console.log('前往重置密码');
      console.log('dialogType', this.dialogType);
      this.$emit('show-reset')
    }

  }
}
