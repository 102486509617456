import { render, staticRenderFns } from "./WRemind.vue?vue&type=template&id=0eff8dd1&scoped=true&"
import script from "./WRemind.js?vue&type=script&lang=js&"
export * from "./WRemind.js?vue&type=script&lang=js&"
import style0 from "./index.scss?vue&type=style&index=0&id=0eff8dd1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eff8dd1",
  null
  
)

export default component.exports