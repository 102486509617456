/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-12-28 14:14:25
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-12-28 14:33:48
 */
import { mapGetters } from 'vuex'
export default {
  name: 'WDownloadTips',
  computed: {
    ...mapGetters([
      'downloadModal'
    ])
  },
  data () {
    return {
      WdownloadTips: true
    }
  }
}
