/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-22 14:59:01
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-22 15:50:14
 */
import * as types from '@/store/mutation-types.js'
import stateTool from '@/store/state-tool.js'

const state = {
  title: '标题',
  descr: '描述'
}

const getters = {
  title: state => state.title,
  descr: state => state.descr
}

const mutations = {

  /**
   * 弹窗初始化
   */
  [types.LOGIN_SUCCESS_INITMODAL] (state, payload) {
    stateTool.updateAllKeys(state, payload)
  }
}

const actions = {

  /**
   * 显示弹窗
   */
  initModal ({ commit }, payload) {
    commit(types.LOGIN_SUCCESS_INITMODAL, payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
