/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 17:18:50
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-22 20:08:12
 */
import Datapackage from './Datapackage'
import Overview from './Overview'

const state = {
  name: 'Operate'
}

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  modules: {
    Datapackage,
    Overview
  },
  state,
  getters,
  mutations,
  actions
}
