/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-13 15:28:07
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-13 15:30:16
 */
import WSvg from './WSvg.vue'
WSvg.install = Vue => {
  Vue.component(WSvg.name, WSvg)
}
export default WSvg
