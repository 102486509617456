/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-13 17:30:03
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-22 15:06:22
 */
import login from './login'
import register from './register'
import reset from './reset'
import success from './success'

export default {
  login,
  register,
  reset,
  success
}
