/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-03 15:24:56
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 14:39:30
 */
import LocalRoute from '../../router/router'
class Route {
  constructor (right) {
    this.right = right
    this.auth = []
    this.route = []
    this.local = new LocalRoute().getRoute()
  }

  computedRoutes () {
    this.recursion(this.right)
    console.log(this.right)
    return Object.assign({
      auth: [],
      routes: [],
      menus: []
    }, {
      auth: this.auth,
      routes: this.route,
      menus: this.right
    })
  }

  recursion (list) {
    for (let i = 0; i < list.length; i++) {
      this.auth = this.auth.concat(list[i].auth)
      this.route.push(list[i].id)
      for (let j = 0; j < this.local.length; j++) {
        if (this.local[j].meta.auth === list[i].id && this.local[j].meta.isMenu) {
          list[i].path = this.local[j].path
          list[i].icon = this.local[j].icon
          list[i].acicon = this.local[j].acicon
          list[i].cn = this.local[j].cn
          break
        }
      }
      if (list[i].children && list[i].children.length > 0) {
        this.recursion(list[i].children)
      }
    }
    // list.forEach(element => {
    //   this.newRoute.push(element.id)
    //   this.auth = this.auth.concat(element.auth)

    //   const arr = new LocalRoute().getRoute()
    //   if (arr[element.id]) {
    //     element.path = arr[element.id][0].path
    //     element.icon = arr[element.id][0].icon
    //     element.acicon = arr[element.id][0].acicon
    //     element.cn = arr[element.id][0].cn
    //   }

    //   if (element.children && element.children.length > 0) {
    //     this.recursion(element.children)
    //   }
    // })
  }

  // concatRoutes (has, local) {
  //   let routes = []
  //   for (const i in local) {
  //     if (has.indexOf(i) > -1) {
  //       routes = routes.concat(local[i])
  //     }
  //   }
  //   // 移除没有权限的子路由
  //   for (let i = 0; i < routes.length; i++) {
  //     if (routes[i].auth && this.auth.indexOf(routes[i].auth) < 0) {
  //       routes.splice(i, 1)
  //       break
  //     }
  //   }

  //   routes = routes.map(v => { if (v.path) { return v.path } }).filter(v => v)
  //   routes = uniqBy(routes)
  //   return routes
  // }
}

export default Route
