/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-25 11:17:03
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-25 14:04:02
 */
import Vue from 'vue'
import * as types from '@/store/mutation-types'
import stateTool from '@/store/state-tool'

const state = {
  info: {
    // 客户id
    id: '',
    // 客户名称
    name: '',
    // 产品名称
    product_name: '',
    // 一级分类
    first_trade_id: '',
    // 二级分类
    second_trade_id: '',
    // 营业执照
    business_url: '',
    // 联系人
    contact_persion_name: '',
    // 手机号
    phone: '',
    // 邮箱
    email: '',
    // 存储量
    max_storage: '',
    // 状态
    status: ''
  },
  // 行业分类
  tradeList: []
}

const getters = {
  info: state => state.info,
  tradeList: state => state.tradeList,
  getTradeItems: state => (id1, id2) => {
    const firstTrade = state.tradeList.find(({ trade_id: id }) => id - id1 === 0)
    if (firstTrade && id2) {
      const secondTrade = firstTrade.children.find(({ trade_id: id }) => id - id2 === 0)
      if (secondTrade) {
        return [firstTrade, secondTrade]
      }
      return [firstTrade]
    }
    return []
  }
}

const mutations = {

  /**
   * 更新行业分类
   */
  [types.APP_ACCOUNT_DETAIL_UPDATE_TRADE_LIST] (state, payload) {
    state.tradeList = payload
  },

  /**
   * 更新详情
   */
  [types.APP_ACCOUNT_DETAIL_UPDATE_INFO] (state, payload) {
    stateTool.updateAllKeys(state.info, payload)
  }
}

const actions = {

  /**
   * 获取所属行业数据
   */
  async getTradeList ({ commit }) {
    const data = await Vue.wPost('/common/list_trade.do', {})
    // console.log(data)

    if (Array.isArray(data)) {
      commit(types.APP_ACCOUNT_DETAIL_UPDATE_TRADE_LIST, data)
    }

    return data
  },

  /**
   * 获取资质详情
   */
  async getAccountInfo ({ commit }) {
    const data = await Vue.wPost('/app/finance/get_custom.do')
    // console.log(data)
    if (data !== false) {
      commit(types.APP_ACCOUNT_DETAIL_UPDATE_INFO, data)
    }
    return data
  },

  /**
   * 更新资质信息
   */
  updateInfo ({ commit }, payload) {
    commit(types.APP_ACCOUNT_DETAIL_UPDATE_INFO, payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
