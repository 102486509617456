/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-12-28 14:14:22
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-12-28 14:15:58
 */
import WDownloadTips from './WDownloadTips.vue'
export default WDownloadTips
