/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-14 13:45:01
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-26 16:20:10
 */
import { createNamespacedHelpers } from 'vuex'
import WGeetest from '@/components/WGeetest'

const { mapActions, mapGetters } = createNamespacedHelpers('Login/dialog/login/register')

export default {
  name: 'login-register',
  components: {
    WGeetest
  },
  data () {
    const self = this
    return {
      rules: {
        name: [
          {
            required: true,
            message: '请输入用户名',
            trigger: ['blur']
          },
          {
            pattern: /^\D{2,10}$/,
            message: '2~10个字，支持中英文和特殊字符',
            trigger: ['blur']
          }
        ],
        username: [
          {
            required: true,
            message: '请输入邮箱/手机号',
            trigger: ['blur']
          },
          {
            async validator (rule, value, res) {
              if (!/^(1\d{10}|\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)$/.test(value)) {
                return res('请输入正确的邮箱/手机号')
              }
              // todo 验证账号存在
              const data = await self.getAccountStatus({ username: value })
              if (data === 1) {
                return res('此账号已存在')
              }
              if (data === 2) {
                return res('此账号已存在')
              }
              return res()
            },
            trigger: ['blur']
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: ['blur']
          },
          {
            pattern: /^[a-zA-Z0-9]{6,20}$/,
            message: '6~20位，支持大小写英文或数字组合',
            trigger: ['blur']
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: ['blur']
          },
          {
            pattern: /^\d{6}$/,
            message: '请输入正确的验证码',
            trigger: ['blur']
          }
        ],
        agree: [
          {
            validator (rule, value, res) {
              if (!value) {
                return res('请勾选同意用户注册协议')
              }
              return res()
            },
            trigger: ['blur']
          }
        ]
      },
      // 切换密码显示
      passwordType: 'password'
    }
  },
  computed: {
    ...mapGetters([
      'form'
    ])
  },
  methods: {

    ...mapActions([
      'showLogin', // 显示登录
      'getAccountStatus', // 验证账号是否存在
      'getVerifyCode', // 获取验证码
      'registerSubmit', // 提交注册
      'showSucccess', // 注册成功
      'resetForm' // 重置表单
    ]),

    /**
     * 切换密码显示、隐藏
     */
    switchPasswordType () {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },

    /**
     * 去登录
     */
    goToLogin () {
      this.showLogin()
    },

    /**
     * 验证手机号、邮箱
     */
    async checkUsername (callback) {
      const errorMsg = await new Promise(resolve => this.$refs['register-form'].validateField('username', resolve))
      callback(errorMsg)
    },

    /**
     * 获取验证码
     */
    async getCode ({ geetest_challenge: challenge }) {
      if (challenge) {
        const { username } = this.form
        const data = await this.getVerifyCode({
          username,
          challenge
        })
        if (data !== true) {
          this.$wToast.error('获取验证码失败')
          return
        }
        // 开始计数
        this.$refs.geetest.startCount()
      }
    },

    /**
     * 表单校验
     */
    validate () {
      const registerForm = this.$refs['register-form']
      return new Promise(resolve => registerForm.validate(resolve))
    },

    /**
     * 提交注册
     */
    async registerSubmitTap () {
      const valid = await this.validate()
      console.log(valid)
      if (!valid) {
        // 关闭正在提交loading
        this.$refs['register-btn'].updatedLoading(false)
        return
      }
      const {
        name,
        username,
        code,
        password
      } = this.form
      const data = await this.registerSubmit({
        name,
        username,
        code,
        password
      })
      // 关闭正在提交loading
      this.$refs['register-btn'].updatedLoading(false)
      // 注册失败
      if (data === false) {
        return
      }
      // 重置表单
      this.resetForm()
      // 显示成功弹窗
      this.showSucccess()
    }
  }
}
