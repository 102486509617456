/* eslint-disable quote-props */
/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-02 17:02:44
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 16:47:42
 */
import { cloneDeep } from 'lodash'
import adminRouter from './admin'
import appRouter from './app'
import operateRouter from './operate'
// const routeMap = Object.assign(adminRouter, appRouter, operateRouter)
const routeMap = cloneDeep(adminRouter.concat(operateRouter).concat(appRouter))

class LocalRoute {
  constructor () {
    this.routeMap = routeMap
  }

  getRoute () {
    return this.routeMap
  }

  getRouteList () {
    // let routes = []
    // for (const prop in routeMap) {
    //   routes = routes.concat(routeMap[prop])
    // }
    // return routes
  }
}

export default LocalRoute
