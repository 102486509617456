/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 09:08:29
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-19 09:10:50
 */
import WSelectList from './WSelectList.vue'
WSelectList.install = Vue => {
  Vue.component(WSelectList.name, WSelectList)
}
export default WSelectList
