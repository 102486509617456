import { render, staticRenderFns } from "./upgrade.vue?vue&type=template&id=32e4d40a&scoped=true&"
import script from "./upgrade.js?vue&type=script&lang=js&"
export * from "./upgrade.js?vue&type=script&lang=js&"
import style0 from "./upgrade.scss?vue&type=style&index=0&id=32e4d40a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e4d40a",
  null
  
)

export default component.exports