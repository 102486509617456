<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-10 09:30:37
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-21 16:27:34
-->
<template>
  <div v-loading="loading" class="w-table-box">
    <slot name="form"></slot>
    <el-table
      stripe
      class="w-table"
      ref="table"
      :data="tableData"
      >
      <slot></slot>
    </el-table>
    <el-pagination
      class="w-table-pagination"
      :background="background"
      v-if="showPag"
      layout="prev, pager, next, jumper"
      :total="count"
      :current-page="page"
      @current-change="currentChange"
    ></el-pagination>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'WTable',
  props: {
    showPag: {
      type: Boolean,
      default: true
    },
    background: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    },
    payload: {
      type: Object,
      default: () => ({})
    },
    autoplay: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async getList () {
      this.loading = true
      console.log(document.documentElement.scrollTop)
      document.documentElement.scrollTop = 0
      const data = await this.$wPost(this.url, Object.assign({
        page: this.page
      }, this.payload))
      if (data === false) {
        this.loading = false
        return
      }
      this.count = Number(data.count) || 0
      const filtersData = cloneDeep(data.records)
      filtersData.forEach(element => {
        for (const i in element) {
          if (element[i] === null) {
            element[i] = '-'
          }
        }
      })
      this.tableData = cloneDeep(filtersData)
      this.loading = false
    },
    currentChange (val) {
      this.page = val
      this.getList()
    },
    async resetList () {
      this.page = 1
      await this.getList()
    },
    updateLoading (bol) {
      this.loading = bol
    }
  },
  data () {
    return {
      tableData: [],
      count: 0,
      loading: false,
      page: 1
    }
  },
  mounted () {
    if (this.autoplay) {
      this.$nextTick(() => {
        this.getList()
      })
    }
  }
}
</script>

<style src="./index.scss" lang="scss"></style>
