/* eslint-disable quote-props */
/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-13 09:57:52
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 16:41:32
 */
const _import = require('../_import_' + process.env.NODE_ENV)

// const operateRouter = {
//   'op000001': [{
//     path: '/operate/overview',
//     icon: 'ic_data_normal',
//     acicon: 'ic_data_hover',
//     cn: '数据看板',
//     name: 'Overview',
//     component: _import('model/Operate/Overview')
//   }],
//   'op000002': [{
//     path: '/operate/custom',
//     icon: 'ic_customer_normal',
//     acicon: 'ic_customer_hover',
//     cn: '客户管理',
//     name: 'OperateCustom',
//     component: _import('model/Operate/Custom')
//   }, {
//     path: '/operate/custom/detail',
//     name: 'OperateCustomDetail',
//     component: _import('model/Operate/Custom/CustomDetail')
//   }, {
//     path: '/operate/custom/add',
//     name: 'OperateCustomAdd',
//     component: _import('model/Operate/Custom/CustomAdd')
//   }, {
//     path: '/operate/custom/edit',
//     name: 'OperateCustomEdit',
//     component: _import('model/Operate/Custom/CustomAdd')
//   }],
//   'op000003': [{
//     path: '/operate/datapackage',
//     icon: 'ic_crowd_normal1',
//     acicon: 'ic_crowd_hover',
//     cn: '人群管理',
//     name: 'Datapackage',
//     component: _import('model/Operate/Datapackage')
//   },
//   {
//     path: '/operate/datapackage/crowdattribute',
//     cn: '人群画像',
//     name: 'Crowdattribute',
//     component: _import('model/Operate/Datapackage/Crowdattribute')
//   },
//   {
//     path: '/operate/datapackage/datapackagedetail',
//     cn: '人群包详情',
//     name: 'DatapackageDetail',
//     component: _import('model/Operate/Datapackage/DatapackageDetail')
//   },
//   {
//     path: '/operate/datapackage/diy/add',
//     cn: '新建人群包',
//     name: 'Newpackage',
//     component: _import('model/Operate/Datapackage/AddDiypackage')
//   }],
//   'op000004': [{
//     path: '/operate/activity',
//     icon: 'ic_activity_normal',
//     acicon: 'ic_activity_hover',
//     cn: '活动管理',
//     name: 'About',
//     component: _import('model/Operate/Activity')
//   },
//   {
//     path: '/operate/activity/detail',
//     cn: '活动管理',
//     name: 'AdminActivityDetail',
//     component: _import('model/Operate/Activity/ActivityDetail')
//   }],
//   'op000005': [{
//     path: '/operate/finance',
//     icon: 'ic_account_normal',
//     acicon: 'ic_account_hover',
//     cn: '账户管理',
//     name: 'Finance',
//     component: _import('model/Operate/Finance')
//   }],
//   'op000006': [{
//     path: '/operate/user',
//     icon: 'ic_user_normal',
//     acicon: 'ic_user_hover',
//     cn: '用户管理',
//     name: 'About',
//     component: _import('model/Operate/User')
//   }]
// }

const operateRouter = [
  {
    path: '/operate/overview',
    icon: 'ic_data_normal',
    acicon: 'ic_data_hover',
    cn: '数据看板',
    name: 'Overview',
    component: _import('model/Operate/Overview'),
    meta: {
      auth: 'op000001',
      isMenu: true
    }
  }, {
    path: '/operate/custom',
    icon: 'ic_customer_normal',
    acicon: 'ic_customer_hover',
    cn: '客户管理',
    name: 'OperateCustom',
    component: _import('model/Operate/Custom'),
    meta: {
      auth: 'op000002',
      isMenu: true
    }
  }, {
    path: '/operate/custom/detail/:id',
    name: 'OperateCustomDetail',
    component: _import('model/Operate/Custom/CustomDetail'),
    meta: {
      auth: 'op000002'
    }
  }, {
    path: '/operate/custom/add',
    name: 'OperateCustomAdd',
    component: _import('model/Operate/Custom/CustomAdd'),
    meta: {
      auth: 'op000002'
    }
  }, {
    path: '/operate/custom/edit/:id',
    name: 'OperateCustomEdit',
    component: _import('model/Operate/Custom/CustomAdd'),
    meta: {
      auth: 'op000002'
    }
  }, {
    path: '/operate/datapackage',
    icon: 'ic_crowd_normal1',
    acicon: 'ic_crowd_hover',
    cn: '人群管理',
    name: 'Datapackage',
    component: _import('model/Operate/Datapackage'),
    meta: {
      auth: 'op000003',
      isMenu: true
    }
  }, {
    path: '/operate/datapackage/crowdattribute',
    cn: '人群画像',
    name: 'Crowdattribute',
    component: _import('model/Operate/Datapackage/Crowdattribute'),
    meta: {
      auth: 'op000003'
    }
  }, {
    path: '/operate/datapackage/datapackagedetail/:id',
    cn: '人群包详情',
    name: 'DatapackageDetail',
    component: _import('model/Operate/Datapackage/DatapackageDetail'),
    meta: {
      auth: 'op000003'
    }
  }, {
    path: '/operate/datapackage/diy/add',
    cn: '新建人群包',
    name: 'Newpackage',
    component: _import('model/Operate/Datapackage/AddDiypackage'),
    meta: {
      auth: 'op000003'
    }
  }, {
    path: '/operate/activity',
    icon: 'ic_activity_normal',
    acicon: 'ic_activity_hover',
    cn: '活动管理',
    name: 'About',
    component: _import('model/Operate/Activity'),
    meta: {
      auth: 'op000004',
      isMenu: true
    }
  }, {
    path: '/operate/activity/detail/:id',
    cn: '活动管理',
    name: 'AdminActivityDetail',
    component: _import('model/Operate/Activity/ActivityDetail'),
    meta: {
      auth: 'op000004'
    }
  }, {
    path: '/operate/finance',
    icon: 'ic_account_normal',
    acicon: 'ic_account_hover',
    cn: '账户管理',
    name: 'Finance',
    component: _import('model/Operate/Finance'),
    meta: {
      auth: 'op000005',
      isMenu: true
    }
  }, {
    path: '/operate/user',
    icon: 'ic_user_normal',
    acicon: 'ic_user_hover',
    cn: '用户管理',
    name: 'About',
    component: _import('model/Operate/User'),
    meta: {
      auth: 'op000006',
      isMenu: true
    }
  }
]

export default operateRouter
