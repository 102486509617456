/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-20 16:28:41
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-20 16:29:56
 */
import WTableSort from './WTableSort.vue'
WTableSort.install = Vue => {
  Vue.component(WTableSort.name, WTableSort)
}
export default WTableSort
