<!--
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-04 09:34:14
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-25 19:25:55
-->
<template>
  <div class="w-header">
    <w-logo></w-logo>
    <w-navbar></w-navbar>
  </div>
</template>

<script>
import WLogo from './WLogo'
import WNavbar from './WNavbar'
export default {
  name: 'WHeader',
  components: {
    WLogo,
    WNavbar
  }
}
</script>

<style lang="scss" scope>
@import './index.scss';
</style>
