/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 09:08:24
 * @LastEditors: AllenXD
 * @LastEditTime: 2022-05-30 09:32:25
 */
import { clone, remove, debounce } from 'lodash'
export default {
  name: 'WSelectList',
  props: {
    customClass: {
      type: String,
      default: ''
    },
    resultClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择绑定用户'
    },
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    remoteMethod: {
      type: Function
    }
  },
  data () {
    return {
      value: ''
    }
  },
  watch: {
    selected (val) {
      const self = this
      self.$emit('change', val)
    }
  },
  methods: {
    remoteMethodFn: debounce(function (keyword) {
      this.remoteMethod(keyword)
    }, 500),
    /**
     * 下拉框状态改变时触发事件
     */
    handleChange (val) {
      if (val) {
        if (this.remoteMethod) {
          this.remoteMethod('')
        }
      }
    },
    /**
     * 选项按钮操作
     * @param {Object} item 选项选中取消
     */
    itemToggle (item) {
      const self = this
      let result = []
      if (self.isChecked(item)) {
        result = self.itemUnCheck(item)
      } else {
        result = self.itemCheck(item)
      }

      self.$emit('toggle', result)
    },

    /**
       * 勾选选项
       */
    itemCheck (item) {
      const self = this
      const selected = clone(self.selected)
      selected.push(item)
      return selected
    },

    /**
       * 取消勾选选项
       */
    itemUnCheck (item) {
      const self = this
      const selected = clone(self.selected)
      remove(selected, _item => {
        return _item.id === item.id
      })
      return selected
    },

    /**
       * 当前选项是否已经被勾选
       * @returns {Boolean} 被勾选:true/没被勾选:false
       */
    isChecked (item) {
      const self = this
      let isChecked = false
      for (const _item of self.selected) {
        if (_item.id === item.id) {
          isChecked = true
        }
      }
      return isChecked
    },

    clearAll () {
      this.$emit('toggle', [])
    }

  }
}
