/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-09 15:29:55
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-09 15:31:54
 */
import WCounto from './WCounto.vue'
WCounto.install = Vue => {
  Vue.component(WCounto.name, WCounto)
}
export default WCounto
