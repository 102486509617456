/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-16 17:02:13
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-16 17:10:49
 */
import WMobileTips from './WMobileTips.vue'
export default WMobileTips
