/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-25 01:39:22
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-30 14:28:42
 */
// import Vue from 'vue'
import * as types from '@/store/mutation-types'
import stateTool from '@/store/state-tool'
import AccountConfig from './AccountConfig'
import AccountDetail from './AccountDetail'

const state = {
  name: 'Finance',
  // 账户情况
  // 记录列表请求参数
  queryForm: {
    // 交易类型
    // deal_type: 0,
    // 交易状态
    pay_status: 0,
    // 开始日期
    start_date: '',
    // 结束日期
    end_date: '',
    // 时间段
    _date: []
  }
}

const getters = {
  accountInfo: (state, getters, rootState, rootGetters) => rootState.accountInfo,
  queryForm: state => state.queryForm
}

const mutations = {

  /**
   * 更新请求参数
   */
  [types.APP_FINANCE_UPDATE_QUERY_FORM] (state, payload) {
    stateTool.updateAllKeys(state.queryForm, payload)
  }

}

const actions = {

  /**
   * 获取当前情况
   */
  async getFinanceInfo ({ dispatch }) {
    return await dispatch('getAccountInfo', {}, { root: true })
  },

  /**
   * 更新请求参数
   */
  updateQueryForm ({ commit }, payload) {
    commit(types.APP_FINANCE_UPDATE_QUERY_FORM, payload)
  },

  /**
   * 显示购买套餐弹窗
   */
  async showMealBuyDialog ({ dispatch }, payload) {
    await dispatch('showMealBuyDialog', payload, { root: true })
  }

}

export default {
  namespaced: true,
  modules: {
    AccountConfig,
    AccountDetail
  },
  state,
  getters,
  mutations,
  actions
}
