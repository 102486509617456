/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-13 17:19:43
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 19:02:47
 */
import Vue from 'vue'
import * as requestApis from '@/store/request-apis'
import login from './login'

const state = {
  name: 'login'
}

const getters = {

}

const mutations = {

}

const actions = {

  /**
   * 验证账号是存在
   */
  async getAccountStatus (context, payload) {
    const data = await Vue.wPost(requestApis.GET_ACCOUNT_STATUS, payload)
    return data
  },

  /**
   * 获取REA公钥
   */
  async getRsaPublishKey () {
    const data = await Vue.wPost(requestApis.GET_RSA_PUBLISH_KEY)
    return data
  }
}

export default {
  namespaced: true,
  modules: {
    login
  },
  state,
  getters,
  mutations,
  actions
}
