/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-23 20:40:35
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-23 20:49:41
 */
import { mapGetters } from 'vuex'
import { wGetUrlQuery } from '@/plugins/utils'
export default {
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    isShow () {
      if (wGetUrlQuery('current_back_id')) {
        console.log(Number(wGetUrlQuery('current_back_id')))
        return Number(wGetUrlQuery('current_back_id'))
      } else {
        return false
      }
    }
  }
}
