import DOMPurify from 'dompurify'

const plugin = {}

plugin.install = Vue => {
  // 自定义指令 v-xss
  Vue.directive('xss', {
    bind (el, binding) {
      el.innerHTML = DOMPurify.sanitize(binding.value)
    },
    update (el, binding) {
      el.innerHTML = DOMPurify.sanitize(binding.value)
    }
  })
}

export default plugin
