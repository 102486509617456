/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-13 10:53:39
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-22 16:24:18
 */
import Login from './components/login'
import Register from './components/register'
import Reset from './components/reset'
import Success from './components/success'
import Upgrade from './components/upgrade'

export default {
  name: 'dialogLogin',
  components: {
    Login,
    Register,
    Reset,
    Success,
    Upgrade
  },
  data () {
    return {
      visible: false,
      resetUserName: ''
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'login'
    },
    emailOrPhone: {
      type: String,
      default: ''
    }
  },
  watch: {
    showDialog (newVal) {
      this.visible = newVal
    }
  },
  created () {
    console.log(this.dialogType);
    this.username = ''
  },
  mounted () {
    this.visible = this.showDialog
  },
  methods: {

    /**
     * 切换
     */
    goToRegister () {
      this.$emit('update:dialogType', 'register')
    },

    /**
     * 关闭弹窗
     */
    closeDialog () {
      this.visible = false
    },

    /**
     * 去重置密码
     */
    goToReset () {
      console.log('dialogType', this.dialogType);
      this.$emit('show-reset')
    },

    goToUpgrade () {
      console.log(this.dialogType);
      this.$emit('show-upgrade')
    },
    //
    getUser (v) {
      console.log(v);
      this.resetUserName = v
    }
  }
}
