/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 14:35:50
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 15:13:50
 */
import { Message } from 'element-ui'

/**
 * * usage：
 * 1. 引用插件 import api from 'path/to/api'
 * 2. 注册插件 Vue.use(api)
 * 3. 在组件中使用 this.$wToast,
 *    信息 this.$wToast.info('')
 *    成功 this.$wToast.success('')
 *    警告 this.$wToast.warning('')
 *    错误 this.$wToast.error('')
 * 4. 在非组件区域使用 Vue.wToast
 *    信息 Vue.wToast.info('')
 *    成功 Vue.wToast.success('')
 *    警告 Vue.wToast.warning('')
 *    错误 Vue.wToast.error('')
 * @param {String} msg 需要提示的内容
 */
class WToast {
  constructor () {
    this.shotDuration = 2000
    this.longDuration = 3000
  }

  info (msg) {
    Message.info({
      message: msg,
      offset: 90,
      duration: this.shotDuration
    })
  }

  success (msg) {
    Message.success({
      message: msg,
      offset: 90,
      duration: this.shotDuration
    })
  }

  warning (msg) {
    Message.warning({
      message: msg,
      offset: 90,
      duration: this.longDuration
    })
  }

  error (msg) {
    Message.error({
      message: msg,
      offset: 90,
      duration: this.longDuration
    })
  }
}

const plugin = {}

plugin.install = Vue => {
  Vue.prototype.$wToast = new WToast()

  Vue.wToast = Vue.prototype.$wToast
}

export default plugin
