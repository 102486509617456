/* eslint-disable camelcase */
/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-20 16:28:32
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-22 15:27:08
 */
import { cloneDeep } from 'lodash'
export default {
  name: 'WTableSort',
  props: {
    align: {
      type: String,
      default: 'left'
    },
    fieldType: {
      type: String,
      default: ''
    },
    status: { // 当前排序状态
      type: Object,
      default: () => { return {} }
    },
    sortabled: { // 是否排序
      type: Boolean
    },
    sortClick: { // 排序点击事件
      type: Function
    },
    tooltips: { // tooltips 文案
      type: String
    },
    tipIcon: { // tooltips 图标
      type: String
    },
    sortValue: {
      type: Object,
      default () {
        return {
          up: '1',
          down: '2'
        }
      }
    }
  },
  methods: {
    /**
       * 点击事件
       */
    handleClick () {
      const form = this.handleData(cloneDeep(this.status))
      this.$emit('table-sort', form)
    },
    handleData (form) {
      let sort_type
      const fieldType = cloneDeep(this.fieldType)
      if (fieldType !== form.field_type) {
        form.sort_type = '0'
      }
      switch (form.sort_type) {
        case '1':
          sort_type = '0'
          break;
        case '2':
          sort_type = '1'
          break;
        default:
          sort_type = '2'
          break;
      }
      return {
        sort_type,
        fieldType
      }
    }
  }
}
