/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 14:19:59
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 14:21:32
 */
import Dialog from './dialog.vue'
export default Dialog
