<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-09 14:23:57
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-17 15:38:04
-->
<template>
  <div class="w-box--content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WBoxContent'
}
</script>

<style src="./index.scss" lang="scss" scoped></style>
