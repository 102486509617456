<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-10-30 10:31:00
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-13 18:54:39
-->
<template>
  <div class="home">
    <w-header></w-header>
    <w-slider></w-slider>
    <div class="w-wapper">
      <template v-for="(item, index) in notice_info">
        <w-remind :key="index" :status="item"></w-remind>
      </template>
      <router-view class="w-content"></router-view>
    </div>
    <w-download-tips></w-download-tips>
    <w-mobile-tips></w-mobile-tips>
    <!-- 新用户认证提醒 -->
    <w-new-custom-tip></w-new-custom-tip>
    <!-- 支付弹窗 -->
    <pay-dialog></pay-dialog>
    <el-backtop target=".home" :bottom="20" :right="20"></el-backtop>
  </div>
</template>

<script>
// @ is an alias to /src
import WHeader from '@/components/WHeader'
import WSlider from '@/components/WSlider'
import WDownloadTips from '@/components/WDownloadTips'
import WMobileTips from '@/components/WMobileTips'
import WNewCustomTip from '@/components/WNewCustomTip'
import WRemind from '@/components/WRemind'
import PayDialog from './model/PayOrder/dialog'
import { mapGetters, mapActions } from 'vuex'
import StorageTool from '@/service/sessionStorage'
import eventBus from '@/service/eventBus'

export default {
  name: 'Home',
  components: {
    WHeader,
    WSlider,
    WDownloadTips,
    WMobileTips,
    WNewCustomTip,
    WRemind,
    PayDialog
  },
  computed: {
    ...mapGetters([
      'notice_info',
      'payOrderInfo'
    ])
  },
  async mounted () {
    // 检查支付状态
    const { toAliPay } = this.payOrderInfo
    if (toAliPay) {
      const status = await this.checkOrderPayStatus()
      await new StorageTool('FINANCE_PAYORDER').removeItem()
      // 支付完成或者取消
      if ([2, 3].includes(status)) {
        if (status === 2) {
          this.$wToast.success('支付成功，已更新')
        } else {
          this.$wToast.success('支付取消，已更新')
        }
        eventBus.$emit('app-finance-refresh')
      } else {
        // 显示支付状态弹窗
        await this.updatePayStatus({ toAliPay: false })
        // await this.showOrderFinish()
        this.$wToast.error('支付失败，请重试')
      }
    }
  },
  methods: {

    ...mapActions([
      'updatePayStatus',
      'showOrderFinish',
      'checkOrderPayStatus'
    ])
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  overflow-y: auto;
  background: #FAFCFF;
  overflow-x: hidden;
}
</style>
