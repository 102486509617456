/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-26 03:24:27
 * @LastEditors: Arvin
 * @LastEditTime: 2021-01-14 15:42:09
 */
import Vue from 'vue'
import dialog from './dialog'
import StorageTool from '@/service/sessionStorage'

const state = {
  name: 'payOrder'
}

const getters = {
  payOrder: (state, getters, rootState, rootGetters) => rootState.payOrderInfo || {},
  orderId: (state, getters) => getters.payOrder.order_id || ''
}

const mutations = {

}

const actions = {

  /**
   * 获取订单详情
   */
  async getOrderDetail ({ getters, dispatch }, payload) {
    let orderId = payload || getters.orderId
    if (!orderId) {
      const storageTool = new StorageTool('FINANCE_PAYORDER')
      const storageData = storageTool.getItem()
      if (storageData) {
        const { order_id: orderId1 } = storageData
        orderId = orderId1
        await dispatch('setPayOrderDetail', { order_id: orderId }, { root: true })
      }
    }
    const data = await dispatch('PayOrder/dialog/getOrderDetail', orderId, { root: true })
    console.log(data)
    await dispatch('setPayOrderDetail', { detail: data }, { root: true })
  },

  /**
   * 立即支付
   */
  async payCash ({ getters, dispatch, rootGetters }, payload) {
    let { order_id: orderId } = payload
    orderId = orderId || getters.orderId
    if (!orderId) {
      const storageTool = new StorageTool('FINANCE_PAYORDER')
      const storageData = storageTool.getItem()
      if (storageData) {
        const { order_id: orderId1 } = storageData
        orderId = orderId1
        await dispatch('setPayOrderDetail', { order_id: orderId }, { root: true })
      }
    }
    const currentBackId = (rootGetters.userInfo || {}).current_back_id
    let data = null
    switch (currentBackId) {
      case 1:
        return
      case 2:
        data = await dispatch('agentPayCash', payload)
        break
      case 3:
        data = await dispatch('appPayCash', payload)
        break
    }
    return data
  },

  /**
   * 客户端立即支付
   */
  async appPayCash ({ getters }, payload) {
    let { return_url: returnUrl, order_id: orderId } = payload
    orderId = orderId || getters.orderId
    const data = await Vue.wPost('/app/finance/pay.do', { order_id: orderId, return_url: returnUrl || location.href })
    return data
  },

  /**
   * 代理侧立即支付
   */
  async agentPayCash ({ getters }, payload) {
    let { return_url: returnUrl, order_id: orderId } = payload
    orderId = orderId || getters.orderId
    const data = await Vue.wPost('/operate/finance/pay.do', { order_id: orderId, return_url: returnUrl || location.href })
    return data
  },

  /**
   * 检查订单支付状态
   * 1-支付中、2-支付成功、3-支付取消
   */
  async checkOrderPayStatus ({ getters, dispatch, rootGetters }, payload) {
    const orderId = payload || getters.orderId
    if (!orderId) {
      return
    }
    const currentBackId = (rootGetters.userInfo || {}).current_back_id
    let data = null
    switch (currentBackId) {
      case 1:
        return
      case 2:
        data = await dispatch('checkAgentOrderPayStatus', orderId)
        break
      case 3:
        data = await dispatch('checkAppOrderPayStatus', orderId)
        break
    }
    if ([1, 2, 3].includes(data)) {
      await dispatch('setPayOrderDetail', { status: data }, { root: true })
    }
    return data
  },

  /**
   * 检查客户端订单支付状态
   */
  async checkAppOrderPayStatus (context, payload) {
    const data = await Vue.wPost('/app/finance/get_pay_status.do', { order_id: payload })
    return data
  },

  /**
   * 检查代理侧订单支付状态
   */
  async checkAgentOrderPayStatus (context, payload) {
    const data = await Vue.wPost('/operate/finance/get_pay_status.do', { order_id: payload })
    return data
  },

  /**
   * 显示支付成功弹窗
   */
  async showOrderFinish ({ dispatch }) {
    await dispatch('PayOrder/dialog/showOrderFinish', {}, { root: true })
  },

  /**
   * 更新页面支付跳转状态
   */
  async updatePayStatus ({ dispatch }, payload) {
    dispatch('setPayOrderDetail', payload, { root: true })
  }
}

export default {
  namespaced: true,
  modules: {
    dialog
  },
  state,
  getters,
  mutations,
  actions
}
