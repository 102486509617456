/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-14 00:02:56
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-26 03:34:33
 */
import Login from './Login'
import PayOrder from './PayOrder'
import App from './App'
import Operate from './Operate'
import Admin from './Admin'

export default {
  Login,
  PayOrder,
  App,
  Operate,
  Admin
}
