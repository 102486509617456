/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-13 17:21:26
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-14 00:00:27
 */
import modules from './components'

const state = {
  name: 'login'
}

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  modules,
  state,
  getters,
  mutations,
  actions
}
