/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-03 15:58:53
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-13 19:11:10
 */
export default {
  rights: state => state.rights,
  auth: state => state.auth,
  menus: state => state.menus,
  isLogin: state => state.isLogin,
  isMobile: state => state.isMobile,
  screenWidth: state => state.screenWidth,
  screenHeight: state => state.screenHeight,
  enum_map: state => state.enum_map,
  notice_info: state => state.notice_info,
  can_buy_combo: state => state.can_buy_combo,
  userInfo: state => state.userInfo,
  accountInfo: state => state.accountInfo,
  payOrderInfo: state => state.payOrderInfo,
  successDialog: state => state.successDialog,
  downloadModal: state => state.downloadModal
}
