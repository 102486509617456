<!--
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 18:57:17
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-26 00:00:06
-->
<template>
  <el-tooltip
    :class="className"
    :placement="placement">
    <div slot="content" v-xss="content"></div>
    <w-svg data_iconName="ic_tips_normal" isIconFont></w-svg></el-tooltip>
</template>

<script>
export default {
  name: 'WTooltip',
  props: {
    content: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'top'
    },
    className: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
