/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-16 17:02:13
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-21 19:54:34
 */
import WNewCustomTip from './WNewCustomTip.vue'
export default WNewCustomTip
