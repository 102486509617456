/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-10-30 11:24:46
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-23 22:07:01
 */
import api from './api'
import toast from './toast'
import vhas from './vhas'
import dpr from './dpr'
import download from './export'
import Jsencrypt from './Jsencrypt'
import directive from './directive'

const plugins = {}
plugins.install = Vue => {
  Vue.use(api)
  Vue.use(toast)
  Vue.use(vhas)
  Vue.use(dpr)
  Vue.use(download)
  Vue.use(Jsencrypt)
  Vue.use(directive)
}

export default plugins
