/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-24 23:25:39
 * @LastEditors: AllenXD
 * @LastEditTime: 2022-05-25 16:06:48
 */
import Vue from 'vue'
import * as types from '@/store/mutation-types'
import stateTool from '@/store/state-tool'

import { wGetImgList } from '@/plugins/utils'

const state = {
  name: 'AccountConfig',
  form: {
    // 客户名称
    name: '',
    // 产品名称
    product_name: '',
    // 所属一级行业
    first_trade_id: '',
    // 所属二级行业
    second_trade_id: '',
    // 营业执照
    business_url: [],
    // 联系人
    contact_person_name: '',
    // 手机号码
    phone: '',
    // 电子邮箱
    email: '',
    // 用户状态
    status: 0,
    // 目标人群描述
    audience_desc: ''
  },
  // 行业分类
  tradeList: []
}

const getters = {
  form: state => state.form,
  tradeList: state => state.tradeList,
  firstTradeList: state => state.tradeList.map(({ trade_id: id, trade_name: name }) => ({ id, name })),
  secondTradeList: state => id => ((state.tradeList.find(({ trade_id: id1 }) => id - id1 === 0) || {}).children || []).map(({ trade_id: id, trade_name: name }) => ({ id, name }))
}

const mutations = {

  /**
   * 更新行业分类数据
   */
  [types.ACCOUNT_CONFIG_UPDATE_TRADE_LIST] (state, payload) {
    state.tradeList = payload
  },

  /**
   * 更新表单信息
   */
  [types.ACCOUNT_CONFIG_UPDATE_FORM] (state, payload) {
    stateTool.updateAllKeys(state.form, payload)
  },

  /**
   * 重置表单
   */
  [types.ACCOUNT_CONFIG_RESET_FORM] (state, payload) {
    state.form = {
      // 客户名称
      name: '',
      // 产品名称
      product_name: '',
      // 所属一级行业
      first_trade_id: '',
      // 所属二级行业
      second_trade_id: '',
      // 营业执照
      business_url: [],
      // 联系人
      contact_person_name: '',
      // 手机号码
      phone: '',
      // 电子邮箱
      email: '',
      status: 0,
      audience_desc: ''
    }
  }

}

const actions = {

  /**
   * 获取所属行业数据
   */
  async getTradeList ({ commit }) {
    const data = await Vue.wPost('/common/list_trade.do', {})
    console.log(data)

    if (Array.isArray(data)) {
      commit(types.ACCOUNT_CONFIG_UPDATE_TRADE_LIST, data)
    }

    return data
  },

  /**
   * 重置表单
   */
  resetForm ({ commit }) {
    commit(types.ACCOUNT_CONFIG_RESET_FORM)
  },

  /**
   * 获取资质详情
   */
  async getAccountInfo ({ commit }) {
    const data = await Vue.wPost('/app/finance/get_custom.do')
    if (data !== false) {
      if (data.status) {
        data.business_url = data.business_url ? wGetImgList(data.business_url, data.business_url_name) : []
        commit(types.ACCOUNT_CONFIG_UPDATE_FORM, data)
      }
    }
    return data
  },

  /**
   * 提交认证
   */
  async submitAccountConfig ({ getters }, payload) {
    const status = getters.form.status
    const authFinish = status > 0
    const requestUrl = authFinish ? '/app/finance/update_custom.do' : '/app/finance/add_custom.do'
    const data = await Vue.wPost(requestUrl, payload)
    console.log(data)

    return data
  },

  /**
   * 更新认证
   */
  async updateAccountConfig ({ dispatch }) {
    const hasLogin = await Vue.wPost('/open/auth/has_login.do')
    if (hasLogin === false) {
      await dispatch('updatedLogin', false)
    } else {
      await dispatch('initRights', hasLogin, { root: true })
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
