/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-25 19:30:39
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-14 11:02:42
 */
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'WRedmine',
  data () {
    return {
      WRedmineShow: false
    }
  },
  computed: {
    ...mapGetters([
      'can_buy_combo'
    ]),
    text () {
      switch (this.status) {
        case 1:
          return '您的账户未认证，为避免影响正常使用，请及时进行提交认证'
        case 2:
          return '您的账户未认证通过，请修改资质后重新提交'
        case 3:
          return '您的账户数据存储额度即将用完，为避免影响数据存储，请删除部分数据或前往账户管理进行升级'
        case 4:
          if (this.can_buy_combo) {
            return '您的账户数据存储量已达到额度上限，已停止新数据存储，如需提升额度，请前往账户管理进行升级'
          } else {
            return '您的账户数据存储量已达到额度上限，已停止新数据存储，如需升级容量，请联系平台运营人员进行升级'
          }
        case 5:
          return '您的账户套餐即将到期，为避免影响正常使用，请及时前往账户管理进行续费'
        case 6:
          return '您的账户套餐已到期，已停止新数据存储，请及时前往账户管理进行续费'
        default:
          return '';
      }
    },
    btn () {
      switch (this.status) {
        case 1:
          return '去认证'
        case 2:
          return '去修改资质'
        case 3:
          return '去升级'
        case 4:
          if (this.can_buy_combo) {
            return '去升级'
          } else {
            return ''
          }
        case 5:
        case 6:
          return '去续费'
        default:
          return '';
      }
    }
  },
  props: {
    status: {
      type: Number
    }
  },
  methods: {
    ...mapActions([
      'showMealBuyDialog'
    ]),
    async handle () {
      switch (this.status) {
        case 1:
        case 2:
          this.$router.push('/app/finance/accountconfig')
          break
        case 3:
        case 4:
          await this.showMealBuyDialog()
          break
        case 5:
        case 6:
          await this.showMealBuyDialog()
          break
        default:
          return false;
      }
    },
    hide () {
      this.WRedmineShow = false
    }
  },
  beforeMount () {
    if (this.status) {
      this.WRedmineShow = true
    }
  }
}
