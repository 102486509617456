<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 15:32:20
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-11 10:53:14
-->
<template>
  <div class="w-input-valid"
    :class="[
      type === 'textarea' ? 'w-form--textarea' : 'w-form--text',
      currentClassName ? currentClassName : ''
    ]">
    <el-input
      ref="input"
      :size="size"
      :class="['w-input-valid__input', appendText ? 'appendText' : '']"
      :type="type"
      :resize="resize"
      :disabled="disabled"
      :readonly="readonly"
      :rows="rows"
      :autosize="autosize"
      :auto-complete="autoComplete"
      :validate-event="validateEvent"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :minlength="minlength"
      :clearable="clearable"
      v-model="query"
      @input="changeValue"
      @blur="blur"
    >
      <template v-if="appendText" slot="append">{{appendText}}</template>
    </el-input>
    <div class="w-input-valid__box" v-if="!isSplitType">
      <span :class="{'w-input-valid__red': currentLength > maxTextLength | currentLength < minTextLength}">{{ currentLength }}</span>/<span>{{ currentMax }}</span>
    </div>
    <!-- <div class="w-input-valid__box" v-else>
      <span :class="{'w-input-valid__red': currentNumber > splitMaxNumber | currentNumber < splitMinNumber}">{{ currentNumber }}</span>/<span>{{ currentMaxNumber }}</span>
    </div> -->
  </div>
</template>
<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'WInputValid',
  data () {
    return {
      query: ''
    }
  },
  watch: {
    value (newVal, oldVal) {
      const self = this
      self.query = self.value
    }
  },
  props: {
    appendText: String,
    value: [String, Number],
    className: String,
    size: String,
    resize: String,
    rows: Number,
    disabled: Boolean,
    readonly: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    autosize: {
      type: [Boolean, Object],
      default: false
    },
    autoComplete: {
      type: String,
      default: 'off'
    },
    validateEvent: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String
    },
    maxlength: {
      type: Number
    },
    minlength: {
      type: Number
    },
    clearable: {
      type: Boolean
    },
    minTextLength: {
      type: Number,
      default: 0
    },
    maxTextLength: {
      type: Number,
      default: 0
    },
    validateFunction: Function,
    blurFunction: {
      type: Function,
      default: function () {}
    },
    isSplitType: { // 是否分割统计类型
      type: Boolean,
      default: false
    },
    splitStr: { // 分割符
      type: String,
      default: ''
    },
    singleMaxLength: { // 分割单个最大长度
      type: Number,
      default: 0
    },
    splitMaxNumber: { // 分割后的最大数量
      type: Number,
      default: 0
    },
    splitMinNumber: { // 分割后的最小数量
      type: Number,
      default: 0
    }

  },
  computed: {
    currentMin () {
      return this.minTextLength || 0
    },
    currentMax () {
      return this.maxTextLength || 0
    },
    currentMaxNumber () {
      return this.splitMaxNumber || 0
    },
    currentLength () {
      const self = this
      if (typeof self.validateFunction === 'function') {
        return parseInt(self.validateFunction(self.query) + 0.5, 10)
      } else {
        return self.query.length
      }
    },
    currentClassName () {
      return this.className || ''
    },
    currentNumber () {
      const self = this
      let len
      if (self.splitStr && self.query.length) {
        len = self.query.split(self.splitStr).length
      } else {
        len = 0
      }

      return len
    }
  },
  methods: {
    changeValue (val) {
      const self = this
      if (typeof val === 'string') {
        val = val.trim()
      }
      self.$emit('input', val)
    },
    blur () {
      if (this.query && typeof this.query === 'string') {
        this.query = this.query.trim()
      }
      this.blurFunction()
    }
  },
  mounted () {
    const self = this
    if (self.value !== undefined && self.value !== null) {
      self.query = cloneDeep(self.value)
    } else {
      self.query = ''
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../../styles/core/var.scss";
  .w-input-valid {
    position: relative;
    display: inline-block;

    &__input {
      width: 100%;
    }
    &__box {
      position: absolute;
      right: 0;
      bottom: -16px;
      font-size: 12px;
      color: $font-color-normal-light;
      line-height: 13px;
      // width: 100%;
    }
    &__red {
      color: $--color-danger;
    }
  }
</style>

<style lang="scss">
.appendText {
  .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .el-input-group__append {
    width: 101px;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}
</style>
