/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-28 23:53:10
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-30 14:35:36
 */
import CryptoJS from 'crypto-js'

class SessionStorage {
  constructor (key) {
    this.key = key
    this.codekey = `AB_DATABRIGHT_BOSS_${key.toUpperCase()}`
  }

  encode (value) {
    return CryptoJS.AES.encrypt(value, this.codekey)
  }

  decode (encodeValue) {
    const decodeValue = CryptoJS.AES.decrypt(encodeValue, this.codekey).toString(CryptoJS.enc.Utf8)
    if (/^[[{]/.test(decodeValue)) {
      return JSON.parse(decodeValue)
    }
    return decodeValue
  }

  setItem (value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }
    sessionStorage.setItem(this.key, this.encode(value))
  }

  getItem () {
    const encodeValue = sessionStorage.getItem(this.key)
    if (encodeValue) {
      return this.decode(encodeValue)
    }
    return ''
  }

  removeItem () {
    sessionStorage.removeItem(this.key)
  }
}

export default SessionStorage
