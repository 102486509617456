<template>
  <div class="el-transfer-panel__body w-transfer-list__tree">
    <el-input
      class="el-transfer-panel__filter w-form--text"
      v-model="filterText"
      size="small"
      :placeholder="filterPlaceholder"
      :suffix-icon="inputIcon"
      @mouseenter.native="inputHover = true"
      @mouseleave.native="inputHover = false"
      @click="clearQuery"
      v-if="filterable"
    ></el-input>
    <div>
      <div class="w-transfer-title">
        <div
          v-if="$slots['title-left']"
          class="w-transfer-title__left">
          <slot name="title-left"></slot>
        </div>
        <div
          v-if="$slots['title-right']"
          class="w-transfer-title__right">
          <slot name="title-right"></slot>
        </div>
      </div>
      <el-scrollbar wrap-class="w-transfer__wrap" view-class="w-transfer__treebox">
        <el-tree
          :data="data"
          show-checkbox
          node-key="id"
          :props="defaultProps"
          :accordion="true"
          ref="tree1"
          @check-change="onCheckedChange"
          :filter-node-method="filterNode"
          class="w-transfer__tree"
        >
          <div class="tree-node-item" slot-scope="{ node, data }">
            <span
            v-if="!node.isLeaf"
            class="expand-container"></span>
            <span class="custom-tree-node">
              <slot name="item" :node="node" :data="data"></slot>
            </span>
          </div>
        </el-tree>
      </el-scrollbar>
    </div>
    <p
      class="el-transfer-panel__footer">
      <el-row style="height: 100%;line-height: 40px;">
        <el-col
          :span="12">
          <el-checkbox
            v-model="allChecked"
            @change="handleAllCheckedChange">全选</el-checkbox>
        </el-col>
        <el-col
          :span="12"
          style="text-align: right;">{{ checkText }}</el-col>
      </el-row>
    </p>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  name: 'treeBox',
  data () {
    return {
      inputHover: false,
      filterText: '',
      defaultProps: {
        // 树形加载框配置项 子元素默认键名为‘childs’
        children: 'childs',
        label: 'label'
      },
      allChecked: false,
      checked: [],
      timer: ''
    }
  },
  watch: {
    value (val) {
      const self = this
      self.checked = val
      if (self.timer !== '') {
        clearTimeout(self.timer)
      }
      setTimeout(() => {
        self.$refs.tree1.setCheckedKeys(val)
      }, 25)
      // console.log('val:' + val.length)
      this.allChecked = val.length === this.dataLength
    },

    checked (val) {
      const self = this
      self.$emit('input', val)
    },

    // 输入框数据
    filterText (val) {
      const self = this
      self.$refs.tree1.filter(val)
    },

    data () {
      const self = this
      self.checked = []
      self.allChecked = false
    }
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    filterable: Boolean, // 是否开启搜索过滤
    filterMethod: Function, // 自定义的搜索过滤方法
    filterPlaceholder: String, // 过滤文案

    overflow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    //  绑定的图片icon
    inputIcon () {
      const self = this
      return self.filterText.length > 0 && self.inputHover
        ? 'el-icon-close'
        : 'el-icon-search'
    },

    // 数据池的数据长度
    dataLength () {
      const self = this
      let total = 0
      self.data.map(item => {
        if (!self.overflow) {
          total++
        }
        item.childs.map(item2 => {
          total++
          if (item2.childs && item2.childs.length > 0) {
            item2.childs.map(item3 => {
              total++
            })
          }
        })
      })
      return total
    },

    //  选择框文案
    checkText () {
      const self = this
      return `共 ${self.dataLength} 项`
    }
  },
  methods: {
    // 选择框选择事件 同时计数
    onCheckedChange (val, isChecked, isChildsChecked) {
      const self = this
      const arr = cloneDeep(self.checked)
      if (isChecked === true) {
        if (arr.indexOf(val.key) === -1) {
          arr.push(val.key)
        }
      } else {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === val.key) {
            arr.splice(i, 1)
            break
          }
        }
      }
      self.checked = arr
      self.$emit('check-change', val, isChecked, isChildsChecked)
    },

    // 过滤节点
    filterNode (value, data) {
      const self = this
      if (typeof self.filterMethod === 'function') {
        return self.filterMethod(self.value, data)
      } else {
        if (!value) {
          return true
        }
        if (
          data.label.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !==
          -1
        ) {
          return true
        } else {
          return false
        }
      }
    },

    // 清空左边输入框值方法
    clearQuery () {
      const self = this
      if (self.inputIcon === 'el-icon-close') {
        self.filterText = ''
      }
    },

    // 执行全选方法
    handleAllCheckedChange (value) {
      const self = this
      if (value === true) {
        const arr = []
        self.data.map(item => {
          arr.push(item.key)
        })
        self.$refs.tree1.setCheckedKeys(arr)
      } else {
        self.$refs.tree1.setCheckedKeys([])
      }
    }
  }
}
</script>
