/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 10:39:14
 * @LastEditors: Arvin
 * @LastEditTime: 2021-01-14 14:25:17
 */

import axios from 'axios'
import Vue from 'vue'
import wTips from '../message'
import store from '../../store'

// 拦截器
axios.interceptors.request.use((config) => {
  if (config.method === 'get') {
    config.params
      ? (config.params._ = Date.parse(new Date()) / 1000)
      : (config.params = { _: Date.parse(new Date().toString()) / 1000 });
  } else if (config.method === 'post') {
    let basePayload = {}
    if (store.state.userInfo && JSON.stringify(store.state.userInfo) !== '{}') {
      basePayload = Object.assign({}, {
        current_back_id: store.state.userInfo.current_back_id,
        current_agent_id: store.state.userInfo.current_agent_id,
        current_custom_id: store.state.userInfo.current_custom_id
      })
    }
    config.data = Object.assign({}, config.data, basePayload)
  }

  return config
}, (err) => {
  return Promise.reject(err)
})

// 过滤器
axios.interceptors.response.use((response) => {
  const { config: { url }, data } = response
  if (
    [
      '/app/finance/pay.do',
      '/operate/finance/pay.do'
    ].includes(url)
  ) {
    // 支付调用
    if (data.startsWith('<form') && data.endsWith('</script>')) {
      return data
    }
    return false
  }
  if (response.request.responseType === 'arraybuffer') {
    const uint8msg = new Uint8Array(response.data)
    try {
      const decodedString = String.fromCharCode.apply(null, uint8msg)
      const data = JSON.parse(decodeURIComponent(escape(decodedString)))
      const httpCode = new Httpcode(data, true)
      return httpCode.check()
    } catch (error) {
      return response
    }
  }
  const httpCode = new Httpcode(response.data)
  return httpCode.check()
}, (err) => {
  const httpCode = new Httpcode(err.response)
  return httpCode.check()
})

class Httpcode {
  constructor (res) {
    this.response = Object.assign({
      code: '',
      data: '',
      message: ''
    }, res)
  }

  async check () {
    if (this.response.code >= 200 && this.response.code <= 200) {
      if (this.response.type === 'file') {
        return this.response
      } else {
        return this.response.data
      }
    }

    switch (this.response.code || this.response.status) {
      case 100:
        Vue.wToast.error(this.response.message)
        await store.dispatch('updatedLogin', true)
        setTimeout(() => {
          location.href = '/website/index'
        }, 1500)
        break
      case 101:
        Vue.wToast.error(wTips.noLimit)
        break
      case 102:
        location.reload()
        break
      case 300:
        location.href = this.response.data
        break
      case 301:
        break
      case 404:
        // Vue.wToast.error(wTips.apiNotFound)
        break
      case 400:
        Vue.wToast.error(this.response.message)
        break
      case 415:
        Vue.wToast.error(this.response.message)
        setTimeout(() => {
          location.href = '/404'
        }, 1500)
        break
      case 500:
        Vue.wToast.error(this.response.message || wTips.serverError)
        break
      case 501:
        Vue.wToast.error(this.response.message || wTips.serverStop)
        break
      case 502:
        Vue.wToast.error(this.response.message || wTips.serverStop)
        break
      case 504:
        Vue.wToast.error('当前网络连接异常，请检查您的网络设置')
        break
      default:
        Vue.wToast.error('当前网络连接异常，请检查您的网络设置')
        break
    }
    return false
  }
}

export default axios
