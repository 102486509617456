/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 10:31:00
 * @LastEditors: heyan
 * @LastEditTime: 2023-12-26 18:25:00
 */
// import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 重置浏览器样式
import './styles/reset/index.scss'
import ElementUI from 'element-ui'
import './styles/elementui/index.scss'
import './styles/common/index.scss'
import MetaInfo from 'vue-meta-info'
// import Dyroute from './plugins/dynamicRoute'
import plugins from './plugins'
import components from './components/common'
import { getUrlParam } from './utils/urlParams'

// mock
// import manageMenu from './mocks/manageMenu'
// import customerMenu from './mocks/customerMenu'
// import agentMenu from './mocks/agentMenu'

const req = require.context('./assets/svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

Vue.config.productionTip = false
console.log(components)
Vue.use(ElementUI, { size: 'mini' })
Vue.use(MetaInfo)
Vue.use(plugins)
Vue.use(components)

async function isLogin () {
  await store.dispatch('updateBack', {})
  const hasLogin = await Vue.wPost('/open/auth/has_login.do')
  if (hasLogin === false) {
    await store.dispatch('updatedLogin', false)
    router.replace({ path: '/website/index' })
  } else {
    await store.dispatch('initRights', hasLogin)
  }
}

const initInterception = async () => {
  const query = getUrlParam(window.location)
  if (query && query.isFrom === 'oa') {
    if (query.token) {
      const payload = {
        token: query.token
      }
      if (query.username) {
        payload.username = decodeURIComponent(query.username)
      }
      await Vue.wPost('/open/auth/login.do', payload)
    }
  }
}

async function init () {
  initInterception().finally(async () => {
    await isLogin()
    new Vue({
      router,
      store,
      render: h => h(App),
      mounted () {
        document.dispatchEvent(new Event('render-event'));
      }
    }).$mount('#app')
  })
}

init()
