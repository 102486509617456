/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 15:11:19
 * @LastEditors: Arvin
 * @LastEditTime: 2020-12-02 17:07:40
 */
import Vue from 'vue'
import * as types from '@/store/mutation-types'
import stateTool from '@/store/state-tool'

const state = {
  name: 'Crowdattribute',
  // 当前展示图表：1 列表：2
  activeTab: 1,
  // 当前绑定的时间
  queryForm: {
    // 时间段
    _date: [],
    // 开始时间
    start_date: '',
    // 结束时间
    end_date: '',
    // 活动id或者人群包id
    source_id: '',
    // 维度
    dimension: '',
    // 数据类型 1 活动 2 自定义人群
    analysis_type: ''
  },
  pageTitle: '',
  // 画像数据
  crowdattributeList: {}
}

const getters = {
  activeTab: state => state.activeTab,
  queryForm: state => state.queryForm,
  pageTitle: state => state.pageTitle,
  crowdattributeList: state => state.crowdattributeList,
  getCrowdattribute: state => dimension => state.crowdattributeList[dimension]
}

const mutations = {

  /**
   * 更新tab展示
   */
  [types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_TAB] (state, payload) {
    state.activeTab = payload
  },

  /**
   * 更新表单数据
   */
  [types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_QUERYFORM] (state, payload) {
    stateTool.updateAllKeys(state.queryForm, payload)
  },

  /**
   * 更新图表数据
   */
  [types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_CROWDATTRIBUTE_LIST] (state, payload) {
    stateTool.updateAllKeys(state.crowdattributeList, payload)
  },

  /**
   * 更新标题
   */
  [types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_PAGETITLE] (state, payload) {
    state.pageTitle = payload
  }
}

const actions = {

  /**
   * 更新tab展示
   */
  updateActiveTab ({ commit }, payload) {
    commit(types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_TAB, payload)
  },

  /**
   * 更新表单数据
   */
  updateQueryForm ({ commit }, payload) {
    commit(types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_QUERYFORM, payload)
  },

  /**
   * 更新标题
   */
  updatePageTitle ({ commit }, payload) {
    sessionStorage.setItem('CROWDATTRIBUTE_PAGE_TITLE', payload)
    commit(types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_PAGETITLE, payload)
  },

  /**
   * 从缓存中获取标题
   */
  getTitleFromSession ({ commit, getters }, payload) {
    if (getters.pageTitle) {
      return
    }
    const pageTitle = sessionStorage.getItem('CROWDATTRIBUTE_PAGE_TITLE')
    if (pageTitle) {
      commit(types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_PAGETITLE, pageTitle)
    }
  },

  /**
   * 获取图表数据
   */
  async getCrowdattributeList ({ commit, getters }, payload = {}) {
    const queryForm = getters.queryForm
    const updateObj = stateTool.getUpdateObj(payload, queryForm)

    if (Object.keys(updateObj).length) {
      await commit(types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_QUERYFORM, updateObj)
    }

    const query = Object.assign({}, queryForm, payload)
    delete query._date
    const data = await Vue.wPost('/app/crowdattribute/list.do', query)
    // console.log(data)

    if (data !== false) {
      commit(types.APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_CROWDATTRIBUTE_LIST, data)
    }

    return data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
