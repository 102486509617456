/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 14:26:02
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 14:28:06
 */
import MealBuy from './mealBuy.vue'
export default MealBuy
