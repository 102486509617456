/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-13 17:21:59
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-25 01:56:55
 */
import CryptoJS from 'crypto-js'
import JsEncrypt from 'jsencrypt'
import Vue from 'vue'
import * as requestApis from '@/store/request-apis'
import * as types from '@/store/mutation-types'
import stateTool from '@/store/state-tool'

const state = {
  name: 'login-login',
  form: {
    // 用户名
    username: '',
    // 密码
    password: '',
    // 记住账号
    remember: false
  }
}

const getters = {
  form: state => state.form,
  rules: state => state.rules
}

const mutations = {

  /**
   * 更新表单
   */
  [types.LOGIN_LOGIN_UPDATE_FORM] (state, payload) {
    stateTool.updateAllKeys(state.form, payload)
  },

  /**
   * 重置表单
   */
  [types.LOGIN_LOGIN_RESET_FORM] (state) {
    stateTool.updateAllKeys(state.form, {
      // 用户名
      username: '',
      // 密码
      password: '',
      // 记住账号
      remember: false
    })
  }
}

const actions = {

  /**
   * 更新表单
   */
  updateForm ({ commit }, payload) {
    commit(types.LOGIN_LOGIN_UPDATE_FORM, payload)
  },

  /**
   * 校验账号是否存在
   */
  getAccountStatus ({ dispatch }, payload) {
    return dispatch('Login/dialog/getAccountStatus', payload, { root: true })
  },

  /**
   * 记住账号密码
   */
  rememberAccountPassword (context, payload) {
    const { username, password } = payload
    if (!username || !password) {
      return
    }
    const encodeUsername = CryptoJS.AES.encrypt(username, 'AB_DATABRIGHT_BOSS_LOGIN')
    const encodePassword = CryptoJS.AES.encrypt(password, 'AB_DATABRIGHT_BOSS_LOGIN')
    localStorage.setItem('AB_DATABRIGHT_BOSS_LOGIN', encodeUsername + '@&@' + encodePassword)
    return true
  },

  /**
   * 获取账号和密码
   */
  getAccountPassword (context, payload) {
    const encodeContext = localStorage.getItem('AB_DATABRIGHT_BOSS_LOGIN')
    if (!encodeContext) {
      return {}
    }
    const [encodeUsername, encodePassword] = encodeContext.split('@&@')
    // console.log(encodeUsername, encodePassword)
    const username = CryptoJS.AES.decrypt(encodeUsername, 'AB_DATABRIGHT_BOSS_LOGIN').toString(CryptoJS.enc.Utf8)
    const password = CryptoJS.AES.decrypt(encodePassword, 'AB_DATABRIGHT_BOSS_LOGIN').toString(CryptoJS.enc.Utf8)
    // console.log(username, password)
    return {
      username,
      password
    }
  },

  /**
   * 移除已记住账号密码
   */
  removeUsernamePassword () {
    localStorage.removeItem('AB_DATABRIGHT_BOSS_LOGIN')
    return true
  },

  /**
   * 获取RSA公钥
   */
  async getRsaPublishKey ({ dispatch }) {
    return dispatch('Login/dialog/getRsaPublishKey', {}, { root: true })
  },

  /**
   * 登录提交
   */
  async loginSubmit ({ dispatch }, payload) {
    const { username, password } = payload
    const publishKey = await dispatch('getRsaPublishKey')
    if (!username || !password || !publishKey) {
      return false
    }
    const encodeKeys = ['password']
    const form = ['username', 'password'].reduce((res, key) => {
      if (encodeKeys.includes(key)) {
        const jsencrypt = new JsEncrypt()
        jsencrypt.setPublicKey(publishKey)
        res[key] = jsencrypt.encrypt(payload[key])
      } else {
        res[key] = payload[key]
      }
      return res
    }, {})
    const data = await Vue.wPost(requestApis.LOGIN_SUBMIT, form)
    console.log('🚀 ~ file: index.js:142 ~ loginSubmit ~ data:', data)
    return data
  },

  /**
   * 重置表单
   */
  resetForm ({ commit }) {
    commit(types.LOGIN_LOGIN_RESET_FORM)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
