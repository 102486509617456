/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-03 15:58:06
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-13 19:10:58
 */
export default {
  rights: [],
  auth: [],
  menus: [],
  isLogin: false,
  isMobile: false,
  screenWidth: 0,
  screenHeight: 0,
  notice_info: [],
  can_buy_combo: '',
  enum_map: {},
  userInfo: {},
  downloadModal: {
    show: false,
    pre: 0,
    title: ''
  },
  // 账户情况
  accountInfo: {
    avaliable_date: '',
    max_storge: 0,
    name: '',
    is_new: true,
    status: '',
    status_name: '认证不通过',
    used: 0
  },
  payOrderInfo: {
    order_id: '',
    status: '',
    from: '',
    detail: null
  }, // 存放支付订单信息
  successDialog: {
    title: '',
    descr: '',
    visible: false
  }
}
