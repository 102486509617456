/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 15:28:20
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 15:43:12
 */
const state = {
  name: 'Diypackage',
  queryForm: {
    // 搜索
    keyword: '',
    // 状态
    status: 0,
    // 数据来源
    source: 0
  }
}

const getters = {
  queryForm: state => state.queryForm
}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
