<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-13 15:28:01
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-13 16:36:14
-->
<template>
  <svg :class="svgClass" aria-hidden="true">
      <use :xlink:href="iconName" />
  </svg>
</template>

<script>
export default {
  name: 'WSvg',
  props: {
    data_iconName: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    isIconFont: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    svgClass () { // svg 的class
      if (this.className) {
        return `icon ${this.className}`;
      } else {
        return 'icon'
      }
    },
    iconName () { // svg xlink-href 指向的文件名
      if (this.isIconFont) {
        return `#${this.data_iconName}`;
      } else {
        return `#icon-${this.data_iconName}`;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
