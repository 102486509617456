/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 14:26:15
 * @LastEditors: Arvin
 * @LastEditTime: 2021-01-14 11:32:40
 */
import { createNamespacedHelpers, mapActions as mapRootGetters } from 'vuex'
import MealItem from './components/meal'
import { numberFormat } from '@/plugins/utils'

const { mapActions } = createNamespacedHelpers('PayOrder/dialog')

export default {
  name: 'MealBuy',
  components: {
    MealItem
  },
  data () {
    return {
      // 套餐列表
      combos: [],
      // 当前选中的套餐id
      selectedComboId: null
    }
  },
  props: {
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {

    // 当前选中的套餐
    selectedCombo () {
      if (this.selectedComboId === '') {
        return {}
      }
      return this.combos.find(({ id }) => id === this.selectedComboId) || {}
    }
  },
  async mounted () {
    // 获取套餐
    this.combos = await this.getListCombo()
    this.selectedComboId = null
    const firstCombo = this.combos.find(({ usable }) => usable)
    if (firstCombo) {
      this.selectCombo(firstCombo)
    }
  },
  methods: {

    ...mapActions([
      'getListCombo',
      'addOrder', // 添加订单
      'closeDialog', // 关闭弹窗
      'getUncashOrder', // 未完成订单
      'showOrderChecking'
    ]),

    ...mapRootGetters([
      'jumpToPay'
    ]),

    numberFormat,

    /**
     * 切换购买
     */
    async comboBuyTypeChange () {
      this.selectedComboId = ''
      this.combos = await this.getListCombo()
      const firstCombo = this.combos.find(({ usable }) => usable)
      if (firstCombo) {
        this.selectCombo(firstCombo)
      }
    },

    /**
     * 选择套餐
     */
    selectCombo ({ id, usable }) {
      if (!usable) {
        return
      }
      this.selectedComboId = id
      this.combos.forEach(item => {
        item.selected = item.id === id
      })
    },

    /**
     * 取消
     */
    cancel () {
      this.closeDialog()
    },

    /**
     * 提交订单
     */
    async submit () {
      if (!this.selectedComboId) {
        this.$wToast.error('请选择套餐')
        this.$refs.submitButton.updatedLoading(false)
        return
      }
      const uncashOrder = await this.getUncashOrder()
      if (uncashOrder !== false) {
        this.showOrderChecking()
        return
      }
      const comboId = this.selectedComboId
      const { combo_buy_type: comboBuyType } = this.formData
      const data = await this.addOrder({
        combo_buy_type: comboBuyType,
        combo_id: comboId
      })
      this.$refs.submitButton.updatedLoading(false)
      if (data !== false) {
        console.log(data)
        this.closeDialog()
        this.jumpToPay(data)
      }
    }
  }
}
