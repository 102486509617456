/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-13 14:48:06
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-13 18:17:08
 */
const dpr = window.devicePixelRatio

const plugin = {}

/**
 * true 1x
 * false 2x
 */
const computedDpr = () => {
  if (Number(dpr) < 2) {
    return true
  } else {
    return false
  }
}

plugin.install = Vue => {
  Vue.prototype.$wDpr = computedDpr
  Vue.wDpr = Vue.prototype.$wDpr
}

export default plugin
