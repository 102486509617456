/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 18:56:57
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-16 19:03:23
 */
import WTooltip from './WTooltip.vue'
WTooltip.install = Vue => {
  Vue.component(WTooltip.name, WTooltip)
}
export default WTooltip
