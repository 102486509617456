/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-25 17:58:14
 * @LastEditors: Arvin
 * @LastEditTime: 2021-01-14 16:45:50
 */
import Vue from 'vue'
import * as types from '@/store/mutation-types'
import stateTool from '@/store/state-tool'

const state = {
  name: 'PayDialog',
  base: {
    visible: false,
    dialogType: 'mealBuy'
  },
  formData: {
    // 当前账户
    name: '',
    // 是否试用
    is_new: false,
    // 购买套餐方式，1-续费，2-升级容量， 3-新购
    combo_buy_type: 1,
    // 是否可以扩容
    can_expand: false
  },
  uncashOrder: { // 未支付订单详情

  }
}

const getters = {
  base: state => state.base,
  formData: state => state.formData,
  uncashOrder: state => state.uncashOrder
}

const mutations = {

  /**
   * 显示弹窗
   */
  [types.APP_FINANCE_DIALOG_UPDATE_SHOW] (state, payload) {
    stateTool.updateAllKeys(state.base, payload)
  },

  /**
   * 更新表单数据
   */
  [types.APP_FINANCE_DIALOG_UPDATE_FORMDATA] (state, payload) {
    stateTool.updateAllKeys(state.formData, payload)
  },

  /**
   * 更新未支付订单详情
   */
  [types.APP_FINANCE_DIALOG_UPDATE_UNCASHORDER] (state, payload) {
    stateTool.updateAllKeys(state.uncashOrder, payload)
  }
}

const actions = {

  /**
   * 更新表单数据
   */
  async updateFormData ({ commit }, payload = {}) {
    await commit(types.APP_FINANCE_DIALOG_UPDATE_FORMDATA, payload)
  },

  /**
   * 显示购买套餐
   */
  async showMealBuyDialog ({ commit, dispatch }, payload = {}) {
    await dispatch('updateFormData', payload)
    await commit(types.APP_FINANCE_DIALOG_UPDATE_SHOW, {
      visible: true,
      dialogType: 'mealBuy'
    })
  },

  /**
   * 显示待支付订单弹窗
   */
  async showOrderChecking ({ commit, dispatch }, payload = {}) {
    await commit(types.APP_FINANCE_DIALOG_UPDATE_SHOW, {
      visible: true,
      dialogType: 'orderChecking'
    })
  },

  /**
   * 显示支付状态弹窗
   */
  async showOrderFinish ({ commit, dispatch }, payload = {}) {
    await commit(types.APP_FINANCE_DIALOG_UPDATE_SHOW, {
      visible: true,
      dialogType: 'orderFinish'
    })
  },

  /**
   * 获取套餐信息
   */
  async getListCombo ({ dispatch, rootGetters }) {
    const currentBackId = (rootGetters.userInfo || {}).current_back_id
    let data = []
    switch (currentBackId) {
      case 1:
        return
      case 2:
        data = await dispatch('getAgentListCombo')
        break
      case 3:
        data = await dispatch('getAppListCombo')
        break
    }
    if (Array.isArray(data)) {
      return data.map(item => {
        item.price = item.price / 100
        item.original_price = item.original_price / 100
        item.amount = item.amount / 100
        item.selected = false
        return item
      })
    }
    return []
  },

  /**
   * 获取客户购买套餐信息
   */
  async getAppListCombo ({ getters }) {
    const formData = getters.formData
    const data = await Vue.wPost('/app/finance/list_combo.do', {
      combo_buy_type: formData.combo_buy_type
    })
    return data
  },

  /**
   * 获取代理商购买套餐信息
   */
  async getAgentListCombo ({ getters }) {
    const formData = getters.formData
    const data = await Vue.wPost('/operate/finance/list_combo.do', {
      combo_buy_type: formData.combo_buy_type
    })
    return data
  },

  /**
   * 取消订单
   */
  async removeOrder ({ dispatch, rootGetters }, payload) {
    const currentBackId = (rootGetters.userInfo || {}).current_back_id
    let data = null
    switch (currentBackId) {
      case 1:
        return
      case 2:
        data = await dispatch('removeAgentOrder', payload)
        break
      case 3:
        data = await dispatch('removeAppOrder', payload)
        break
    }
    return data
  },

  /**
   * 客户端取消订单
   */
  async removeAppOrder (context, payload) {
    const data = await Vue.wPost('/app/finance/cancel_order.do', { order_id: payload })
    return data
  },

  /**
   * 代理侧取消订单
   */
  async removeAgentOrder (context, payload) {
    const data = await Vue.wPost('/operate/finance/cancel_order.do', { order_id: payload })
    return data
  },

  /**
   * 获取订单详情
   */
  async getOrderDetail ({ dispatch, commit, rootGetters }, payload) {
    const currentBackId = (rootGetters.userInfo || {}).current_back_id
    let data = null
    switch (currentBackId) {
      case 1:
        return
      case 2:
        data = await dispatch('getAgentOrderDetail', payload)
        break
      case 3:
        data = await dispatch('getAppOrderDetail', payload)
        break
    }
    if (data) {
      await commit(types.APP_FINANCE_DIALOG_UPDATE_UNCASHORDER, data)
    }
    return data
  },

  /**
   * 获取客户端订单详情
   */
  async getAppOrderDetail ({ commit }, payload) {
    const data = await Vue.wPost('/app/finance/get_order.do', { order_id: payload })
    return data
  },

  /**
   * 获取代理侧订单详情
   */
  async getAgentOrderDetail ({ commit }, payload) {
    const data = await Vue.wPost('/operate/finance/get_order.do', { order_id: payload })
    return data
  },

  /**
   * 查询是否有未支付订单
   */
  async getUncashOrder ({ dispatch, rootGetters }) {
    const currentBackId = (rootGetters.userInfo || {}).current_back_id
    let data = null
    switch (currentBackId) {
      case 1:
        return
      case 2:
        data = await dispatch('getAgentUncashOrder')
        break
      case 3:
        data = await dispatch('getAppUncashOrder')
        break
    }
    if (data) {
      const orderDetail = await dispatch('getOrderDetail', data)
      return orderDetail
    }
    return false
  },

  /**
   * 获取客户端未完成订单
   */
  async getAppUncashOrder ({ dispatch }) {
    const data = await Vue.wPost('/app/finance/get_wait_order.do')
    return data
  },

  /**
   * 获取代理侧未完成订单
   */
  async getAgentUncashOrder ({ dispatch }) {
    const data = await Vue.wPost('/operate/finance/get_wait_order.do')
    return data
  },

  /**
   * 提交订单
   */
  async addOrder ({ dispatch, rootGetters }, payload) {
    const currentBackId = (rootGetters.userInfo || {}).current_back_id
    let data = {}
    switch (currentBackId) {
      case 1:
        return
      case 2:
        data = await dispatch('addAgentOrder', payload)
        break
      case 3:
        data = await dispatch('addAppOrder', payload)
        break
    }
    return data
  },

  /**
   * 客户端提交订单
   */
  async addAppOrder (context, payload) {
    const data = await Vue.wPost('/app/finance/add_order.do', payload)
    return data
  },

  /**
   * 代理侧提交订单
   */
  async addAgentOrder (context, payload) {
    const data = await Vue.wPost('/operate/finance/add_order.do', payload)
    return data
  },

  /**
   * 检查订单状态
   */
  async checkOrderPayStatus ({ dispatch, getters }, payload) {
    return await dispatch('PayOrder/checkOrderPayStatus', payload, { root: true })
  },

  /**
   * 关闭弹窗
   */
  async closeDialog ({ commit }) {
    await commit(types.APP_FINANCE_DIALOG_UPDATE_SHOW, {
      visible: false,
      dialogType: ''
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
