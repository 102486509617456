/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-13 17:19:07
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-25 01:57:30
 */
import * as types from '@/store/mutation-types'
import dialog from './dialog'
import stateTool from '@/store/state-tool'

const state = {
  name: 'Login',
  // 登录弹窗
  dialogLogin: {
    // 显示弹窗
    showDialog: false,
    // 登录弹窗类型
    dialogType: 'login'
  }
}

const getters = {
  dialogLogin: state => state.dialogLogin,
  dialogReset: state => state.dialogReset
}

const mutations = {

  /**
   * 更新登录
   */
  [types.LOGIN_UPDATE_DIALOG_LOGIN] (state, payload) {
    stateTool.updateAllKeys(state.dialogLogin, payload)
  }
}

const actions = {

  /**
   * 更新登录弹窗
   */
  updateDialogLogin ({ commit }, payload) {
    commit(types.LOGIN_UPDATE_DIALOG_LOGIN, payload)
  }
}

export default {
  namespaced: true,
  modules: {
    dialog
  },
  state,
  getters,
  mutations,
  actions
}
