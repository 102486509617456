/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 14:30:44
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-22 10:31:00
 */
import eventBus from '@/service/eventBus'
import { createNamespacedHelpers, mapActions as mapRootActions, mapGetters } from 'vuex'

const { mapActions } = createNamespacedHelpers('PayOrder/dialog')

export default {
  name: 'OrderChecking',
  props: {
    orderDetail: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  mounted () {
    // console.log(this.$parent.$parent.$parent)
  },
  methods: {

    ...mapActions([
      'removeOrder',
      'closeDialog'
    ]),

    ...mapRootActions([
      'jumpToPay'
    ]),

    /**
     * 取消订单
     */
    async cancel () {
      const { id } = this.orderDetail
      if (!id) {
        this.$refs.cancelBtn.updatedLoading(false)
        return
      }
      const data = await this.removeOrder(id)
      this.$refs.cancelBtn.updatedLoading(false)
      if (data === true) {
        this.$wToast.success('订单已取消')
        // this.closeDialog()
        // if (this.$route.path !== '/app/finance') {
        //   this.$router.push('/app/finance')
        // } else {
        //   eventBus.$emit('app-finance-refresh')
        // }
        // this.$parent.$parent.$parent.$refs['record-table'].refreshTableList()
        this.viewRecords()
      }
    },

    /**
     * 跳转支付
     */
    goToCash () {
      const { id } = this.orderDetail
      if (!id) {
        return
      }
      this.closeDialog()
      this.jumpToPay(id)
    },

    /**
     * 查看记录
     */
    viewRecords () {
      this.closeDialog()
      // console.log('path: ' + this.$route.path)
      if (this.userInfo.current_back_id === 2) {
        this.viewAgentRecords()
      } else {
        this.viewAppRecords()
      }
      // eventBus.$emit('app-finance-refresh')
    },
    viewAppRecords () {
      if (this.$route.path !== '/app/finance') {
        this.$router.push('/app/finance')
      } else {
        eventBus.$emit('app-finance-refresh')
      }
    },
    viewAgentRecords () {
      if (this.$route.path !== '/operate/finance') {
        this.$router.push('/operate/finance')
      } else {
        eventBus.$emit('app-finance-refresh')
      }
    }
  }
}
