/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-03 15:59:06
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-13 19:12:53
 */
export const UPDATED_SUCCESS_DIALOG = 'UPDATED_SUCCESS_DIALOG'
export const UPDATED_RIGHTS = 'UPDATED_RIGHTS'
export const UPDATED_AUTH = 'UPDATED_AUTH'
export const UPDATED_MENUS = 'UPDATED_MENUS'
export const UPDATED_LOGIN = 'UPDATED_LOGIN'
export const UPDATED_IS_MOBILE = 'UPDATED_IS_MOBILE'
export const SCREEN_WIDTH = 'SCREEN_WIDTH'
export const SCREEN_HEIGHT = 'SCREEN_HEIGHT'
export const UPDATED_ENUM_MAP = 'UPDATED_ENUM_MAP'
export const UPDATED_USER_INFO = 'UPDATED_USER_INFO'
export const UPDATED_NOTICE_INFO = 'UPDATED_NOTICE_INFO'
export const UPDATE_PAYORDER_INFO = 'UPDATE_PAYORDER_INFO'
export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO'
export const UPDATED_DOWNLOAD_MODAL = 'UPDATED_DOWNLOAD_MODAL'
export const UPDATED_CAN_BUY_COMBO = 'UPDATED_CAN_BUY_COMBO'

// 首页 - 登录弹窗/重置密码弹窗
export const LOGIN_UPDATE_DIALOG_LOGIN = 'LOGIN_UPDATE_DIALOG_LOGIN'
export const LOGIN_LOGIN_UPDATE_FORM = 'LOGIN_LOGIN_UPDATE_FORM'
export const LOGIN_REGISTRE_UPDATE_FORM = 'LOGIN_REGISTRE_UPDATE_FORM'
export const LOGIN_UPDATE_DIALOG_RESET = 'LOGIN_UPDATE_DIALOG_RESET'
export const LOGIN_LOGIN_RESET_FORM = 'LOGIN_LOGIN_RESET_FORM'
export const LOGIN_REGISTER_RESET_FORM = 'LOGIN_REGISTER_RESET_FORM'
export const LOGIN_RESET_RESET_FORM = 'LOGIN_RESET_RESET_FORM'

// 首页 - 重置密码、注册成功弹窗
export const LOGIN_SUCCESS_INITMODAL = 'LOGIN_SUCCESS_INITMODAL'

// 管理后台 - 数据看板
export const ADMIN_OVERVIEW_OVERVIEW_DATA_UPDATE = 'ADMIN_OVERVIEW_OVERVIEW_DATA_UPDATE'
export const ADMIN_OVERVIEW_TREND_DATA_UPDATE = 'ADMIN_OVERVIEW_TREND_DATA_UPDATE'
export const ADMIN_OVERVIEW_TREND_QUERYFORM_UPDATE = 'ADMIN_OVERVIEW_TREND_QUERYFORM_UPDATE'

// 代理后台 - 数据看板
export const OPERATE_OVERVIEW_OVERVIEW_DATA_UPDATE = 'OPERATE_OVERVIEW_OVERVIEW_DATA_UPDATE'
export const OPERATE_OVERVIEW_TREND_DATA_UPDATE = 'OPERATE_OVERVIEW_TREND_DATA_UPDATE'
export const OPERATE_OVERVIEW_TREND_QUERYFORM_UPDATE = 'OPERATE_OVERVIEW_TREND_QUERYFORM_UPDATE'

// 客户前台 - 数据看板
export const APP_OVERVIEW_OVERVIEW_DATA_UPDATE = 'APP_OVERVIEW_OVERVIEW_DATA_UPDATE'
export const APP_OVERVIEW_TREND_DATA_UPDATE = 'APP_OVERVIEW_TREND_DATA_UPDATE'
export const APP_OVERVIEW_TREND_QUERYFORM_UPDATE = 'APP_OVERVIEW_TREND_QUERYFORM_UPDATE'

// 人群画像 - 画像分析
export const APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_TAB = 'APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_TAB'
export const APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_QUERYFORM = 'APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_QUERYFORM'
export const APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_CROWDATTRIBUTE_LIST = 'APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_CROWDATTRIBUTE_LIST'
export const APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_PAGETITLE = 'APP_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_PAGETITLE'
export const OPERATE_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_TAB = 'OPERATE_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_TAB'
export const OPERATE_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_QUERYFORM = 'OPERATE_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_QUERYFORM'
export const OPERATE_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_CROWDATTRIBUTE_LIST = 'OPERATE_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_CROWDATTRIBUTE_LIST'
export const OPERATE_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_PAGETITLE = 'OPERATE_DATAPACKAGE_CROWDATTRIBUTE_UPDATE_PAGETITLE'

// 人群管理 - 新建自定义人群
export const APP_DIYPACKAGE_ADD_UPDATE_ACTIVITY_SOURCE = 'APP_DIYPACKAGE_ADD_UPDATE_ACTIVITY_SOURCE' // 设置数据来源查询资源
export const OPERATE_DIYPACKAGE_ADD_UPDATE_ACTIVITY_SOURCE = 'OPERATE_DIYPACKAGE_ADD_UPDATE_ACTIVITY_SOURCE' // 设置数据来源查询资源
export const APP_DIYPACKAGE_ADD_RESET_FORM = 'APP_DIYPACKAGE_ADD_RESET_FORM' // 重置表单数据
export const APP_DIYPACKAGE_ADD_UPDATE_FORM = 'APP_DIYPACKAGE_ADD_UPDATE_FORM' // 更新表单数据
export const OPERATE_DIYPACKAGE_ADD_UPDATE_FORM = 'OPERATE_DIYPACKAGE_ADD_UPDATE_FORM' // 更新表单数据
export const OPERATE_DIYPACKAGE_ADD_RESET_FORM = 'OPERATE_DIYPACKAGE_ADD_RESET_FORM' // 重置表单数据

// 客户前台 - 资质认证
export const ACCOUNT_CONFIG_UPDATE_TRADE_LIST = 'ACCOUNT_CONFIG_UPDATE_TRADE_LIST' // 获取行业分类
export const ACCOUNT_CONFIG_RESET_FORM = 'ACCOUNT_CONFIG_RESET_FORM' // 重置表单
export const ACCOUNT_CONFIG_UPDATE_FORM = 'ACCOUNT_CONFIG_UPDATE_FORM' // 更新表单

// 客户前台 - 账户管理
export const APP_FINANCE_UPDATE_ACCOUNT_INFO = 'APP_FINANCE_UPDATE_ACCOUNT_INFO' // 更新当前账户情况
export const APP_FINANCE_UPDATE_QUERY_FORM = 'APP_FINANCE_UPDATE_QUERY_FORM' // 更新请求参数
export const APP_FINANCE_DIALOG_UPDATE_SHOW = 'APP_FINANCE_DIALOG_UPDATE_SHOW' // 更新弹窗
export const APP_FINANCE_DIALOG_UPDATE_FORMDATA = 'APP_FINANCE_DIALOG_UPDATE_FORMDATA' // 更新表单数据
export const APP_FINANCE_DIALOG_UPDATE_UNCASHORDER = 'APP_FINANCE_DIALOG_UPDATE_UNCASHORDER' // 更新未支付订单信息

// 客户前台 - 资质详情
export const APP_ACCOUNT_DETAIL_UPDATE_TRADE_LIST = 'APP_ACCOUNT_DETAIL_UPDATE_TRADE_LIST' // 获取行业分类
export const APP_ACCOUNT_DETAIL_UPDATE_INFO = 'APP_ACCOUNT_DETAIL_UPDATE_INFO' // 更新资质详情信息
