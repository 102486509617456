/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 15:28:14
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-23 15:30:25
 */

import diypackage from './diypackage'

const state = {
  name: 'Components'
}

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  modules: {
    diypackage
  },
  state,
  getters,
  mutations,
  actions
}
