/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-09 14:24:02
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-09 14:25:13
 */
import WBoxContent from './WBoxContent.vue'
WBoxContent.install = Vue => {
  Vue.component(WBoxContent.name, WBoxContent)
}
export default WBoxContent
