/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-17 16:56:25
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-22 21:49:04
 */
import { wVerifyUrl } from '@/plugins/verifier'
const state = {
  rules: {
    name: [{
      validator (rule, val, res) {
        if (val === '') {
          res('请填写活动单元名称')
        }
        res()
      },
      trigger: 'blur',
      required: true
    }],
    media_platform: [{
      message: '请选择投放平台',
      trigger: 'change',
      required: true
    }],
    data_type: [{
      message: '请选择数据类型',
      trigger: 'change',
      required: true
    }],
    monitor_platform: [{
      message: '请选择监测平台',
      trigger: 'change',
      required: true
    }],
    report_type: [{
      message: '请选择上报方式',
      trigger: 'change',
      required: true
    }],
    origin_url: [{
      validator (rule, val, res) {
        if (val === '') {
          res('请填写原监测链接')
        }
        if (!wVerifyUrl(val)) {
          res('请填写正式格式的链接')
        }
        res()
      },
      trigger: 'blur',
      required: true
    }]
  }
}

const getters = {
  rules: state => state.rules
}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
