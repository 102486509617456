/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 16:34:44
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-10-30 16:41:31
 */
const wTips = {
  success: '成功',
  apiNotFound: '接口异常：找不到接口',
  noLimit: '当前无权限访问该页面',
  serverError: '服务器异常',
  serverStop: '服务器停机维护',
  noCode: '当前服务器返回了一个未定义的状态码'
}

export default wTips
