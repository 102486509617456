/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 16:05:21
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-27 15:52:04
 */
import Vue from 'vue'
import * as requestApis from '@/store/request-apis'
import * as types from '@/store/mutation-types'
import stateTool from '@/store/state-tool'

// 活动数据处理
const activityDataFormat = data => {
  return data.map(
    ({
      custom_id: customId,
      custom_name: customName,
      number: customNumber = '',
      diy_activity_volist: diyActivityVolist = []
    }) => {
      return {
        id: customId,
        key: customId,
        lanel: customName,
        name: customName,
        number: '',
        childs: diyActivityVolist.map(
          ({
            active_id: activeId,
            active_name: activeName,
            number: activeNumber = 0
          }) => ({
            id: activeId,
            key: activeId,
            label: activeName,
            name: activeName,
            number: activeNumber - 0,
            childs: [],
            childIds: []
          })
        ),
        childIds: diyActivityVolist.map(({ active_id: activeId }) => activeId)
      }
    })
}

const state = {
  name: 'Addpackage',
  form: {
    // 数据类型
    source_type: 1,
    // 数据来源
    compose_type: 1,
    // 数据组合关系
    data_type_list: [0],
    // 数据来源
    active_list: [],
    // 排除数据
    remove_list: [],
    // 人群包名称
    name: '',
    // 上传后返回的id
    uuid: ''
  },
  // 活动数据源
  activitySource: []
}

const getters = {
  form: state => state.form,
  activitySource: state => state.activitySource
}

const mutations = {

  /**
   * 更新活动数据源
   */
  [types.OPERATE_DIYPACKAGE_ADD_UPDATE_ACTIVITY_SOURCE] (state, payload) {
    stateTool.update(state, 'activitySource', payload)
  },

  /**
   * 更新表单
   */
  [types.OPERATE_DIYPACKAGE_ADD_UPDATE_FORM] (state, payload) {
    stateTool.updateAllKeys(state.form, payload)
  },

  /**
   * 重置表单
   */
  [types.OPERATE_DIYPACKAGE_ADD_RESET_FORM] (state, payload) {
    stateTool.updateAllKeys(state.form, {
      // 数据类型
      source_type: 1,
      // 数据来源
      compose_type: 1,
      // 数据组合关系
      data_type_list: [0],
      // 数据来源
      active_list: [],
      // 排除数据
      remove_list: [],
      // 人群包名称
      name: '',
      // 上传后返回的id
      uuid: ''
    })
  }

}

const actions = {

  /**
   * 更新表单
   */
  updateForm ({ commit }, payload) {
    commit(types.OPERATE_DIYPACKAGE_ADD_UPDATE_FORM, payload)
  },

  /**
   * 获取所有活动数据
   */
  async getAllActivities ({ commit, getters }, payload) {
    const datatypeList = getters.form.data_type_list
    const data = await Vue.wPost(requestApis.OPERATE_DIYPACKAGE_ADD_SEARCH_ACTIVITY, {
      data_type_list: datatypeList
    })

    if (Array.isArray(data)) {
      const formatData = activityDataFormat(data)
      commit(types.OPERATE_DIYPACKAGE_ADD_UPDATE_ACTIVITY_SOURCE, formatData)
    }
  },

  /**
   * 新建人群包
   */
  async addPackageSubmit ({ commit }, payload) {
    const data = await Vue.wPost('/operate/datapackage/diy/add.do', payload)
    return data
  },

  /**
   * 重置表单数据
   */
  resetForm ({ commit }, payload) {
    commit(types.OPERATE_DIYPACKAGE_ADD_RESET_FORM)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
