var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-transfer el-transfer"},[_c('div',{staticClass:"el-transfer-panel"},[_vm._t("title-left"),_c('tree-box',_vm._b({ref:"leftTree",attrs:{"data":_vm.sourceData,"filterPlaceholder":_vm.filterPlaceholder,"expand-on-click-node":false,"overflow":_vm.overflow},on:{"check-change":_vm.onSourceCheckedChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{},[_vm._t("item",null,{"node":node,"data":data})],2)}}],null,true)},'tree-box',_vm.$props,false),[_vm._t("item-title-left",null,{"slot":"title-left"}),_vm._t("item-title-right",null,{"slot":"title-right"})],2),_vm._t("left-footer")],2),_c('div',{staticClass:"el-transfer-panel",class:_vm.handleScreenWidth(_vm.screenWidth)},[_vm._t("title-right"),_c('result-box',_vm._b({ref:"rightTree",attrs:{"data":_vm.targetData,"filterPlaceholder":_vm.filterPlaceholder,"overflow":_vm.overflow},scopedSlots:_vm._u([{key:"result-item",fn:function(ref){
var node = ref.node;
var data = ref.data;
var eventHandle = ref.eventHandle;
return _c('div',{},[_vm._t("result-item",null,{"node":node,"data":data,"eventHandle":eventHandle})],2)}},{key:"result-footer-top",fn:function(ref){
var data = ref.data;
var value = ref.value;
return _c('div',{},[_vm._t("result-footer-top",null,{"data":data,"value":value})],2)}}],null,true)},'result-box',_vm.$props,false),[_vm._t("result-item-title-left",null,{"slot":"title-left"}),_vm._t("result-item-title-right",null,{"slot":"title-right"})],2),_vm._t("right-footer")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }