<!--
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-04 15:13:44
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-04 16:11:50
-->
<template>
  <div class="w-slider">
    <w-menu></w-menu>
  </div>
</template>

<script>
import WMenu from './WMenu'
export default {
  name: 'WSlider',
  components: {
    WMenu
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
