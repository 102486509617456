/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-10-30 10:39:07
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-02 16:02:42
 */
import axios from './axios'

const plugin = {}
/**
 *
 * @param {String} url 地址
 * @param {Object} params 参数
 * @param {Object} option axios参数，例： {header: {'token':token }}
 */
plugin.install = Vue => {
  Vue.prototype.$wGet = (url, params, option) => {
    const payload = Object.assign({ params }, option)
    return axios.get(url, payload)
  }

  Vue.prototype.$wPost = (url, params, option) => {
    return axios.post(url, params, option)
  }

  Vue.wGet = Vue.prototype.$wGet
  Vue.wPost = Vue.prototype.$wPost
}

export default plugin
