/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 15:19:38
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-23 11:54:39
 */
const state = {

}

const getters = {

}

const mutations = {

}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
