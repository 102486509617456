/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-23 21:50:07
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-23 22:12:57
 */
import JsEncrypt from 'jsencrypt'
import Vue from 'vue'
class Jsencrypt {
  async encrypt (payload) {
    const jsencrypt = new JsEncrypt()
    const key = await Vue.wPost('/open/auth/get_secret.do')
    if (key) {
      jsencrypt.setPublicKey(key)
      const newPayload = {}
      for (const i in payload) {
        newPayload[i] = jsencrypt.encrypt(payload[i])
      }
      return newPayload
    } else {
      return false
    }
  }
}

const plugin = {}

plugin.install = Vue => {
  Vue.prototype.$wJsencrypt = new Jsencrypt()

  Vue.wJsencrypt = Vue.prototype.$wJsencrypt
}

export default plugin
