/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-13 11:08:56
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-26 16:20:34
 */
import { createNamespacedHelpers, mapActions as mapRootActions, mapGetters as mapRootGetters } from 'vuex'
const { mapActions, mapGetters } = createNamespacedHelpers('Login/dialog/login/login')

export default {
  name: 'login',
  data () {
    const self = this
    return {
      rules: {
        username: [
          {
            required: true,
            message: '请输入邮箱/手机号',
            trigger: ['blur']
          },
          {
            async validator (rule, value, res) {
              if (!/^(1\d{10}|\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)$/.test(value)) {
                return res('请输入正确的邮箱/手机号')
              }
              // todo 验证账号存在
              const data = await self.getAccountStatus({ username: value })
              console.log('🚀 ~ file: login.js:31 ~ validator ~ data:', data)
              if (data === -1) {
                return res('账号不存在，请检查输入是否有误')
              }
              if (data === 2) {
                return res('账号已停用，请联系平台管理员')
              }
              return res()
            },
            trigger: ['blur']
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: ['blur']
          }
        ]
      },
      // 切换密码显示
      passwordType: 'password'
    }
  },
  computed: {
    ...mapGetters([
      'form'
    ]),
    ...mapRootGetters([
      'isLogin',
      'menus'
    ])
  },
  created () {
    this.$emit('transfer', '')
  },
  async mounted () {
    const { username = '', password = '' } = await this.getAccountPassword()
    if (username) {
      // 记住账号、密码后赋值
      this.updateForm({
        username,
        password,
        remember: true
      })
    }
  },
  methods: {

    ...mapActions([
      'updateForm', // 更新表单信息
      'getAccountStatus', // 验证账号是否存在
      'rememberAccountPassword', // 记住账号密码
      'getAccountPassword', // 获取账号密码
      'removeUsernamePassword', // 移除账号和密码
      'loginSubmit', // 提交登录
      'resetForm' // 重置表单
    ]),

    ...mapRootActions([
      'initRights' // 初始化权限
    ]),

    /**
     * 切换密码显示、隐藏
     */
    switchPasswordType () {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },

    /**
     * 跳转注册页
     */
    goToRegister () {
      this.$emit('show-register')
    },

    /**
     * 去重置密码
     */
    goToReset () {
      this.$emit('show-reset')
    },

    goToUpgrade () {
      console.log('show-upgrade');
      this.$emit('show-upgrade')
    },
    /**
     * 表单校验
     */
    validate () {
      const loginForm = this.$refs['login-form']
      console.log('🚀 ~ file: login.js:121 ~ validate ~ loginForm:', loginForm)
      if (!loginForm) {
        return false
      }
      return new Promise(resolve => loginForm.validate(resolve))
    },

    /**
     * 提交登录
     */
    async loginSubmitTap () {
      console.log(this.form, 'form........');
      const valid = await this.validate()
      console.log(valid)
      if (!valid) {
        // 关闭正在提交loading
        this.$refs['login-btn'].updatedLoading(false)
        return
      }
      const data = await this.loginSubmit(this.form)
      console.log('🚀 ~ file: login.js:139 ~ loginSubmitTap ~ data:', data)
      // 关闭正在提交loading
      this.$refs['login-btn'].updatedLoading(false)
      // 登录失败
      if (data === false) {
        return
      } else if (data.need_reset_pwd) {
        // 用户需要重置密码
        console.log(this.form, 'form........');
        this.$emit('transfer', this.form.username)
        this.$emit('show-upgrade')
        return
      }
      // 提示登录登录成功
      this.$wToast.success('登录成功')
      // 登录成功后
      if (this.form.remember) {
        await this.rememberAccountPassword(this.form)
      } else {
        await this.removeUsernamePassword()
      }
      // 重置表单
      this.resetForm()
      this.$emit('close-dialog')
      // todo 登录成功后默认跳转上一个页面, 首页则默认跳转对应后台
      // const referrer = document.referrer
      // if (referrer === location.href) {
      //   location.href = referrer + '?backId=' + data.backId
      // } else {
      //   location.href = referrer
      // }
      // 初始化权限
      await this.initRights(data)
      if (this.isLogin === true) {
        if (this.menus.length > 0) {
          if (this.menus[0].children.length > 0) {
            this.$router.replace({ path: this.menus[0].children[0].path })
          } else {
            this.$router.replace({ path: this.menus[0].path })
          }
        }
      }
    }
  }
}
