import { render, staticRenderFns } from "./WGeetest.vue?vue&type=template&id=a4126c58&scoped=true&"
import script from "./WGeetest.js?vue&type=script&lang=js&"
export * from "./WGeetest.js?vue&type=script&lang=js&"
import style0 from "./WGeetest.scss?vue&type=style&index=0&id=a4126c58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4126c58",
  null
  
)

export default component.exports