/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-22 11:27:00
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-27 17:12:32
 */
import { mapActions } from 'vuex'

export default {
  name: 'WGeetest',
  data () {
    return {
      captchaObj: null,
      count: 0,
      timer: null,
      isCounting: false,
      countingText: '已发送60s',
      defaultText: '获取验证码'
    }
  },
  props: {
    username: {
      type: String
    }
  },
  mounted () {
    this.init()
  },
  methods: {

    ...mapActions([
      'getGeeTestConfig',
      'verifyGeeTestCode'
    ]),

    /**
     * 初始化
     */
    async init () {
      const config = await this.getGeeTestConfig()
      if (config.gt) {
        const {
          gt,
          challenge,
          success,
          new_captcha: newCaptcha
        } = config
        const initConfig = {
          t: (new Date()).getTime(),
          gt,
          challenge,
          offline: !success,
          new_captcha: newCaptcha,
          product: 'bind',
          width: '100%'
        }
        window.initGeetest(initConfig, captchaObj => {
          this.captchaObj = captchaObj
          captchaObj.onReady(this.verifyReady).onSuccess(this.verifySuccess)
        });
      }
    },

    /**
     * 开始校验
     */
    async verify () {
      console.log('开始校验');
      const errorMsg = await new Promise(resolve => this.$emit('check-username', resolve))
      console.log(errorMsg);
      if (errorMsg) {
        return
      }
      if (this.count > 0) {
        return
      }
      if (!this.captchaObj) {
        return
      }
      this.captchaObj.verify()
    },

    /**
     * 校验成功
     */
    async verifySuccess () {
      const result = this.captchaObj.getValidate();
      console.log('🚀 ~ file: WGeetest.js:89 ~ verifySuccess ~ result:', result)
      if (!result) {
        this.$wToast.error('请完成验证')
        return
      }
      const verifyGeeTestData = await this.verifyGeeTestCode({
        user_phone: this.username,
        geetest_challenge: result.geetest_challenge,
        geetest_validate: result.geetest_validate,
        geetest_seccode: result.geetest_seccode
      })
      if (verifyGeeTestData.result === 'success') {
        this.$emit('success', result)
      } else {
        this.$wToast.error('校验不通过，请重试')
        this.$emit('fail')
      }
    },

    /**
     * 校验准备
     */
    verifyReady () {
      console.log('ready')
    },

    /**
     * 开始倒计时
     */
    startCount () {
      this.count = 60
      this.countingText = '已发送60s'
      this.isCounting = true
      this.doCount()
    },

    /**
     * 倒计时计数
     */
    doCount () {
      console.log('counting')
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.count--
        if (this.count > 0) {
          this.countingText = `已发送${this.count}s`
          this.doCount()
          return
        }
        clearTimeout(this.timer)
        this.timer = null
        this.isCounting = false
      }, 1000)
    }
  }
}
