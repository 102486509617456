/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-23 14:20:09
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-27 17:39:42
 */
import { createNamespacedHelpers } from 'vuex'
import MealBuy from './template/mealBuy'
import OrderChecking from './template/orderChecking'
import OrderFinish from './template/orderFinish'

const { mapGetters } = createNamespacedHelpers('PayOrder/dialog')

export default {
  name: 'Dialog',
  components: {
    MealBuy,
    OrderChecking,
    OrderFinish
  },
  data () {
    return {
    }
  },
  computed: {

    ...mapGetters([
      'base',
      'formData',
      'uncashOrder'
    ]),

    dialogType () {
      return this.base.dialogType
    },

    dialogComponent () {
      switch (this.dialogType) {
        case 'mealBuy':
          return 'meal-buy'
        case 'orderChecking':
          return 'order-checking'
        case 'orderFinish':
          return 'order-finish'
      }
    }
  }
}
