/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-23 19:35:30
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-13 19:16:10
 */
import { collspanMenu, wGetUrlQuery } from '@/plugins/utils'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'WNavbar',
  data () {
    return {
      payDiasbled: false
    }
  },
  methods: {
    ...mapActions([
      'showMealBuyDialog'
    ]),
    toggleCollspan () {
      collspanMenu(true)
    },
    async toPay () {
      this.payDiasbled = true
      await this.showMealBuyDialog()
      this.payDiasbled = false
    },
    changePwd () {
      this.$router.push({ path: '/change-password' })
      console.log(this.$refs.wSubmenu)
      this.$refs.wSubmenu.handleMouseleave()
    },
    async logout () {
      const data = await this.$wPost('/common/user/loginout.do')
      if (data === true) {
        location.reload()
      } else {
        this.$wTips.error('退出登录失败')
        console.log('退出失败')
      }
    },
    dateFormat (val) {
      return moment(val).format('YYYY年MM月DD日')
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'accountInfo',
      'can_buy_combo'
    ]),
    isShow () {
      if (wGetUrlQuery('current_back_id')) {
        console.log(Number(wGetUrlQuery('current_back_id')))
        return Number(wGetUrlQuery('current_back_id'))
      } else {
        return false
      }
    }
  }
}
