/*
 * @Descripttion:
 * @version:
 * @Author: Mingkit
 * @Date: 2020-11-04 09:36:43
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-04 09:42:06
 */
import WLogo from './WLogo.vue'
export default WLogo
